import {FC} from 'react'

type Props = {
value?: string
}

const FirstCellLink: FC<Props> = ({value}) => (
  <div style={{color:"#009ef7"}}>{value}</div>
)

export {FirstCellLink}
