/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'

import {KTSVG} from '../../_template/helpers'
import {pickupStatus} from '../pages/mainOrders/main-orders-list/core/_models'
import { useLang } from '../../_template/i18n/Metronici18n'
type Props = {
  data: trackingStatus[]
}

const OrderTrackingHeader: FC<Props> = ({data}) => {
  const locale: string = useLang()

  return (
    <>
      <div className='timelineHorizontal'>
        {/* <div className='timeline-progress ' style={{width:'80%'}}></div> */}
        <div className='timeline-items'>
          {data &&
            data.map((item, i) => (
              <div
                key={i}
                className={
                  'timeline-item' +
                  (item.status === pickupStatus.completed
                    ? ' done'
                    : item.status === pickupStatus.progress
                    ? ' active'
                    : item.status === pickupStatus.pending
                    ? ' waiting'
                    : '')
                }
              >
                <div className='timeline-content'>
                  <KTSVG
                    path={'/media/svg/tarcking/packed.svg'}
                    className='svg-icon-2 svg-icon-gray-500'
                  />
                </div>
                {item.status === pickupStatus.progress ? (
                  <div className='timeline-title' style={{color: '#E8A623'}}>
                    {locale === 'ar' ? item.nameAr : item.name}
                  </div>
                ) : (
                  <div className='timeline-title'> {locale === 'ar' ? item.nameAr : item.name}</div>
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default OrderTrackingHeader
