import {FC, useEffect} from 'react'
import clsx from 'clsx'
import {useAppDispatch} from '../../../../app/hooks'
import {HIDE_MESSAGE} from '../../../../app/redux/actionTypes'
type Props = {
  message: string
  variant: string
}
const MessageView: FC<Props> = ({message, variant}) => {
  const dispatch = useAppDispatch()
  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      dispatch({type: HIDE_MESSAGE})
    }, 8000)

    return () => {
      clearTimeout(timeId)
    }
  }, [])

  return (
    <>
      <div className={clsx('mb-lg-10 alert', variant)}>
        <h4 className='alert-heading'>{variant === 'alert-success' ? 'Success' : 'Oups'}</h4>
        <div className='alert-text font-weight-bold'>{message}</div>
      </div>
    </>
  )
}

export {MessageView}
