import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { CustomersListHeader } from './components/header/CustomersListHeader'
import CustomersTable from './table/CustomersTable'
// import CustomerEditModal from './customer-edit-modal/CustomerEditModal'
// import { KTCard } from '../../../../_template/helpers'
import { useAppDispatch } from '../../../hooks'
import { useEffect, useState } from 'react'
import { getAllFunction } from '../../../redux/actions/commonCrud'
import InfoView from '../../../../_template/layout/components/infoView'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../redux/actionTypes'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_template/layout/core'
import getBreadCrambs from '../../../routing/breadcramps/getBreadCrambs'
import { Tab, Tabs } from 'react-bootstrap'
import DocsCount from '../../../components/DocsCount'

const CustomersList = () => {
  const intl = useIntl()
  const [count, setCount] = useState(0);

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getAllFunction(endpoints.customerEndpoint, actionTypes.GET_ALL_CUSTOMERS, {}))
  }, [dispatch])
  return (
    <>
      <InfoView />
      <div
        className='d-flex justify-content-between'
        style={{ marginTop: '-4%', marginBottom: '25px' }}
      >
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({ id: 'ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST' })}
          </PageTitle>
        </div>
        <div className=''>
          <CustomersListHeader />
        </div>
      </div>
      {/* onSelect={(k) => handleSelect(k)} */}
      <Tabs id='controlled-tab-example' defaultActiveKey='All' >
        <Tab className='nav-item' eventKey='All' title={<DocsCount showNumbers={true} count={count} title={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ALL' })} />}>
          <CustomersTable setCount={setCount} />
        </Tab>
      </Tabs >

      {/* <CustomersListHeader /> */}
      {/* <CustomersTable /> */}
      {/* {itemIdForUpdate !== undefined && <CustomerEditModal />} */}
    </>
  )
}

const CustomersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CustomersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export default CustomersListWrapper
