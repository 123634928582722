/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../hooks'
import { getAllFunctionById } from '../../../../../redux/actions/commonCrud'
// import {Link} from 'react-router-dom'
// import { KTSVG } from '../../../../../../components/helpers'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import CustomersListLoading from '../components/loading/deliveryTypeLoading'
import { toAbsoluteUrl } from '../../../../../../_template/helpers'
import InfoView from '../../../../../../_template/layout/components/infoView'
import { PageTitle } from '../../../../../../_template/layout/core'
import { useParams } from 'react-router-dom'
import getBreadCrambs from '../../../../../routing/breadcramps/getBreadCrambs'
import { Card } from 'react-bootstrap'

export function DeliveryTypeInfo() {
  const { id } = useParams()
  const oneDeliveryType: IDeliveryType | undefined = useSelector(
    ({ config }: { config: ConfigState }) => {
      return config.oneDeliveryType
    }
  )
  const intl = useIntl()

  const blankImg = toAbsoluteUrl('/media/svg/avatars/avatar_obj.svg')
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setLoading(true)
    dispatch(
      getAllFunctionById(endpoints.deliveryType, actionTypes.GET_ONE_DELIVERY_TYPE_BY_ID, id)
    )

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [dispatch, id])

  return (
    <>
      {loading && <CustomersListLoading />}
      {/* <InfoView /> */}
      <div className='d-flex justify-content-between'>
        <PageTitle
          breadcrumbs={getBreadCrambs(intl, {
            isActive: false,
            path: '/configutation/deliveryType',
            title: 'Delivery Type',
            isSeparator: false,
          })}
        >
          {/* {intl.formatMessage({id: 'ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST'})} */}
          {intl.formatMessage({ id: 'ECOMMERCE.DELIVERY.TYPE.DETAILS' })}
        </PageTitle>
      </div>
      {oneDeliveryType && (
        <div className='vstack gap-10 bg-white rounded p-10'>
          <div className='d-flex flex-column flex-sm-row gap-12'>
            {/* begin::Image input */}
            <div
              className='image-input image-input-outline mx-auto'
              data-kt-image-input='true'
              style={{
                backgroundImage: `url('${blankImg}')`,
                padding: '10px',
                width: 'fit-content',
                height: 'fit-content',
                borderColor: '#c6c9cc42',
                borderStyle: 'dashed',
                borderRadius: '70px',
              }}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-110px h-110px p-5'
                style={{
                  borderRadius: '70px',
                  backgroundImage: `url('${oneDeliveryType.photo
                    ? process.env.REACT_APP_API_URL + oneDeliveryType.photo.slice(1)
                    : blankImg
                    }')`,
                }}
              ></div>

              {/* begin::Cancel */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Cancel */}

              {/* begin::Remove */}
              {/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='remove'
              data-bs-toggle='tooltip'
              title='Remove avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            <div className='my-auto vstack gap-2 mx-auto'>
              <h2>{intl.formatMessage({ id: 'ECOMMERCE.DASHBOARD.INFORMATION' })}</h2>
              <div className='mb-10'>
                <HorizontalAttributeComponent
                  attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.NAME' })}
                  value={`${oneDeliveryType?.name}`}
                />

                <HorizontalAttributeComponent
                  attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CODE' })}
                  value={oneDeliveryType?.code}
                />
              </div>

              <div>
                <h2>{intl.formatMessage({ id: 'ECOMMERCE.DASHBOARD.DISTANCES' })}</h2>
                {oneDeliveryType.distances &&
                  <div style={{
                    gap: 12,
                    display: 'flex',
                    flexDirection: "column"
                  }}>
                    {
                      oneDeliveryType.distances.map((item) => (
                        <Card style={{ padding: 4 }}>
                          <HorizontalAttributeComponent
                            attr={intl.formatMessage({ id: 'ECOMMERCE.DELIVERY_TYPE.MINDISTANCE' })}
                            value={item.minDistance}
                          />
                          <HorizontalAttributeComponent
                            attr={intl.formatMessage({ id: 'ECOMMERCE.DELIVERY_TYPE.MAXDISTANCE' })}
                            value={item.maxDistance}
                          />
                          <HorizontalAttributeComponent
                            attr={intl.formatMessage({ id: 'ECOMMERCE.DELIVERY_TYPE.PRICE' })}
                            value={item.price}
                          />
                        </Card>
                      )

                      )
                    }
                  </div>
                }
              </div>
            </div>
            <div className='my-auto vstack gap-2 mx-auto'>
              <h2>{intl.formatMessage({ id: 'ECOMMERCE.DASHBOARD.ATTRIBUTES' })}</h2>

              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.HEIGHT' })}
                value={oneDeliveryType?.minWeight}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.WIDTH' })}
                value={oneDeliveryType?.minHeight}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.DEPTH' })}
                value={oneDeliveryType?.minWidth}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.WEIGHT' })}
                value={oneDeliveryType?.minDepth}
              />
            </div>
            <div className='my-auto vstack gap-2 mx-auto'>
              {' '}
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.HEIGHT' })}
                value={oneDeliveryType?.maxWeight}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.WIDTH' })}
                value={oneDeliveryType?.maxHeight}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.DEPTH' })}
                value={oneDeliveryType?.maxWidth}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.WEIGHT' })}
                value={oneDeliveryType?.maxDepth}
              />
            </div>
          </div>
        </div>
      )}
      {/* Container */}
    </>
  )
}

const HorizontalAttributeComponent = ({
  attr = 'Test',
  value = 'Test',
}: {
  attr?: string | undefined
  value?: string | undefined | number
}) => (
  <div className='d-flex fs-5 my-auto'>
    <div className='text-muted '>{attr}</div>: <div className='mx-2'> {value}</div>
  </div>
)
