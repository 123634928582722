/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../hooks'
import {getAllFunction, getAllFunctionById} from '../../../../redux/actions/commonCrud'
import {PageTitle} from '../../../../../_template/layout/core'

import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
// import { useListView } from '../core/ListViewProvider'
import {useParams} from 'react-router-dom'
import Loading from '../../../../../_template/layout/components/loading/Loading'
import {toAbsoluteUrl} from '../../../../../_template/helpers'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'
import {GoogleMap, LoadScript, MarkerF} from '@react-google-maps/api'
import {StatisticsDriver} from './statisticsDriver'
// import {UsersListHeader} from '../../../ListUsers/users-list/components/header/UsersListHeader'
// import DriverInfoHeader from '../components/header/DriverInfoHeader'
import {driverArea} from '../../../../../_template/helpers/endpoints'
const googlePlaceKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PLACE || ''

export function DriversInfo() {
  const user: any = useSelector(({users}: {users: UsersState}) => {
    return users.oneUser
  })
  const driverStatusByAdmin: any = useSelector(({stats}: {stats: StatisticsState}) => {
    return stats.driverStatusByAdmin
  })
  const containerStyle = {
    width: '100%',
    height: '400px',
  }
  const blankImg = toAbsoluteUrl('/media/avatars/driver.svg')

  let {id} = useParams()
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(getAllFunctionById(endpoints.userEndpoint, actionTypes.GET_ALL_USERS_BY_ID, id))

    dispatch(getAllFunction(endpoints.driverByAdminStatistics, actionTypes.GET_ALL_DRIVER_STATUS_BY_ADMIN, {driverId:id}))

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [dispatch, id])
  const intl = useIntl()
  const data = [
    {title: 'Pending', color: '#ffc700', value: '12', percentage: '10'},
    {title: 'Delivered', color: 'green', value: '10', percentage: '10'},
    {title: 'Rejected', color: 'red', value: '12', percentage: '10'},
  ]
  return (
    <>
      {loading && <Loading />}
      {/* <InfoView /> */}

      <div
        className='d-flex justify-content-start'
        style={{marginTop: '-8%', marginBottom: '25px'}}
      >
        <div>
          <PageTitle
            breadcrumbs={getBreadCrambs(intl, {
              isActive: true,
              path: '/drivers',
              title: intl.formatMessage({id: 'MENU.DRIVERS'}),
              isSeparator: false,
            })}
          >
            {intl.formatMessage({id: 'ECOMMERCE.USERS.DRIVER_DETAILS'})}
          </PageTitle>
        </div>
        <div className='my-20'>{/* <DriverInfoHeader /> */}</div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xxl-3 col-xl-5 col-lg-12 col-md-12 my-1' key={`driverdetails`}>
          {user && (
            <div className={`card p-10 mb-8 `}>
              <div className='d-flex flex-column justify-content-left'>
                {/* begin::Image input */}
                <div>
                  <div
                    className='image-input image-input-outline '
                    data-kt-image-input='true'
                    style={{
                      backgroundImage: `url('${blankImg}')`,
                      padding: '10px',
                      width: 'fit-content',
                      borderColor: '#c6c9cc42',
                      borderStyle: 'dashed',
                      borderRadius: '70px',
                    }}
                  >
                    {/* begin::Preview existing avatar */}
                    <div
                      className='image-input-wrapper w-110px h-110px p-10'
                      style={{
                        borderRadius: '70px',
                        backgroundImage: `url('${blankImg}')`,
                      }}
                    ></div>
                    {/* end::Preview existing avatar */}
                    {/* begin::Cancel */}
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='cancel'
                      data-bs-toggle='tooltip'
                      title='Cancel avatar'
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                    {/* end::Cancel */}
                  </div>
                  <div style={{marginLeft: '15px'}}>
                    <h3>{user?.firstName + ' ' + user?.lastName}</h3>
                  </div>
                </div>
                {/* end::Image input */}

                <div className='d-flex flex-column flex-sm-row'>
                  <div className='d-flex flex-column flex-sm-row' style={{width: '300px'}}>
                    <div className=' vstack gap-2'>
                      <HorizontalAttributeComponent
                        attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.EMAIL'})}
                        value={user?.email}
                      />
                      <HorizontalAttributeComponent
                        attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.PHONENUMBER'})}
                        value={user?.phoneNumber}
                      />
                      <HorizontalAttributeComponent
                        attr={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.GENDER'})}
                        value={
                          user?.gender === 'F' ? 'Female' : user?.gender === 'M' ? 'Male' : '--'
                        }
                      />
                      <HorizontalAttributeComponent
                        attr={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.IDNUMBER'})}
                        value={`${user?.IDNumber}`}
                      />
                      <HorizontalAttributeComponent
                        attr={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.TYPEOfID'})}
                        value={user?.typeOfID}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={`card p-10 mb-8`}>
            <div
              className='d-flex flex-column flex-sm-row'
              style={{
                width: '300px',
              }}
            >
              <div className='vstack gap-1'>
                <h3>{intl.formatMessage({id: 'ECOMMERCE.DRIVERS.TRANSPORTATIONTYPE'})}</h3>
                {user?.deliveryType && (
                  <div className='d-flex flex-row gap-2'>
                    <img
                      style={{
                        width: 20,
                        height: 20,
                      }}
                      alt='car'
                      src='/media/driver_type/car.svg'
                    />
                    <div>{user?.deliveryType?.name}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='col-xxl-9 col-xl-6 col-lg-12 col-md-12 my-6' key={`driveraddress`}>
          <div className='row g-5 g-xl-8'>
            {/* <div className='col-xxl-12 col-xl-4 col-lg-4 col-md-4 my-2' key={`driveraddress`}> */}
            {driverStatusByAdmin.map((item :any, i:number) => (
              <div className='col-xxl-3 col-xl-6 col-lg-4  col-md-12 my-2' key={`statInfo` + i}>
                <StatisticsDriver
                  className='card-xl-stretch mb-8'
                  color={item.statusColor}
                  title={item.statusName}
                  value={item.count}
                  percentage={item.percentage}
                />
              </div>
            ))}
            {/* </div> */}
          </div>
          <div className='row g-5 g-xl-8'>
            <div className='col-xxl-5 col-xl-12 col-lg-6 col-md-12 my-2' key={`drivermap`}>
              <div className={`card p-10 mb-8`}>
                <h3 className='mt-10'>
                  {intl.formatMessage({id: 'ECOMMERCE.USERS.ADDRESS_DETAILS'})}
                </h3>
                <HorizontalAttributeComponent
                  attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.ADDRESS_LINE_1'})}
                  value={`${user?.address?.addressLine1}`}
                />
                <HorizontalAttributeComponent
                  attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.CITY'})}
                  // value={user?.city}
                />
                <HorizontalAttributeComponent
                  attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.COUNTRY'})}
                  // value={user?.phoneNumber}
                />
              </div>
              <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-5 my-4' key={`driverType`}>
                <div className={`card p-10 mb-8`}>
                  <div className='d-flex flex-column flex-sm-row' style={{width: '100%'}}>
                    <div className=' vstack gap-2'>
                      <h3>{intl.formatMessage({id: 'ECOMMERCE.DRIVERS.AREA'})}</h3>
                      {user.driverArea &&
                        user.driverArea.map((item: any) => (
                          <div className='d-flex flex-column flex-sm-row' style={{width: '100%'}}>
                            {item.name}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xxl-7 col-xl-12  col-md-12 my-1' key={`driverInfo`}>
              <div className={`card p-10 mb-2`}>
                <h3>{intl.formatMessage({id: 'ECOMMERCE.DRIVERS.ACTUAL_POSITION'})}</h3>
                {user.location?.coordinates && (
                  <LoadScript googleMapsApiKey={googlePlaceKey}>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        lng: user.location?.coordinates[0],
                        lat: user.location?.coordinates[1],
                      }}
                      zoom={12}
                      //onLoad={(map)=>setMap(map)}
                      //options={{streetViewControl:false}}
                      //onReady={onMapReady}
                    >
                      {/* <MarkerF position={order.customerPos} icon={'/media/svg/tarcking/markerBlue.svg'} /> */}
                      <MarkerF
                        position={{
                          lng: user.location?.coordinates[0],
                          lat: user.location?.coordinates[1],
                        }}
                        icon={'/media/svg/tarcking/markerYellow.svg'}
                      />
                      {/* <MarkerF position={order.sellerPos} icon={'/media/svg/tarcking/markerPrimer.svg'} /> */}
                    </GoogleMap>
                  </LoadScript>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='row g-5 g-xl-8 '>
       
      </div> */}
      <div className='row g-5 g-xl-8 '>
        <div className='col-xxl-12 col-xl-12  col-md-12 my-4' key={`driverdetails`}>
          <div className={`card p-10 mb-8`}>
            <div className='d-flex flex-column flex-sm-row' style={{width: '100%'}}>
              <div className=' vstack gap-2'>
                <h3>{intl.formatMessage({id: 'ECOMMERCE.USERS.DRIVER_INFO'})}</h3>
                <div className='d-flex flex-column flex-sm-row' style={{width: '100%'}}>
                  <div className=' vstack gap-2'>
                    <HorizontalAttributeComponent
                      attr={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.LICENCENUMBER'})}
                      value={user?.licenseNumber}
                    />
                    <HorizontalAttributeComponent
                      attr={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.PLACEOFISSUANCE'})}
                      value={user?.placeOfIssuance}
                    />
                  </div>
                  <div className=' vstack gap-2'>
                    <HorizontalAttributeComponent
                      attr={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.LISENCEEXPIRATIONDATE'})}
                      value={user?.licenseExpirationDate}
                    />
                    <HorizontalAttributeComponent
                      attr={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.TECHNICALINSPECTION'})}
                      value={user?.technicalInspection}
                    />
                  </div>
                  <div className=' vstack gap-2'>
                    <HorizontalAttributeComponent
                      attr={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.INSURANCENUMBER'})}
                      value={user?.insuranceNumper}
                    />
                    <HorizontalAttributeComponent
                      attr={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.PLATENUMBER'})}
                      value={user?.plateNumber}
                    />
                    <HorizontalAttributeComponent
                      attr={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.POULLLISENCE'})}
                      value={user?.poullLicense}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const HorizontalAttributeComponent = ({
  attr = '--',
  value = '--',
}: {
  attr?: string | undefined
  value?: string | undefined
}) => (
  <div className='d-flex fs-5 '>
    <div className='form-subtitle'>{attr}</div>: <div className='mx-2'> {value}</div>
  </div>
)
