import {FC} from 'react'
import { FormattedMessage } from 'react-intl'

type Props = {
  status?: any
}

const CustomSuggStatus: FC<Props> = ({status}) => {
  return (
    <>
      {' '}
      {status === 10 ? (
        <span
          style={{width: 'auto', padding: '8px 15px 8px 14px', fontSize: '15px'}}
          className={`badge ${'badge-light-warning'}`}
        >
       <FormattedMessage id='ECOMMERCE.COMMON.PENDING'/>
        </span>
      ) : status === 4000 ? (
        <span
          style={{width: 'auto', padding: '8px 15px 8px 14px', fontSize: '15px'}}
          className={`badge ${'badge-light-danger'}`}
        >
              <FormattedMessage id='ECOMMERCE.COMMON.CANCELED'/>
        </span>
      ):    <span
      style={{width: 'auto', padding: '8px 15px 8px 14px', fontSize: '15px'}}
      className={`badge ${'badge-light-success'}`}
    >
        <FormattedMessage id='ECOMMERCE.COMMON.ASSIGNED'/>
    </span>}
    </>
  )
}

export {CustomSuggStatus}
