/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../hooks'
import { getAllFunctionById } from '../../../../redux/actions/commonCrud'
// import {Link} from 'react-router-dom'
// import { KTSVG } from '../../../../../components/helpers'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import CustomersListLoading from '../components/loading/CustomersListLoading'
import { toAbsoluteUrl } from '../../../../../_template/helpers'
import InfoView from '../../../../../_template/layout/components/infoView'
import { PageTitle } from '../../../../../_template/layout/core'
import { useParams } from 'react-router-dom'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'

export function CustomersInfo() {
  const { id } = useParams()
  const customer: ICustomer = useSelector(({ users }: { users: UsersState }) => {
    return users.oneCustomer
  })

  //   {
  //     "_id": "63fbea919596e34d97e23431",
  //     "firstName": "ibtissem",
  //     "lastName": "Bouchnak",
  //     "email": "ibtissembouchnak@gmail.com",
  //     "phoneNumber": "95544764",
  //     "status": "ACTIVE",
  //     "createDate": "2023-02-26T23:26:09.164Z",
  //     "updateDate": "2023-02-26T23:26:09.164Z",
  //     "__v": 0
  // }

  const intl = useIntl()

  const blankImg = toAbsoluteUrl('/media/svg/avatars/avatar_user.svg')
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setLoading(true)
    dispatch(
      getAllFunctionById(endpoints.customerEndpoint, actionTypes.GET_ALL_CUSTOMERS_BY_ID, id)
    )

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [dispatch, id])

  return (
    <>
      {loading && <CustomersListLoading />}
      {/* <InfoView /> */}
      <div className='d-flex justify-content-between'>
        <PageTitle
          breadcrumbs={getBreadCrambs(intl, {
            isActive: false,
            path: '/customers',
            title: intl.formatMessage({ id: 'MENU.CUSTOMERS' }),
            isSeparator: false,
          })}
        >
          {/* {intl.formatMessage({id: 'ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST'})} */}
          {intl.formatMessage({ id: 'ECOMMERCE.CUSTOMERS.CUSTOMERS_DETAILS' })}
        </PageTitle>
      </div>
      {customer && (
        <div className='row bg-white rounded p-10'>
          <div className='row'>
            {/* begin::Image input */}
            <div className='w-auto'>
              {' '}
              <div
                className='image-input image-input-outline mx-auto'
                data-kt-image-input='true'
                style={{
                  backgroundImage: `url('${blankImg}')`,
                  padding: '10px',
                  width: 'fit-content',
                  height: 'fit-content',
                  borderColor: '#c6c9cc42',
                  borderStyle: 'dashed',
                  borderRadius: '70px',
                }}
              >
                {/* begin::Preview existing avatar */}
                <div
                  className='image-input-wrapper w-110px h-110px p-5'
                  style={{
                    borderRadius: '70px',
                    backgroundImage: `url('${blankImg}')`,
                  }}
                ></div>
                {/* end::Preview existing avatar */}
                {/* begin::Cancel */}
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
                {/* end::Cancel */}
              </div>
            </div>

            {/* end::Image input */}

            <div className='w-auto mt-8 mx-auto'>
              <h4>Profile details</h4>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.NAME' })}
                value={`${customer?.firstName + ' ' + customer?.lastName}`}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.EMAIL' })}
                value={customer?.email}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.PHONENUMBER' })}
                value={customer?.phoneNumber}
              />
            </div>
            <div className='w-auto  mt-8 mx-auto'>
              <h4>Address details</h4>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ADDRESS_LINE_1' })}
                value={customer?.address?.addressLine1}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ADDRESS_LINE_2' })}
                value={customer?.address?.addressLine2}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CITY' })}
                value={customer?.address?.city}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.COUNTRY' })}
                value={customer?.address?.country}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATE' })}
                value={customer?.address?.state}
              />
            </div>
            <div className='w-auto mt-8 mx-auto'>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CREATEDAT' })}
                value={new Date(customer?.createDate || new Date()).toLocaleDateString('en')}
              />
            </div>
          </div>
        </div>
      )}
      {/* Container */}
    </>
  )
}

const HorizontalAttributeComponent = ({
  attr = 'Test',
  value = 'Test',
}: {
  attr?: string
  value?: string
}) => (
  <div className='d-flex fs-5 my-auto'>
    <div className='text-muted '>{attr}</div>: <div className='mx-2'> {value}</div>
  </div>
)
