// @ts-nocheck
import {Column} from 'react-table'
import {
  UserInfoCell,
  UserTwoStepsCell,
  UserActionsCell,
  UserSelectionCell,
  UserCustomHeader,
  UserSelectionHeader,
} from '../../../../../../_template/layout/components/columns'
// import {UserEmailCell} from '../../../../../../components/layout/components/columns/UserEmailCell'
// import {User} from '../../core/_models'
import {FormattedMessage} from 'react-intl'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
import {useListView} from '../../core/ListViewProvider'
import {Link} from 'react-router-dom'

const usersColumns: ReadonlyArray<Column<IUsers>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index]._id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.FULLNAME' />}
        className='min-w-125px'
      />
    ),
    id: 'firstName',
    Cell: ({...props}) => (
      <Link to={`/users/${props.data[props.row.index]._id}`}>
        <UserInfoCell user={props.data[props.row.index]} />
      </Link>
    ),
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title={<FormattedMessage id='ECOMMERCE.COMMON.LAST_NAME'/>} className='min-w-125px' />,
  //   id: 'lastName',
  //   Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title={<FormattedMessage id='ECOMMERCE.COMMON.EMAIL'/>} className='min-w-125px' />,
  //   accessor: 'email',
  // },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.ROLE' />}
        className='min-w-80px'
      />
    ),
    accessor: 'role.name',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.PHONENUMBER' />}
        className='min-w-80px'
      />
    ),
    accessor: 'phoneNumber',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),
    id: 'createDate',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].createDate} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.STATUS' />}
        className='min-w-125px'
      />
    ),
    id: 'status',
    Cell: ({...props}) => (
      <UserTwoStepsCell
        id={props.data[props.row.index]._id}
        two_steps={props.data[props.row.index].status}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        userType='users'
        id={props.data[props.row.index]._id}
        useListView={useListView}
      />
    ),
  },
]

export {usersColumns}
