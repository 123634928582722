/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../hooks'
import { getAllFunctionById } from '../../../../redux/actions/commonCrud'
// import {Link} from 'react-router-dom'
// import { KTSVG } from '../../../../../components/helpers'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import { useParams } from 'react-router-dom'
import { PageTitle } from '../../../../../_template/layout/core'
import Loading from '../../../../../_template/layout/components/loading/Loading'
import { toAbsoluteUrl } from '../../../../../_template/helpers'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'

export function RelayPointInfo() {
  const { id } = useParams()
  const intl = useIntl()
  const relayPoint: IRelayPoint = useSelector(({ users }: { users: UsersState }) => {
    return users.oneRelayPoint
  })
  const blankImg = toAbsoluteUrl('/media/svg/avatars/avatar_user.svg')

  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(
      getAllFunctionById(endpoints.relayPointEndpoint, actionTypes.GET_ALL_RELAYPOINT_BY_ID, id)
    )

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [dispatch])

  return (
    <>
      {loading && <Loading />}
      {/* <InfoView /> */}
      <div className='d-flex justify-content-between'>
        <PageTitle breadcrumbs={getBreadCrambs(intl)}>
          {intl.formatMessage({ id: 'ECOMMERCE.SELLERS.SELLERS_DETAILS' })}
        </PageTitle>
      </div>
      {relayPoint && (
        <div className='vstack gap-10 bg-white rounded p-10'>
          <div className='d-flex flex-column flex-sm-row gap-12'>
            {/* begin::Image input */}
            <div
              className='image-input image-input-outline mx-auto'
              data-kt-image-input='true'
              style={{
                backgroundImage: `url('${blankImg}')`,
                padding: '10px',
                width: 'fit-content',
                borderColor: '#c6c9cc42',
                borderStyle: 'dashed',
                borderRadius: '70px',
              }}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-110px h-110px p-5'
                style={{
                  borderRadius: '70px',
                  backgroundImage: `url('${blankImg}')`,
                }}
              ></div>
              {/* end::Preview existing avatar */}
              {/* begin::Cancel */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Cancel */}
            </div>
            {/* end::Image input */}

            <div className='my-auto vstack gap-2 mx-auto'>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.NAME' })}
                value={`${relayPoint?.fullname}`}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.EMAIL' })}
                value={relayPoint?.email}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.PHONENUMBER' })}
                value={relayPoint?.phoneNumber}
              />
            </div>
          </div>

          <div>
            <h2>{intl.formatMessage({ id: 'ECOMMERCE.COMMON.ADDRESS' })}</h2>
            <div className='d-flex'>
              <div className='w-50'>
                <div className='d-flex justify-content-between flex-wrap'>
                  <VerticalAttributeComponent
                    attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ADDRESS_LINE_1' })}
                    value={relayPoint?.address?.addressLine1}
                  />
                  <VerticalAttributeComponent
                    attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.COUNTRY' })}
                    value={relayPoint?.address?.country}
                  />
                </div>
                <div className='d-flex justify-content-between flex-wrap'>
                  <VerticalAttributeComponent
                    attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ADDRESS_LINE_2' })}
                    value={relayPoint?.address?.addressLine2}
                  />
                  <VerticalAttributeComponent
                    attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CITY' })}
                    value={relayPoint?.address?.city}
                  />
                </div>

                <div className='d-flex justify-content-between flex-wrap'>
                  <VerticalAttributeComponent
                    attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATE' })}
                    value={relayPoint?.address?.state}
                  />
                  <VerticalAttributeComponent
                    attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATE' })}
                    value={relayPoint?.address?.state}
                  />
                </div>
                <VerticalAttributeComponent
                  attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CREATEDAT' })}
                  value={new Date(relayPoint?.createDate || new Date()).toLocaleDateString('en')}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const VerticalAttributeComponent = ({
  attr = 'Test',
  value = 'Test',
}: {
  attr?: string
  value?: string
}) => (
  <div className='mt-2 mx-2'>
    <div className='text-muted'>{attr}</div>
    <p
      className={` mt-2 mx-3 ${value === 'ACTIVE' ? 'text-success' : value === 'INACTIVE' ? 'text-danger' : ''
        }`}
    >
      {value}
    </p>
  </div>
)

const HorizontalAttributeComponent = ({
  attr = 'Test',
  value = 'Test',
}: {
  attr?: string
  value?: string
}) => (
  <div className='d-flex fs-5 my-auto'>
    <div className='text-muted '>{attr}</div>: <div className='mx-2'> {value}</div>
  </div>
)
