import React from 'react'
import {useFormikContext} from 'formik'
import {FormattedMessage} from 'react-intl'
import {useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../../helpers'

// interface AdressProps {
//   onNext: () => void
//   onPrevious: () => void
//   isUserLoading: boolean
//   user: IUsers
// }

const SubmissionForm: React.FC<{type: string}> = ({type}) => {
  const blankImg = toAbsoluteUrl('/media/svg/avatars/avatar_user.svg')
  const deliveryType: IDeliveryType[] = useSelector(({config}: {config: ConfigState}) => {
    return config.deliveryType
  })
  const roles: Role[] = useSelector(({users}: {users: UsersState}) => {
    return users.roles
  })
  const {values} = useFormikContext<IUsers>()
  return (
    <>
      {/* begin::Scroll */}
      <div>
        <h1>
          <FormattedMessage id='ECOMMERCE.USERS.SUBMISSION' />
        </h1>

        <div className='d-flex flex-column mb-5'>
          {values?.photo && (
            <div className='mx-auto'>
              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{
                  backgroundImage: `url('${
                    values?.photo?.preview
                      ? values?.photo?.preview
                      : values?.photo
                      ? values?.photo
                      : blankImg
                  }')`,
                  padding: '5px',
                  borderColor: '#c6c9cc42',
                  borderRadius: '100px',
                }}
              >
                {/* begin::Preview existing avatar */}
                <div
                  className='image-input-wrapper w-150px h-150px p-5'
                  style={{
                    borderRadius: '70px',
                    backgroundImage: `url('${
                      values?.photo?.preview
                        ? values?.photo?.preview
                        : values?.photo
                        ? values?.photo
                        : blankImg
                    }')`,
                  }}
                ></div>
              </div>
            </div>
          )}
          <label className='d-block fw-bold fs-6 mb-5'>
            <FormattedMessage id='ECOMMERCE.USERS.PROFILE_DETAILS' />
          </label>
          <span className='form-subsection'>
            <span className='form-subtitle'>
              <FormattedMessage id='ECOMMERCE.COMMON.FIRST_NAME' />:
            </span>
            <span className='form-subvalue'>{values.firstName}</span>
          </span>
          <span className='form-subsection'>
            <span className='form-subtitle'>
              <FormattedMessage id='ECOMMERCE.COMMON.LAST_NAME' />:
            </span>
            <span className='form-subvalue'>{values.lastName}</span>
          </span>
          <span className='form-subsection'>
            <span className='form-subtitle'>
              <FormattedMessage id='ECOMMERCE.COMMON.EMAIL' />:
            </span>
            <span className='form-subvalue'>{values.email}</span>
          </span>
          <span className='form-subsection'>
            <span className='form-subtitle'>
              <FormattedMessage id='ECOMMERCE.COMMON.PHONENUMBER' />:
            </span>
            <span className='form-subvalue'>{values.phoneNumber}</span>
          </span>
          {type === 'driver' ? (
            <span className='form-subsection'>
              <span className='form-subtitle'>
                <FormattedMessage id='MENU.DELIVERY.TYPE' />:
              </span>
              <span className='form-subvalue'>
                {deliveryType.find((item) => item._id === values.deliveryType)?.name}
              </span>
            </span>
          ) : null}
        </div>
        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}
        <div className='d-flex flex-column my-5'>
          <label className='d-block fw-bold fs-6 mb-5'>
            <FormattedMessage id='ECOMMERCE.USERS.ADDRESS_DETAILS' />
          </label>
          <span className='form-subsection'>
            <span className='form-subtitle'>
              <FormattedMessage id='ECOMMERCE.COMMON.ADDRESS_LINE_1' />:
            </span>
            <span className='form-subvalue'>{values.address?.addressLine1}</span>
          </span>

          <div className='d-flex flex-sm-row flex-column justify-content-between'>
            <span className='form-subsection'>
              <span className='form-subtitle'>
                <FormattedMessage id='ECOMMERCE.COMMON.ADDRESS_LINE_2' />:
              </span>
              <span className='form-subvalue'>{values.address?.addressLine2}</span>
            </span>
            <span className='form-subsection'>
              <span className='form-subtitle'>
                <FormattedMessage id='ECOMMERCE.COMMON.CITY' /> :
              </span>
              <span className='form-subvalue'>{values.address?.city}</span>
            </span>
            <span className='form-subsection'>
              <span className='form-subtitle'>
                <FormattedMessage id='ECOMMERCE.COMMON.COUNTRY' />:
              </span>
              <span className='form-subvalue'>{values.address?.country}</span>
            </span>
          </div>
          <div className='d-flex flex-sm-row flex-column justify-content-between'>
            <span className='form-subsection'>
              <span className='form-subtitle'>
                <FormattedMessage id='ECOMMERCE.COMMON.STATE_REGION' />:
              </span>
              <span className='form-subvalue'>{values.address?.state}</span>
            </span>
            <span className='form-subsection'>
              <span className='form-subtitle'>
                <FormattedMessage id='ECOMMERCE.COMMON.ZIPCODE' />:
              </span>
              <span className='form-subvalue'>{values.address?.zipCode}</span>
            </span>
          </div>
        </div>
        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}
        <div className='d-flex flex-column my-5'>
          <label className='d-block fw-bold fs-6 mb-5'>
            <FormattedMessage id='ECOMMERCE.USERS.ROLES_DETAILS' />
          </label>
          <span className='form-subsection'>
            <span className='form-subtitle'>
              <FormattedMessage id='ECOMMERCE.COMMON.STATUS' />:
            </span>
            <span className='form-subvalue'>{values.status}</span>
          </span>
          {type === 'user' ? (
            <span className='form-subsection'>
              <span className='form-subtitle'>
                <FormattedMessage id='ECOMMERCE.COMMON.ROLE' />:
              </span>
              <span className='form-subvalue'>
                {roles.find((role) => role._id === values.role)?.name}
              </span>
            </span>
          ) : null}

          {type === 'driver' ? (
            <>
              <div className='d-flex flex-sm-row flex-column justify-content-between'>
                <span className='form-subsection'>
                  <span className='form-subtitle'>
                    <FormattedMessage id='ECOMMERCE.ORDER_STATUS.DRIVER_AREA' />:
                  </span>
                  <span className='form-subvalue'>
                    {values.driverArea && values.driverArea.map((value) => value.label)}
                  </span>
                </span>
              </div>
              <div className='d-flex flex-sm-row flex-column justify-content-between'>
                <span className='form-subsection'>
                  <span className='form-subtitle'>
                    <FormattedMessage id='ECOMMERCE.DRIVERS.IDNUMBER' />:
                  </span>
                  <span className='form-subvalue'>{values.IDNumber}</span>
                </span>
                <span className='form-subsection'>
                  <span className='form-subtitle'>
                    <FormattedMessage id='ECOMMERCE.DRIVERS.TYPEOfID' />:
                  </span>
                  <span className='form-subvalue'>{values.typeOfID}</span>
                </span>
              </div>
              <div className='d-flex flex-sm-row flex-column justify-content-between'>
                <span className='form-subsection'>
                  <span className='form-subtitle'>
                    <FormattedMessage id='ECOMMERCE.DRIVERS.LICENCENUMBER' />:
                  </span>
                  <span className='form-subvalue'>{values.licenseNumber}</span>
                </span>
                <span className='form-subsection'>
                  <span className='form-subtitle'>
                    <FormattedMessage id='ECOMMERCE.DRIVERS.PLACEOFISSUANCE' />:
                  </span>
                  <span className='form-subvalue'>{values.placeOfIssuance}</span>
                </span>
              </div>
              <div className='d-flex flex-sm-row flex-column justify-content-between'>
                <span className='form-subsection'>
                  <span className='form-subtitle'>
                    <FormattedMessage id='ECOMMERCE.DRIVERS.LISENCEEXPIRATIONDATE' />:
                  </span>
                  <span className='form-subvalue'>{values.licenseExpirationDate}</span>
                </span>
                <span className='form-subsection'>
                  <span className='form-subtitle'>
                    <FormattedMessage id='ECOMMERCE.DRIVERS.TECHNICALINSPECTION' />:
                  </span>
                  <span className='form-subvalue'>{values.technicalInspection}</span>
                </span>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {/* end::Scroll */}
    </>
  )
}

export default SubmissionForm
