/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo} from 'react'
import {FormattedMessage} from 'react-intl'
import {useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../../../_template/helpers'
import moment from 'moment'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import {getAllFunction} from '../../../../redux/actions/commonCrud'
import {useAppDispatch} from '../../../../hooks'
import {Link} from 'react-router-dom'
type Props = {
  className: string
}

const DriversList: React.FC<Props> = ({className}) => {
  const data: ISuggestions = useSelector(({orders}: {orders: OrdersState}) => {
    return orders.OneSuggestion
  })

  const orders = useMemo(() => data, [data])

  //   const dispatch = useAppDispatch()
  //   useEffect(() => {
  //     dispatch(getAllFunction(endpoints.orderEndpoint, actionTypes.GET_ALL_ORDERS, {size: 4}))
  //   }, [dispatch])
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_7_tab_1'>
            {/* begin::Table container */}
            <div className=''>
              {/* begin::Table */}
              <table className='customtable align-middle w-100 gs-0 gy-2'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='p-0 w-250px'>
                      <FormattedMessage id='ECOMMERCE.DTIVER.NAME' />
                    </th>
                 
                    <th className='p-0 min-w-120px'>
                      <FormattedMessage id='ECOMMERCE.DELIVERY.TYPE' />
                    </th>
                    <th className='p-0 min-w-120px'>
                      <FormattedMessage id='ECOMMERCE.COMMON.ADDRESS' />
                    </th>
                    <th className='p-0 min-w-120px'>
                      <FormattedMessage id='ECOMMERCE.COMMON.PHONENUMBER' />
                    </th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {orders.driver && orders.driver?.length > 0 ? (
                    orders.driver?.map((item: IUsers) => (
                      <tr>
                        <td>
                          <Link to={`/drivers/${item._id}`}>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-50px me-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={toAbsoluteUrl('/media/avatars/driver.svg')}
                                    className='h-75 align-self-end'
                                    alt=''
                                  />
                                </span>
                              </div>
                              <span className='text-dark text-hover-primary mt-2 fs-6'>
                                {item.firstName + ' ' + item.lastName}
                              </span>
                            </div>
                          </Link>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <span className='text-dark text-hover-primary mt-2 fs-6'>
                              {item.deliveryType && item.deliveryType.name}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <span className='text-dark text-hover-primary mt-2 fs-6'>
                              {item.address && item.address.city}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <span className='text-dark text-hover-primary mt-2 fs-6'>
                              {item.phoneNumber}
                            </span>
                          </div>
                        </td>

                        {/* <td className=''>
                          <span className='text-dark fs-7'>
                            {moment(item.createDate).format('MM.DD.YYYY')}
                          </span>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {DriversList}
