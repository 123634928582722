import {FC, useEffect, useState} from 'react'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_template/helpers'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {IntlShape} from '@formatjs/intl'
import {
  formDataAddFunction,
  formDatapatchFunction,
  getAllFunction,
} from '../../../../redux/actions/commonCrud'
import {useAppDispatch} from '../../../../hooks'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import Loading from '../../../../../_template/layout/components/loading/Loading'
import {DriversList} from './DriversList'

type Props = {
  isDataLoading: boolean
  suggestion: ISuggestions
}

const ModalInfo: FC<Props> = ({suggestion, isDataLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const intl = useIntl()
  const dispatch = useAppDispatch()

  useEffect(() => {}, [suggestion])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  return (
    <>
      {isDataLoading && <Loading />}
      <div>
        {' '}
        <DriversList className='' />
      </div>
    </>
  )
}

export {ModalInfo}
