// @ts-nocheck
import {Column} from 'react-table'

import {User} from '../../core/_models'
import {FormattedMessage} from 'react-intl'
// import {CustomerActionsCell} from '../../../../../_template/layout/components/columns/CustomerActionsCell'
// import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
// import { CustomerActionsCell } from '../../../../../../../_template/layout/components/columns/CustomerActionsCell'
import {UserCustomHeader} from '../../../../../../../_template/layout/components/columns'
import {DateCell} from '../../../../../../../_template/layout/components/columns/DateAnTimeCell'
import {DeliveryTypeActionsCell} from '../../../../../../../_template/layout/components/columns/DeliveryTypeActionsCell'
import {FirstCell} from '../../../../../../../_template/layout/components/columns/FirstCell'
import {DeliveryTypeSelectionCell} from '../../../../../../../_template/layout/components/columns/DeliveryTypeSelectionCell copy'
import {DeliveryTypeSelectionHeader} from './deliveryTypeSelectAll'

const customerColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <DeliveryTypeSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <DeliveryTypeSelectionCell id={props.data[props.row.index]._id} />,
  // },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.TITLE' />}
        className='min-w-125px'
      />
    ),

    id: 'title',
    Cell: ({...props}) => <FirstCell value={props.data[props.row.index].title} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.MESSAGE' />}
        className='min-w-125px'
      />
    ),
    accessor: 'message',
  },
  
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),

    id: 'createDate',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].createDate} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='MENU.ACTIONS' />}
  //       className='text-end min-w-100px'
  //     />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <DeliveryTypeActionsCell id={props.data[props.row.index]._id} />,
  // },
]

export default customerColumns
