/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG} from '../../_template/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {PageTitle} from '../../_template/layout/core'
import getBreadCrambs from '../routing/breadcramps/getBreadCrambs'
import { useLang } from '../../_template/i18n/Metronici18n'
type Props = {
  data: any
}
function convertToCustomFormat(isoDateString: string, locale: string): string {
  const isoDate = new Date(isoDateString);

  const englishOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
  const arabicOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' };

  const formatter = new Intl.DateTimeFormat(locale, locale === 'ar' ? arabicOptions : englishOptions);
  const formattedDate = formatter.format(isoDate);
  
  return formattedDate;
}

export const TrackingInfo: FC<Props> = ({data}) => {
  const intl = useIntl()
  const locale: string = useLang()
  return (
    <>
      <div className='card-body pt-5 d-flex justify-content-between flex-row flex-wrap'>
        {/* begin::Body */}

        {/* begin::section */}
        <div className='m-5 '>
          <div>
            <span>
              <FormattedMessage id='ECOMMERCE.ORDER' />
            </span>
          </div>
          {data.paidThrough === 'mobicash' || data.status?.code === '100' ? (
            <div>
              <span>
                <KTSVG
                  path='/media/svg/tarcking/checked.svg'
                  className='svg-icon-2 svg-icon-gray-500'
                />
              </span>
              <span className='fs-4 mx-2' style={{color: '#12A05C'}}>
                {intl.formatMessage({id: 'ECOMMERCE.ORDER.PAID'})}
              </span>
            </div>
          ) : (
            <div>
              <span>
                <KTSVG
                  path='/media/svg/general/hourglass.svg'
                  className='svg-icon-2 svg-icon-gray-500'
                />
              </span>
              <span className='fs-4 mx-2' style={{color: '#e8a623'}}>
                {intl.formatMessage({id: 'ECOMMERCE.ORDER.NOT_PAID'})}
              </span>
            </div>
          )}
        </div>

        <div className='m-5 '>
          <div>
            <span>
              <FormattedMessage id='ECOMMERCE.ORDER.AMOUNT' />
            </span>
          </div>
          <div>
            <span className='fw-bold '>{data.totalAmount}  LYD</span>
          </div>
        </div>
        <div className='m-5 '>
          <div>
            <span>
              <FormattedMessage id='ECOMMERCE.ORDER.ESTIMATED_DELIVERY' />
            </span>
          </div>
          <div>
           {data?.createDate&& <span className='fw-bold'>{convertToCustomFormat(data?.createDate,locale)}</span>}
          </div>
        </div>
        <div className='m-5 '>
          <div>
            <span
              className={`badge ${
                data.status?.code === '10'
                  ? 'badge-light-warning'
                  : data.status?.code === '20'
                  ? 'badge-light-info'
                  : data.status?.code === '100'
                  ? 'badge-light-success'
                  : data.status?.code === '30'
                  ? 'badge-light-primary'
                  : data.status?.code === '400'
                  ? 'badge-light-danger'
                  : 'badge-light-warning'
              } fs-3 fw-bolder p-4`}
            >
              {data.status?.name}
            </span>
          </div>
        </div>

        {/* end::section */}
      </div>
      {/* end: Card Body */}
    </>
  )
}
