/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, FC } from 'react'
import { useIntl } from 'react-intl'
import { initialQueryState, KTSVG, useDebounce } from '../../../../../../_template/helpers'
import { useLang } from '../../../../../../_template/i18n/Metronici18n'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import { useAppDispatch } from '../../../../../hooks'
import { getAllFunction } from '../../../../../redux/actions/commonCrud'
type values = {
  role?: string | undefined
  phoneNumber?: string | undefined
  status?: string | undefined
  shopName?: string | undefined
  email?: string | undefined
}
type Props = {
  eventKey: string | undefined
  handleDispatch: () => any
  values: values | undefined
  handleChangeValues: (e: any) => any
  resetData: (e: any) => any
}
const SellersListSearchComponent: FC<Props> = ({
  values,
  eventKey,
  handleChangeValues,
  handleDispatch,
  resetData
}) => {
  const intl = useIntl()
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const dispatch = useAppDispatch()
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        dispatch(
          getAllFunction(endpoints.sellerEndpoint, actionTypes.GET_ALL_SELLERS, {
            searchSeller: searchTerm,
          })
        )
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  const locale: string = useLang()

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex'>
        <div
          style={{
            position: 'relative',
          }}
          className='d-flex align-items-center position-relative my-1 w-100'
        >
          {' '}
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute mx-5'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control px-14'
            placeholder={intl.formatMessage({
              id: 'ECOMMERCE.SELLERS.SEARCH_SELLERS_PLACEHOLDER',
            })}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            data-kt-menu-trigger='click'
            data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
            type='button'
            style={{
              position: 'absolute',
              right: locale !== 'ar' ? 0 : undefined,
              left: locale === 'ar' ? 0 : undefined,
              border: 0,
              zIndex: 5,
            }}
            className='btn btn-icon'
          >
            <KTSVG
              path='/media/icons/duotune/print/Filter.svg'
              className='svg-icon-8 position-absolute'
            />{' '}
          </button>
          {/* begin::SubMenu */}
          <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
            {/* begin::Header */}
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>
                {intl.formatMessage({ id: 'ECOMMERCE.COMMON.FILTER_OPTIONS' })}
              </div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='separator border-gray-200'></div>
            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.NAME' })}
                </label>
                <input
                  type='text'
                  className='form-control rounded form-control-sm form-control-solid  ps-10'
                  name='shopName'
                  value={values?.shopName}
                  onChange={handleChangeValues}
                  placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.NAME' })}
                />
              </div>
              {/* end::Input group */}
              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.PHONENUMBER' })}
                </label>
                <input
                  type='text'
                  className='form-control rounded form-control-sm form-control-solid  ps-10'
                  name='phoneNumber'
                  value={values?.phoneNumber}
                  onChange={handleChangeValues}
                  placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.PHONENUMBER' })}
                />
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.EMAIL' })}
                </label>
                <input
                  type='email'
                  className='form-control rounded form-control-sm form-control-solid  ps-10'
                  name='email'
                  value={values?.email}
                  onChange={handleChangeValues}
                  placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.EMAIL' })}
                />
              </div>
              {/* end::Input group */}

              {/* begin::Actions */}
              <div className='d-flex justify-content-between'>
                <button
                  type='button'
                  //disabled={isLoading}
                  onClick={resetData}
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                  data-kt-menu-dismiss='false'
                  data-kt-user-table-filter='reset'
                >
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.RESET' })}
                </button>
                <button
                  // disabled={isLoading}
                  type='button'
                  onClick={() => handleDispatch()}
                  className='btn btn-design fw-bold px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.APPLY' })}
                </button>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>
          {/* end::SubMenu */}
        </div>
      </div>
      {/* end::Search */}
    </div>
  )
}

export { SellersListSearchComponent }
