import React from 'react'

const TransportationType = ({ driver }: any) => {
  return (
    <div className='d-flex flex-row gap-3'>
      <div>
        {driver?.deliveryType ? driver?.deliveryType?.photo ? <img
          className='my-auto'
          style={{
            width: 20,
            height: 20,
          }}
          alt='Type'
          src={`${process.env.REACT_APP_API_URL}/${driver?.deliveryType?.photo.slice(1)}`}
        /> : "N/A" : 'N/A'
        }
      </div>
      <div className='fs-6 my-auto'>{driver?.deliveryType ? driver?.deliveryType.name : 'N/A'}</div>
    </div>
  )
}

export default TransportationType
