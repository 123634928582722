/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../helpers'
// import {useListView} from '../../../../app/pages/ListUsers/users-list/core/ListViewProvider'
import {useQueryResponse} from '../../../../app/pages/ListUsers/users-list/core/QueryResponseProvider'
import {deleteUser} from '../../../../app/pages/ListUsers/users-list/core/_requests'
import {Link} from 'react-router-dom'
import {useLang} from '../../../i18n/Metronici18n'
import {useIntl} from 'react-intl'
import {useListView} from '../../../../app/pages/ListOrders/orders-list/core/ListViewProvider'

type Props = {
  id: ID
  order: any
}

const OrderActionsCell: FC<Props> = ({id, order}) => {
  const {setItemIdForUpdate} = useListView()
  const locale: string = useLang()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const intl = useIntl()
  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
      >
        <KTSVG path='/media/icons/duotune/print/dots.svg' className='svg-icon-4 ml-5' />{' '}
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link className='menu-link px-3' to={`/orders/${id}`}>
            {intl.formatMessage({id: 'ECOMMERCE.COMMON.DETAILS'})}
          </Link>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link className='menu-link px-3' to={`/orders/tracking/${id}`}>
            {intl.formatMessage({id: 'ECOMMERCE.ORDER.TRACKING'})}
          </Link>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link
            className='menu-link px-3'
            to={`/orders/${id}/suggestions`}
            onClick={() => localStorage.setItem('mainOrder', order.mainOrder)}
          >
            {intl.formatMessage({id: 'ECOMMERCE.ORDER.SUGGESTIONS'})}
          </Link>
        </div>
        {!order.driver && (
          <>
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a className='menu-link px-2' onClick={openEditModal}>
                {intl.formatMessage({id: 'ECOMMERCE.DRIVERS.ASSIGN'})}
              </a>
            </div>
            {/* end::Menu item */}
          </>
        )}

        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {OrderActionsCell}
