/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {FC} from 'react'
// import {toAbsoluteUrl} from '../../../../../../components/helpers'
// import {User} from '../../core/_models'

type Props = {
  order: any
}

const OrderAddressCell: FC<Props> = ({order}) => {
  return (
    <div className='align-items-center'>
      <div className='d-flex flex-row gap-1'>
        <div>{order?.address?.country}</div>
        <div>{order?.address?.city}</div>
        <div>{order?.address?.zipCode}</div>
      </div>
    </div>
  )
}

export {OrderAddressCell}

// {
//     "_id": "64118388bbc2463307484b89",
//     "name": "TSTVEQVYX",
//     "shippingMethods": "DOMESTIC",
//     "marketplaceReference": "TSTVEQVYX",
//     "reference": "31-ORDER-1678869384742",
//     "totalAmount": "30.000000",
//     "paidThrough": "Bank transfer",
//     "customer": {
//         "_id": "64105b3b00910ab7fcf72abf",
//         "firstName": "Farouk",
//         "lastName": "Benabed",
//         "address": {
//             "zipCode": "12508",
//             "city": "Tripoli",
//             "street": "Tripoli",
//             "country": "Libya",
//             "delegation": "Tripoli"
//         },
//         "email": "faroukbenabed@gmail.com",
//         "phoneNumber": "50000000",
//         "createDate": "2023-03-14T11:32:11.484Z",
//         "updateDate": "2023-03-14T11:32:11.484Z",
//         "__v": 0
//     },
//     "createdBy": "63f78082fc1c6f259ad7b323",
//     "createDate": "2023-03-15T08:36:24.745Z",
//     "updateDate": "2023-03-15T08:36:24.745Z",
//     "__v": 0
// }
