/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {FC} from 'react'
// import {toAbsoluteUrl} from '../../../../../../components/helpers'
// import {User} from '../../core/_models'

type Props = {
  user: IUsers
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center '>
    {/* begin:: Avatar */}

    <div className='d-flex flex-column fs-7' style={{color:"#3F434A"}} >
      {/* <a href='#' className='text-gray-800 text-hover-primary mb-1'> */}
      {user?.firstName} {user?.lastName}
      {/* </a> */}
      {/* <span>{user.email}</span> */}
    </div>
  </div>
)

export {UserInfoCell}
