import {KTSVG} from '../../../../../../_template/helpers'
import {useListView} from '../../core/ListViewProvider'
import Export from '../../../../../components/export'

const DriverListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <Export dataType='users' />

      {/* begin::Add user */}
      <button type='button' className='btn btn-design btn-icon' onClick={openAddUserModal}>
        <KTSVG
          path='/media/icons/duotune/arrows/arr075.svg'
          className='svg-icon-2 svg-icon-light'
        />
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {DriverListToolbar}
