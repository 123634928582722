/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, FC } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG, useDebounce } from '../../../../../../../_template/helpers'
import { useLang } from '../../../../../../../_template/i18n/Metronici18n'
import TCustomerFilterValues from '../../../../../../types/TCustomerFilterValues'
import * as endpoints from '../../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../../redux/actionTypes'

// import {useQueryRequest} from '../../core/QueryRequestProvider'
import { useAppDispatch } from '../../../../../../hooks';
import { getAllFunction } from '../../../../../../redux/actions/commonCrud';
import { OrderStatus } from '../../../../../ListOrders/orders-list/core/_models';

type Props = {
  // eventKey: string | undefined
  handleDispatch: () => void
  filterValues?: TCustomerFilterValues
  handleChangeValues: (e?: React.ChangeEvent<any>) => void
}

const DriverAreaSearchComponent: FC<Props> = ({
  handleChangeValues,
  handleDispatch,
  filterValues,
}) => {
  const intl = useIntl()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't values our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm)
  const dispatch = useAppDispatch()
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        dispatch(
          getAllFunction(endpoints.driverArea, actionTypes.GET_ALL_DRIVER_AREAS, {
            searchDriverArea: searchTerm,
          })
        )
        // A separate endpoint should be created here to accommodate the OR search for the input field (firstName and lastName)
        // handleChangeValues({firstName: debouncedSearchTerm.trim()})
        // lastName: debouncedSearchTerm.trim() commented for now until an OR route is provided for the search failed.
        // Change be changed to a different route that searches all the attributes of the customer collection (name(s), phone, email) (a full-text field)
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  const locale: string = useLang()

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex'>
        <div
          style={{
            position: 'relative',
          }}
          className='d-flex align-items-center position-relative my-1 w-100'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute mx-5'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control px-14'
            placeholder={intl.formatMessage({
              id: 'ECOMMERCE.COMMON.DRIVER_AREA_PLACEHOLDER',
            })}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {/* <div className='my-auto'>Filter</div> */}
          <button
            style={{
              position: 'absolute',
              right: locale !== 'ar' ? 0 : undefined,
              left: locale === 'ar' ? 0 : undefined,
              border: 0,
              zIndex: 5,
            }}
            data-kt-menu-trigger='click'
            data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
            type='button'
            // btn-light has been removed to match with the figma design
            className='btn btn-icon '
          >
            <KTSVG
              path='/media/icons/duotune/print/Filter.svg'
              className='svg-icon-8 position-absolute'
            />{' '}
          </button>
          {/* begin::SubMenu */}
          <div
            className='menu menu-sub menu-sub-dropdown w-300px w-md-325px overflow-auto'
            data-kt-menu='true'
            style={{ maxHeight: 400 }}
          >
            {/* begin::Header */}
            <div className='px-7 py-5'>
              {/* ECOMMERCE.CUSTOMERS.SEARCH_CUSTOMERS_PLACEHOLDER */}
              <div className='fs-5 text-dark fw-bolder'>
                {intl.formatMessage({ id: 'ECOMMERCE.COMMON.FILTER_OPTIONS' })}
              </div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='separator border-gray-200'></div>
            {/* end::Separator */}
            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.NAME' })}
                </label>
                <input
                  type='text'
                  className='form-control rounded form-control-sm form-control-solid  ps-10'
                  name='name'
                  // value={filterValues?.firstName} 
                  onChange={handleChangeValues}
                  placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.NAME' })}
                />
              </div>
              {/* end::Input group */}
              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.CITY' })}
                </label>
                <input
                  type='text'
                  className='form-control rounded form-control-sm form-control-solid  ps-10'
                  name='city'
                  // value={filterValues?.lastName}
                  onChange={handleChangeValues}
                  placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CITY' })}
                />
              </div>

              <div className='d-flex justify-content-end pt-5'>
                <div className='hstack gap-2'>
                  <button
                    // disabled={isLoading}
                    type='button'
                    onClick={() => handleDispatch()}
                    className='btn btn-design fw-bold px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='filter'
                  >
                    {intl.formatMessage({ id: 'ECOMMERCE.COMMON.APPLY' })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
    </div>
  )
}

export default DriverAreaSearchComponent
