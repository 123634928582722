/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { toAbsoluteUrl } from '../../../../../_template/helpers'

type Props = {
  className: string
}

const ProductDetails: React.FC<Props> = ({ className }) => {
  const order: IOrder = useSelector(({ orders }: { orders: OrdersState }) => {
    return orders.oneOrder
  })
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='p-0 min-w-200px'>
                  <FormattedMessage id='ECOMMERCE.ORDER.PRODUCT' />
                </th>
                <th className='p-0 min-w-50px'>
                  <FormattedMessage id='ECOMMERCE.ORDER.PRICE' />
                </th>
                <th className='p-0 min-w-50px'>
                  <FormattedMessage id='ECOMMERCE.ORDER.QUANTITY' />
                </th>
                <th className='p-0 min-w-50px'>
                  <FormattedMessage id='ECOMMERCE.ORDER.TOTAL' />
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {order.products &&
                order.products.map((item) => (
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                              className='h-50 align-self-center'
                              alt='product'
                            />
                          </span>
                        </div>
                        <div>
                          <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {item.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='text-start'>
                      <span className='fw-semibold fs-4 me-1'>{'LYD ' + item.price}</span>
                    </td>
                    <td className='text-start'>
                      <span className='fw-semibold fs-4'>{item.quantity}</span>
                    </td>
                    <td className='text-start'>
                      <span className='fw-semibold fs-4'>
                        {'LYD ' + parseFloat(item.price.toString()) * parseFloat(item.quantity.toString())}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default ProductDetails
