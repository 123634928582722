const DocsCount = ({ count, title, showNumbers }: {
    count: number;
    title: string;
    showNumbers: boolean;
}) => {

    return (
        showNumbers ?
            <div className='d-flex flex-row'>
                <div className='mx-auto d-flex flex-row gap-3'>
                    <div>{title}</div>
                    <div style={{
                        color: "#8A9099",
                        backgroundColor: "#E8E9EB",
                        borderRadius: 8
                    }} className='fs-6 px-2 py-1'>{count}</div>
                </div>
            </div> : <div>{title}</div>
    )
}

export default DocsCount