/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../hooks'
import { getAllFunctionById } from '../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import { UsersListLoading } from '../../../ListUsers/users-list/components/loading/UsersListLoading'
import { useParams } from 'react-router-dom'
import { TablesWidget9 } from '../../../../../_template/partials/widgets'
import { Accordion, Button } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../../_template/helpers'
import DetailsBox from './detailsBox'

type Props = {
  className: string
}
const OrdersInfo: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const columns = [
    { code: '', title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.NAME' }) },
    { code: '', title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.EMAIL' }) },
    { code: '', title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.PHONENUMBER' }) },
    { code: '', title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.LOCATION' }) },
  ]
  //let data = [{name: 'Nuha', email: 'ddd@gmail.com', phone: '95544764', location: 'cccc'}]

  const order: any = useSelector(({ orders }: { orders: OrdersState }) => {
    return orders.oneOrder
  })
  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(getAllFunctionById(endpoints.orderEndpoint, actionTypes.GET_ONE_ORDER, id))

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [dispatch, id])

  return (
    <>
      <div>
        {loading && <UsersListLoading />}
        <div
          style={{ backgroundColor: '#e4e6ef30' }}
          className='card mb-5 mb-xl-10'
          id='kt_profile_details_view'
        >
          <div className='p-9'>
            <div className='row mb-7'>
              {/* <TablesWidget9 columns={columns} className='mb-5 mb-xl-8' data={[order?.customer]} /> */}
              <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 p-5'>
                {order.totalAmount && (
                  <DetailsBox
                    rows={[
                      {
                        code: 'STATUS',
                        title: 'Status',
                        icon: 'bi-bag-fill',
                        value: order.status,
                      },

                      // {code:'', title: 'shipping method', icon: 'bi-truck', value: '30/05'},
                      {
                        code: '',
                        title: 'Price',
                        icon: 'bi-truck',
                        value: `${parseFloat(order.totalAmount)?.toFixed(3) + ' LYD'}`,
                      },
                      {
                        code: '',
                        title: 'Fees',
                        icon: 'bi-upc',
                        value: `${order.fees}  LYD`,
                      },

                    ]}
                    className={''}
                    title={`${intl.formatMessage({ id: 'ECOMMERCE.DRIVERS.ORDER' })} #` + order.reference}
                  />
                )}
              </div>
              <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 p-5'>
                <DetailsBox
                  rows={[
                    {
                      code: 'CUSTOMER',
                      title: 'Customer',
                      icon: 'bi-person-circle',
                      value: `${order.customer?.firstName + ' ' + order.customer?.lastName}`,
                    },
                    {
                      code: 'EMAIL',
                      title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.EMAIL' }),
                      icon: 'bi-envelope-at-fill',
                      value: `${order.customer?.email}`,
                    },
                    {
                      code: '',
                      title: 'Phone',
                      icon: 'bi-phone-fill',
                      value: `${order.customer?.phoneNumber}`,
                    },
                  ]}
                  className={''}
                  title={intl.formatMessage({ id: 'ECOMMERCE.CUSTOMERS.CUSTOMERS_DETAILS' })}
                />
              </div>
              <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 p-5'>
                {order && (
                  <DetailsBox
                    rows={[
                      {
                        code: '',
                        title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.PAYMENT&SHIPING' }),
                        icon: 'bi-cash-coin',
                        value: `${order.paidThrough === 'mobicash' || order.status?.code === '100'
                          ? intl.formatMessage({ id: 'ECOMMERCE.ORDER.PAID' })
                          : intl.formatMessage({ id: 'ECOMMERCE.ORDER.NOT_PAID' })
                          }`,
                      },
                      {
                        code: '',
                        title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.PAYMENTMETHOD' }),
                        icon: 'bi-wallet-fill',
                        value: `${order.paidThrough}`,
                      },
                      {
                        code: '',
                        title: intl.formatMessage({ id: 'ECOMMERCE.ORDER.SHIPPING_DATE' }),
                        icon: 'bi-truck',
                        value: '30/05',
                      },
                    ]}
                    className={''}
                    title={intl.formatMessage({ id: 'ECOMMERCE.COMMON.PAYMENT&SHIPING' })}
                  />
                )}
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 p-5'>
                <div
                  className={`card bgi-no-repeat ${className}`}
                  style={{
                    backgroundPosition: 'right top',
                    backgroundSize: '30% auto',
                    backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-1.svg')})`,
                  }}
                >
                  <div className='py-5'>
                    <span className='card-title fw-bold fs-2'>
                      <FormattedMessage id='ECOMMERCE.ORDER.BILLING_ADDRESS' />
                    </span>

                    <div className='w-lg-75 w-md-100 mt-4'>
                      <div>
                        <HorizontalAttributeComponent
                          attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CITY' })}
                          value={order.billingAddress?.city}
                        />

                        <HorizontalAttributeComponent
                          attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.COUNTRY' })}
                          value={order.billingAddress?.country}
                        />

                        <HorizontalAttributeComponent
                          attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATE' })}
                          value={order.billingAddress?.addressLine1}
                        />
                        <HorizontalAttributeComponent
                          attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ZIPCODE' })}
                          value={order.billingAddress?.zipCode}
                        />
                      </div>
                    </div>
                  </div>

                  {/* end::Body */}
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 p-5'>
                <div
                  className={`card bgi-no-repeat ${className}`}
                  style={{
                    backgroundPosition: 'right top',
                    backgroundSize: '30% auto',
                    backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-4.svg')})`,
                  }}
                >
                  {/* begin::Body */}
                  <div className='py-5'>
                    <div className='card-title fw-bold fs-2 d-flex justify-content-between'>
                      <span className='card-title fw-bold fs-2'>
                        <FormattedMessage id='ECOMMERCE.ORDER.SHIPPING_ADDRESS' />
                      </span>
                      <div>
                        <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${order.customer?.address?.positionY},${order.customer?.address?.positionX}`}>
                          <Button variant="success" size='sm'>
                            {intl.formatMessage({ id: 'ECOMMERCE.COMMON.LOCATION' })}
                            <i className={`bi bi-geo-alt-fill`}></i>
                          </Button>
                        </a>
                      </div>
                    </div>

                    <div className='w-lg-75 w-md-100 mt-4'>
                      <div>
                        <HorizontalAttributeComponent
                          attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CITY' })}
                          value={order.shippingAddress?.city}
                        />
                        <HorizontalAttributeComponent
                          attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.COUNTRY' })}
                          value={order.shippingAddress?.country}
                        />
                        <HorizontalAttributeComponent
                          attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATE' })}
                          value={order.shippingAddress?.addressLine1}
                        />
                        <HorizontalAttributeComponent
                          attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ZIPCODE' })}
                          value={order.shippingAddress?.zipCode}
                        />
                      </div>
                    </div>
                  </div>
                  {/* end::Body */}
                </div>
              </div>
            </div>
            <div className='row my-3'>
              <TablesWidget9
                columns={columns}
                className='mb-5 mb-xl-8'
                name='seller'
                data={order?.sellers}
              />
            </div>
            <div className='row my-3'>
              <TablesWidget9
                columns={columns}
                className='mb-5 mb-xl-8'
                name='driver'
                data={order?.driver}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OrdersInfo
const HorizontalAttributeComponent = ({
  attr = '--',
  value = '--',
}: {
  attr?: string
  value?: string | number
}) => (
  <div className='d-flex fs-4 my-auto '>
    <div className='text-muted '>{attr}</div>:<div className='mx-8'>{value}</div>
  </div>
)
