/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, FC, useMemo } from 'react'
import { useIntl, } from 'react-intl'
import { KTSVG, useDebounce } from '../../../../../../_template/helpers'
import { useLang } from '../../../../../../_template/i18n/Metronici18n'
import { useAppDispatch } from '../../../../../hooks'
import { getAllFunction } from '../../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import { useSelector } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useRef } from 'react'
const TypeaheadInput: React.FC<TypeaheadInputProps> = ({
  options,
  onChange,
  placeholder,
  ref,
  selected,
  setSelected,
}) => {
  //const [selected, setSelected] = useState<Option[]>([])

  const handleOnChange = (selected: any[]) => {
    setSelected(selected)
    onChange(selected)
  }
  return (
    <Typeahead
      ref={ref}
      id='basic-typeahead-customer'
      data-kt-menu-dismiss='false'
      labelKey='name'
      options={options}
      onChange={handleOnChange}
      selected={selected}
      placeholder={placeholder}
    />
  )
}
const OrdersSearch: FC<Props> = ({
  values,
  // eventKey,
  handleChangeValues,
  handleDispatch,
  setFiltersValues,
  filtersValues,
}) => {
  const locale: string = useLang()
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const customersList: ICustomer[] = useSelector(
    ({ users }: { users: UsersState }) => users.customers
  ).map((item) => ({
    id: item._id,
    name: item.firstName + ' ' + item.lastName,
  }))
  // const sellersList: ISeller[] = useSelector(({users}: {users: UsersState}) => users.sellers).map(
  //   (item) => ({
  //     id: item._id,
  //     name: item.shopName,
  //   })
  // )
  // const driversList: IUsers[] = useSelector(({users}: {users: UsersState}) => users.usersList).map(
  //   (item) => ({
  //     id: item._id,
  //     name: item.firstName + ' ' + item.lastName,
  //   })
  // )
  const trStatus: any[] = useSelector(
    ({ orders }: { orders: OrdersState }) => orders.trackingStatus
  )?.map((item) => ({
    id: item._id,
    name: item.name,
    code: item.code,
  }))
  const customers = useMemo(() => customersList, [customersList])
  //const sellers = useMemo(() => sellersList, [sellersList])
  //const drivers = useMemo(() => driversList, [driversList])
  const trackingStatus = useMemo(() => trStatus, [trStatus])
  const typeaheadRefs = [useRef<any>(null), useRef<any>(null), useRef<any>(null)]

  const [selected, setSelected] = useState<Option[]>([])
  const [selected1, setSelected1] = useState<Option[]>([])
  const [selected2, setSelected2] = useState<Option[]>([])

  const [searchTerm, setSearchTerm] = useState<string>('')

  const shippingMethod = [
    { name: 'Pickup from relay point', id: 'STORE_PICKUP' },
    { name: 'Deliver to customer', id: 'CUSTOMER_DELIVERY' },
  ]
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        dispatch(
          getAllFunction(endpoints.mainOrderEndpoint, actionTypes.GET_ALL_MAIN_ORDERS, {
            searchOrder: searchTerm,
          })
        )
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )


  const handleCustomerChange = (selected: Option[]) => {
    setSelected(selected)
    setFiltersValues((prevState: any) => ({
      ...prevState,
      customer: selected[0] && selected[0]?.id,
    }))
  }
  // const handleSellerChange = (selected: Option[]) => {
  //   setFiltersValues((prevState: any) => ({
  //     ...prevState,
  //     seller: selected[0] && selected[0]?.id,
  //   }))
  // }
  const handleStatusChange = (selected: Option[]) => {
    setFiltersValues((prevState: any) => ({
      ...prevState,
      status: selected[0] && selected[0]?.id,
    }))
  }
  const handleShippingMethodChange = (selected: Option[]) => {
    setFiltersValues((prevState: any) => ({
      ...prevState,
      shippingMethods: selected[0] && selected[0]?.id,
    }))
  }
  // const handleDriverChange = (selected: Option[]) => {
  //   setFiltersValues((prevState: any) => ({
  //     ...prevState,
  //     user: selected[0] && selected[0]?.id,
  //   }))
  // }
  const handleReset = () => {
    typeaheadRefs.forEach((ref) => {
      ref.current?.clear()
    })
    setSelected([])
    setSelected1([])
    setSelected2([])

    setFiltersValues((prevState: any) => ({
      ...prevState,
      user: undefined,
      shippingMethods: undefined,
      status: undefined,
      customer: [],
    }))
  }

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex'>
        <div
          style={{
            position: 'relative',
          }}
          className='d-flex align-items-center position-relative my-1 w-100'
        >
          {' '}
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute mx-5'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control px-14'
            placeholder={intl.formatMessage({
              id: 'ECOMMERCE.CUSTOMERS.SEARCH_ORDERS_PLACEHOLDER',
            })}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            style={{
              position: 'absolute',
              right: locale !== 'ar' ? 0 : undefined,
              left: locale === 'ar' ? 0 : undefined,
              border: 0,
              zIndex: 5,
            }}
            data-kt-menu-height='auto'
            data-kt-menu-static='true'
            data-kt-menu-trigger='click'
            data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
            type='button'
            className='btn btn-icon'
          >
            <KTSVG
              path='/media/icons/duotune/print/Filter.svg'
              className='svg-icon-8 position-absolute'
            />{' '}
          </button>
          {/* begin::SubMenu */}
          <div
            className='menu menu-sub menu-sub-dropdown w-300px w-md-325px overflow-auto'
            data-kt-menu='true'
            style={{ maxHeight: 'fit-content' }}
          >
            {/* begin::Header */}
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>
                {intl.formatMessage({ id: 'ECOMMERCE.COMMON.FILTER_OPTIONS' })}
              </div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='separator border-gray-200'></div>
            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}
              {/* begin::Input group */}
              {/* <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {' '}
                  {intl.formatMessage({id: 'ECOMMERCE.COMMON.DRIVER'})}
                </label>
                <TypeaheadInput
                  options={drivers}
                  onChange={handleDriverChange}
                  placeholder={intl.formatMessage({id: 'ECOMMERCE.SELECT.DRIVER'})}
                />
              </div> */}
              {/* end::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.CUSTOMER' })}
                </label>

                <TypeaheadInput
                  ref={typeaheadRefs[0]}
                  options={customers}
                  onChange={handleCustomerChange}
                  selected={selected}
                  setSelected={setSelected}
                  placeholder={intl.formatMessage({ id: 'ECOMMERCE.SELECT.CUSTOMER' })}
                />
              </div>
              {/* begin::Input group */}
              {/* <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'ECOMMERCE.COMMON.SELLER'})}
                </label>
                <TypeaheadInput
                  options={sellers}
                  onChange={handleSellerChange}
                  placeholder={intl.formatMessage({id: 'ECOMMERCE.SELECT.SELLER'})}
                />
              </div> */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {' '}
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATUS' })}
                </label>
                <TypeaheadInput
                  ref={typeaheadRefs[1]}
                  options={trackingStatus}
                  onChange={handleStatusChange}
                  selected={selected1}
                  setSelected={setSelected1}
                  placeholder={intl.formatMessage({ id: 'ECOMMERCE.SELECT.STATUS' })}
                />
              </div>
              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>
                  {' '}
                  {intl.formatMessage({ id: 'ECOMMERCE.ORDER.SHIPPING_METHOD' })}
                </label>
                <TypeaheadInput
                  selected={selected2}
                  setSelected={setSelected2}
                  ref={typeaheadRefs[3]}
                  options={shippingMethod}
                  onChange={handleShippingMethodChange}
                  placeholder={intl.formatMessage({ id: 'ECOMMERCE.SELECT.SHIPPING_METHOD' })}
                />
              </div>
              {/* end::Input group */}

              {/* begin::Actions */}
              <div className='d-flex justify-content-between'>
                <button
                  // disabled={isLoading}
                  type='button'
                  onClick={() => handleReset()}
                  className='btn btn-light fw-bold px-6'
                  data-kt-menu-dismiss='false'
                  data-kt-user-table-filter='filter'
                >
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.RESET' })}
                </button>
                <button
                  // disabled={isLoading}
                  type='button'
                  onClick={() => handleDispatch()}
                  className='btn btn-design fw-bold px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.APPLY' })}
                </button>
              </div>

              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>
          {/* end::SubMenu */}
        </div>
        {/* end::Search */}
      </div>
    </div>
  )
}

const OrdersListSearchComponent: FC<Props> = ({
  values,
  // eventKey,
  handleChangeValues,
  handleDispatch,
  setFiltersValues,
  filtersValues,
}) => (
  <>
    <OrdersSearch
      values={values}
      //eventKey={eventKey}
      handleChangeValues={handleChangeValues}
      handleDispatch={handleDispatch}
      setFiltersValues={setFiltersValues}
      filtersValues={filtersValues}
    />
  </>
)

export { OrdersListSearchComponent }

type values = {
  role?: string | undefined
  phone?: string | undefined
  status?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string | undefined
}
type Props = {
  //eventKey: string | undefined
  handleDispatch: () => any
  values: values | undefined
  handleChangeValues: (e: any) => any
  setFiltersValues: (e: any) => any
  filtersValues: any
}
type Option = { name: string; id: string }
type TypeaheadInputProps = {
  options: any[]
  onChange: (selected: any[]) => void
  placeholder?: string
  ref: any
  selected: Option[]
  setSelected: any
}
