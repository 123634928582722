import React from 'react'
import { useSelector } from 'react-redux'
import { MessageView } from '../messageView'

const InfoView = () => {
  const commonState: CommonState = useSelector(({ common }: { common: CommonState }) => common)

  const showMessage = () => {
    return <MessageView variant='alert-success' message={commonState.message.toString()} />
  }

  const showError = () => {
    return <MessageView variant='alert-danger' message={commonState.error.toString()} />
  }

  return (
    <>
      {/* {loading && <Loader />} */}

      {commonState.message && showMessage()}
      {commonState.error && showError()}
    </>
  )
}

export default InfoView
