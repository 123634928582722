/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../../../_template/helpers'

type Props = {
  className: string
  title: string
  value: string
  color: string
  percentage: string
}

const StatisticsDriver: React.FC<Props> = ({className, title, value, color, percentage}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex align-items-center pt-1 pb-0'>
        <div className='d-flex flex-column flex-grow-1 py-1 py-lg-2 me-2'>
          <span className='fw-semibold text-muted fs-5'> {title}</span>
          <a
            href='#'
            className='fw-bold  mb-2 text-hover-primary '
            style={{fontSize: '2.5rem', color: color}}
          >
            {value}
          </a>
        </div>{' '}
        {/* <span className='badge badge-light-success  fw-bold align-self-center mx-8 '  style={{fontSize: '1rem'}}>
          {percentage}
        </span> */}
        {/* <img
          src={toAbsoluteUrl(color)}
          alt=''
          className='align-self-center '
          style={{height: '80%'}}
        /> */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {StatisticsDriver}
