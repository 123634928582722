import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../../_template/helpers'
import {useLang} from '../../../../../../_template/i18n/Metronici18n'
import Export from '../../../../../components/export'
import {useListView} from '../../core/ListViewProvider'
import {CustomersListFilter} from './CustomersListFilter'

const CustomersListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  const locale: string = useLang()

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <Export dataType='customers' />

      {/* begin::Add user */}
      {/* <button type='button' className='btn btn-design btn-icon' onClick={openAddUserModal}> */}
      {/* <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2 svg-icon-light' /> */}
      {/* <FormattedMessage id='ECOMMERCE.CUSTOMERS.EDIT.ADD_CUSTOMER' /> */}
      {/* </button> */}
      {/* end::Add user */}
    </div>
  )
}

export {CustomersListToolbar}
