import { FC } from 'react'
import DriverAreaWrapper from './driverArea-list/driverAreaList'

const DriverAreaPageWrapper: FC = () => {
  return (
    <>
      <DriverAreaWrapper />
    </>
  )
}

export default DriverAreaPageWrapper
