import {PayloadAction} from '@reduxjs/toolkit'
import * as actionTypes from '../actionTypes'

const initialState: OrdersState = {
  orders: [],
  oneOrder: {
    products: [{name: ' ', price: 0, quantity: 0, total: 0}],
  },
  data: '',
  trackingStatus: [],
  oneOrderStatus: [],
  oneMainOrderStatus: [],
  oneMainOrderTracking: [],
  oneOrderTracking: [],
  suggestions: [],
  OneSuggestion: {},
  mainOrders: [],
  oneMainOrder: {},
  googleMaps: {
    customer: {positionX: '', positionY: ''},
    sellers: {positionX: '', positionY: ''},
    drivers: {positionX: '', positionY: ''},
  },
  totalPages: 1,
  totalPagesMainOrders: 1,
  oneMainOrderGoogleMap: {
    customerPos: {lng: 0, lat: 0},
    sellers: [{sellerPos: {lng: 0, lat: 0}, driverPos: {lng: 0, lat: 0}}],
  },
  allSellersPOS: [{sellerPos: {lng: 0, lat: 0}, driverPos: {lng: 0, lat: 0}}],
  oneOrderGoogleMap: {
    customerPos: {lng: 0, lat: 0},
    sellerPos: {lng: 0, lat: 0},
    driverPos: {lng: 0, lat: 0},
  },
  totalItemsMainOrders: 0,
  totalItems: 0,
}
export default function ordersReducer(
  state = initialState,
  action: PayloadAction<any>
): OrdersState {
  switch (action.type) {
    case actionTypes.GET_ONE_ORDER: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneOrder: action.payload,
        oneOrderStatus: [action.payload[0]?.status],
      }
    }
    case actionTypes.GET_ONE_MAIN_ORDER: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneMainOrder: action.payload,
        orders: action.payload.subOrders,
        oneMainOrderStatus: action.payload,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      }
    }
    case actionTypes.GET_ONE_MAIN_ORDER_TRACKING: {
      return {
        // Again, one less level of nesting to copy
        ...state,

        orders: action.payload.subOrders,
        oneMainOrderTracking: action.payload,
      }
    }
    case actionTypes.GET_ONE_ORDER_TRACKING: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneOrderGoogleMap: {
          customerPos: {
            lat: parseFloat(action.payload[0].order.customer?.address?.positionY)
              ? parseFloat(action.payload[0].order.customer?.address?.positionY)
              : 0,
            lng: parseFloat(action.payload[0].order.customer?.address?.positionX)
              ? parseFloat(action.payload[0].order.customer?.address?.positionX)
              : 0,
          },
          sellerPos: {
            lat: parseFloat(action.payload[0].order.sellers?.address?.positionY)
              ? parseFloat(action.payload[0].order.sellers?.address?.positionY)
              : 0,
            lng: parseFloat(action.payload[0].order.sellers?.address?.positionX)
              ? parseFloat(action.payload[0].order.sellers?.address?.positionX)
              : 0,
          },
          driverPos: {
            lat: parseFloat(action.payload[0].order.driver?.address?.positionY)
              ? parseFloat(action.payload[0].order.driver?.address?.positionY)
              : 0,
            lng: parseFloat(action.payload[0].order.driver?.address?.positionX)
              ? parseFloat(action.payload[0].order.driver?.address?.positionX)
              : 0,
          },
        },
        oneOrder: action.payload[0].order,
        oneOrderTracking: action.payload,
      }
    }
    case actionTypes.GET_ONE_SUGGESTION: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        OneSuggestion: {
          ...action.payload,
          driver: Object.values(
            action.payload.driver.reduce(
              (acc: any, cur: any) => Object.assign(acc, {[cur._id.toString()]: cur}),
              {}
            )
          ),
        },
        oneOrderStatus: [action.payload[0]?.status],
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      }
    }
    case actionTypes.GET_ALL_ORDERS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        orders: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      }
    }
    case actionTypes.GET_ALL_MAIN_ORDERS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        mainOrders: action.payload.data,
        totalPagesMainOrders: action.payload.totalPages,
        totalItemsMainOrders: action.payload.totalItems,
      }
    }
    case actionTypes.GET_ALL_SUGGESTIONS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        suggestions: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      }
    }
    case actionTypes.GET_ALL_TRACKING_STATUS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        trackingStatus: action.payload.data,
        //totalPages: action.payload.totalPages,
        //totalItems: action.payload.totalItems,
      }
    }
    case actionTypes.GET_POSITIONS_TO_GOOGLE_MAP: {
      return {
        ...state,
        oneMainOrderGoogleMap: {
          customerPos: {
            lat: parseFloat(action.payload.customer?.address?.positionY)
              ? parseFloat(action.payload.customer?.address?.positionY)
              : 0,
            lng: parseFloat(action.payload.customer?.address?.positionX)
              ? parseFloat(action.payload.customer?.address?.positionX)
              : 0,
          },
          sellers: [
            action.payload.subOrders.map((subOrder: any) => ({
              sellerPos: {
                lat: parseFloat(subOrder.sellers?.address?.positionY),
                lng: parseFloat(subOrder.sellers?.address?.positionX),
              },
              driverPos: {
                lat: parseFloat(subOrder.driver?.address?.positionY)
                  ? parseFloat(subOrder.driver?.address?.positionY)
                  : 0,
                lng: parseFloat(subOrder.driver?.address?.positionX)
                  ? parseFloat(subOrder.driver?.address?.positionX)
                  : 0,
              },
            })),
          ],
        },

      }
    }

    default:
      return state
  }
}
