// @ts-nocheck
import { Column } from 'react-table'

import { User } from '../../core/_models'
import { FormattedMessage, useIntl } from 'react-intl'
// import {CustomerActionsCell} from '../../../../../_template/layout/components/columns/CustomerActionsCell'
// import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
// import { CustomerActionsCell } from '../../../../../../../_template/layout/components/columns/CustomerActionsCell'
import {
  UserCustomHeader,
} from '../../../../../../../_template/layout/components/columns'
import { DateCell } from '../../../../../../../_template/layout/components/columns/DateAnTimeCell'
import { OrderStatusActionsCell } from '../../../../../../../_template/layout/components/columns/OrderStatusActionsCell'
import { SelectionCell } from './selectionCell'
import { SelectionHeader } from './selectionHeader'
import { Link } from 'react-router-dom'

const driverAreaColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index]._id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.NAME' />}
        className='min-w-100px'
      />
    ),
    accessor: 'name',
    // Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.DESCRIPTION' />}
        className='min-w-150px'
      />
    ),
    accessor: 'description',
    // Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CITY' />}
        className='min-w-100px'
      />
    ),
    accessor: 'city',
  },
  // Becouse viewing the radius, lat and long as plain numbers will not be if usefullness to the user.
  // Thus the view butten will direct the user to a maps component that will have the point on the map of the area.
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),

    id: 'createDate',
    Cell: ({ ...props }) => <DateCell date={props.data[props.row.index].createDate} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.COORDINATES' />}
        className='min-w-100px'
      />
    ),
    id: 'coordinates',
    Cell: ({ ...props }) => {
      const intl = useIntl()
      return <Link className='menu-link px-3' to={`/configutation/driver_area/view/${props.data[props.row.index]._id}`}>
        <button className='btn btn-design-blue '>
          {intl.formatMessage({ id: 'ECOMMERCE.COMMON.VIEWINMAP' })}
        </button>
      </Link>
    }
    ,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='MENU.ACTIONS' />}
  //       className='text-end min-w-100px'
  //     />
  //   ),
  //   id: 'actions',
  //   Cell: ({ ...props }) => <OrderStatusActionsCell id={props.data[props.row.index]._id} />,
  // },
]

export default driverAreaColumns
