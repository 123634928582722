import React, {FC} from 'react'
import {PageTitle} from '../../../_template/layout/core'
// import {BuilderPage} from './BuilderPage'
import {useIntl} from 'react-intl'
import {MainOrdersListWrapper} from './main-orders-list/MainOrdersList'

const MainOrdersPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MAIN.ORDERS'})}</PageTitle>
      <MainOrdersListWrapper />
    </>
  )
}

export default MainOrdersPageWrapper
