export const SUCCESSFULLY_CREATED = 2021
export const SUCCESSFULLY_DELETED = 2020
export const SUCCESSFULLY_UPDATED = 2021
export const NO_DATA_FOUND = 2200
export const DATA_SUCCESSFULLY_RETRIEVED = 2201

//error codes
export const USER_NOT_FOUND = 4100
export const USER_INVALID_PASSWORD = 4101
export const USER_IS_DESACTIVATE = 4102
export const USER_INACTIVE = 4103
export const USER_EMAIL_ALREADY_EXISTS = 4104
export const USER_DOCUMENT_ALREADY_EXISTS = 4105
export const USER_HAS_DOCUMENT_TYPE = 4106
export const USER_EXISTS_ERROR = 4107
export const USER_EMAIL_EXIST_ERROR = 4108
export const USER_MOBILE_NUMBER_EXIST_ERROR = 4109
