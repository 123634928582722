import {FC} from 'react'

type Props = {
value?: string
}

const FirstCell: FC<Props> = ({value}) => (
  <div style={{color:"#3F434A"}}>{value}</div>
)

export {FirstCell}
