import {Suspense} from 'react'
import {ThemeProvider} from 'react-bootstrap'
import {Outlet} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {I18nProvider} from '../_template/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_template/layout/core'
import {MasterInit} from '../_template/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {useLang} from '../_template/i18n/Metronici18n'
import {Provider} from 'react-redux'
import {store} from './redux/store'

const App = () => {
  const locale: string = useLang()

  return (
    <Provider store={store}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Helmet
          htmlAttributes={{
            lang: locale,
            dir: locale === 'ar' ? 'rtl' : 'ltr',
          }}
        />
        <ThemeProvider dir={locale === 'ar' ? 'rtl' : 'ltr'}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </ThemeProvider>
      </Suspense>
    </Provider>
  )
}

export {App}
