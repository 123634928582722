import {useField} from 'formik'

export const CustomSelect: React.FC<any & React.SelectHTMLAttributes<HTMLSelectElement>> = ({
  label,
  options,
  ...props
}) => {
  const [field, meta] = useField(props)
  return (
    <>
      <label className='required fw-bold fs-6 mb-2' htmlFor={props.id || props.name}>
        {label}
      </label>
      <select {...field} {...props}>
        <option key={'0'} value={''}>
          {''}
        </option>
        {options?.map((option: any) => (
          <option key={option._id} value={option._id}>
            {option.name ? option.name : option.firstName ? option.firstName + option.lastName : ''}
          </option>
        ))}
      </select>
      {meta.touched && meta.error && <div className='text-danger fs-7 mt-2'>{meta.error}</div>}
    </>
  )
}
