/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../helpers'
import {useListView} from '../../../../app/pages/ListSuggestions/suggestions-list/core/ListViewProvider'
import {Link} from 'react-router-dom'
import {useLang} from '../../../i18n/Metronici18n'
import {deleteFunction, getAllFunction} from '../../../../app/redux/actions/commonCrud'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../app/redux/actionTypes'
import {useAppDispatch} from '../../../../app/hooks'
import {useIntl} from 'react-intl'

type Props = {
  id: ID
}

const SuggestionsActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  // const {query} = useQueryResponse()
  // const queryClient = useQueryClient()
  const locale: string = useLang()
  const intl = useIntl()
  // const location = useLocation()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const dispatch = useAppDispatch()
  const deleteItem = useMutation(() => dispatch(deleteFunction(endpoints.userEndpoint, id)), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      dispatch(getAllFunction(endpoints.suggestionsEndpoint, actionTypes.GET_ALL_SUGGESTIONS, {}))
    },
  })
  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
      >
        <KTSVG path='/media/icons/duotune/print/dots.svg' className='svg-icon-4 ml-5' />{' '}
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            {intl.formatMessage({id: 'ECOMMERCE.DRIVERS.LIST'})}
          </a>
        </div>
        {/* end::Menu item */}

      </div>
      {/* end::Menu */}
    </>
  )
}

export {SuggestionsActionsCell}
