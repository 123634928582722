import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { DriverAreaListHeader } from './components/header/driverAreaHeader'
import DriverAreaTable from './table/driverAreaTable'
// import CustomerEditModal from './customer-edit-modal/CustomerEditModal'
import { useAppDispatch } from '../../../../hooks'
import { useEffect } from 'react'
import { getAllFunction } from '../../../../redux/actions/commonCrud'
import InfoView from '../../../../../_template/layout/components/infoView'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../../_template/layout/core'
import DriverAreaEditModal from './driverAreaedit-modal/driverAreaEditModal'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'

const DriverAreaList = () => {
  const { itemIdForUpdate } = useListView()
  const intl = useIntl()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAllFunction(endpoints.DriverAreas, actionTypes.GET_ALL_DRIVER_AREAS, {}))
  }, [dispatch])

  return (
    <>
      <InfoView />
      <div
        className='d-flex justify-content-between'
        style={{ marginTop: '-4%', marginBottom: '25px' }}
      >
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({ id: 'ECOMMERCE.ORDER_STATUS.DRIVER_AREA' })}
          </PageTitle>
        </div>
        <div>
          <DriverAreaListHeader />
        </div>
      </div>
      <DriverAreaTable />

      {/* <OrderStatusManagementListHeader /> */}
      {/* <OrderStatusManagementTable /> */}
      {itemIdForUpdate !== undefined && <DriverAreaEditModal />}
    </>
  )
}

const DriverAreaWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DriverAreaList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export default DriverAreaWrapper
