import React, {useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useAppDispatch} from '../hooks'
import {getAllFunction} from '../redux/actions/commonCrud'
import {trackingStatus} from '../../_template/helpers'
import {GET_ALL_STATUS} from '../redux/actionTypes'
import {deleteOrderStatu} from '../modules/auth/core/_requests'
import {useIntl} from 'react-intl'
import {fetchSuccess} from '../redux/actions/common'
import { useAuth } from '../modules/auth'

const LogoutModal = ({show, handleClose}: {handleClose: any; show: any}) => {
  const intl = useIntl()
  const {auth, logout} = useAuth()
  const dispatch = useAppDispatch()
  const handleDispatch = () => {
    dispatch(getAllFunction(trackingStatus, GET_ALL_STATUS, {}))
  }
  const handleDelete = () => {
    logout()
    handleClose();
    // deleteOrderStatu(id)
    //     .then(() => {
    //         dispatch(fetchSuccess("Data successfully deleted"))
    //         handleDispatch()
    //         handleClose();
    //     })
    //     .catch((err) => {
    //       
    //         alert('error deleting the order stat us')
    //     })
  }

  return (
    <Modal show={show}>
      <Modal.Header >
        <Modal.Title>{intl.formatMessage({id: 'ECOMMERCE.COMMON.SESSION_EXPIRED'})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{intl.formatMessage({id: 'ECOMMERCE.COMMON.MUST_LOGOUT'})}</Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleClose}>
                    {intl.formatMessage({ id: 'ECOMMERCE.COMMON.CANCEL' })}
                </Button> */}
        <Button variant='danger' onClick={handleDelete}>
          {intl.formatMessage({id: 'ECOMMERCE.COMMON.LOGOUT'}).toUpperCase()}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default LogoutModal
