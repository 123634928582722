import React, {FC, useState} from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import OrdersInfo from './ordersInfo'
import InvoiceDetails from './invoice'
import ProductTab from './productTab'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_template/layout/core'
import InfoView from '../../../../../_template/layout/components/infoView'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'

const OrdersDetailsTabs: FC = () => {
  const [key, setKey] = useState('order')
  const intl = useIntl()
  const order: IOrder = useSelector(({orders}: {orders: OrdersState}) => {
    return orders.oneOrder
  })
  return (
    <div>
      <PageTitle
        breadcrumbs={getBreadCrambs(
          intl,
          {
            isActive: true,
            path: '/main-orders',
            title:`${intl.formatMessage({id:'ECOMMERCE.ORDER'})}`,
            isSeparator: false,
          },
          {
            isActive: true,
            path: `/main-orders/${order.mainOrder}/orders`,
            title: `${intl.formatMessage({id:'ECOMMERCE.ORDER.SUB.ORDERS'})}`,
            isSeparator: false,
          }
        )}
      >
        {intl.formatMessage({id: 'ECOMMERCE.ORDER.DETAILS'})}
      </PageTitle>
      <InfoView />
      <Tabs
        className='nav-custom-tabs '
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
      >
        <Tab eventKey='order' title={intl.formatMessage({id: 'ECOMMERCE.ORDER.DETAILS'})}>
          <OrdersInfo className='p-5' />
        </Tab>
        <Tab eventKey='product' title={intl.formatMessage({id: 'ECOMMERCE.ORDER.PRODUCT_DETAILS'})}>
          <ProductTab className='p-5' />
        </Tab>
        <Tab eventKey='invoice' title={intl.formatMessage({id: 'ECOMMERCE.ORDER.INVOICE_TITLE'})}>
          <InvoiceDetails className='p-5' />
        </Tab>
      </Tabs>
    </div>
  )
}

export default OrdersDetailsTabs
