import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomRow, CustomHeaderColumn } from '../../../../../_template/layout/components/columns'
import { usersColumns } from './columns/_columns'
import { Seller } from '../core/_models'
import { KTCard, KTCardBody } from '../../../../../_template/helpers'
import { SellersListSearchComponent } from '../components/header/SellersListSearchComponent'
import { useAppDispatch } from '../../../../hooks'
import * as actionTypes from '../../../../redux/actionTypes'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import { getAllFunction } from '../../../../redux/actions/commonCrud'
import { useSelector } from 'react-redux'
import PaginationComponent from '../../../../../_template/layout/components/pagination'
// import PaginationComponent from '../components/paginationComp'

type Props = {
  eventKey?: string | undefined
  setCount: any
}
type values = {
  role?: string | undefined
  phoneNumber?: string | undefined
  status?: string | undefined
  shopName?: string | undefined
  email?: string | undefined
}
const SellersTable: React.FC<Props> = ({ eventKey, setCount }) => {
  const seller: ISeller[] = useSelector(({ users }: { users: UsersState }) => {
    return users.sellers
  })
  const totalPages: number = useSelector(({ users }: { users: UsersState }) => {
    return users.totalPages
  })
  const totalItems: number = useSelector(({ users }: { users: UsersState }) => {
    return users.totalItems
  })
  const [values, setValues] = useState<values | undefined>({
    status: eventKey === 'All' ? undefined : eventKey,
  })
  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }
  const resetData = () => {
    setValues({ shopName: '', email: '', phoneNumber: '' })
  }
  const data = useMemo(() => seller, [seller])
  const columns = useMemo(() => usersColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })
  const dispatch = useAppDispatch()
  const handleDispatch = () => {
    dispatch(getAllFunction(endpoints.sellerEndpoint, actionTypes.GET_ALL_SELLERS, values))
  }

  useEffect(() => {
    if (totalItems) {
      setCount(totalItems)
    } else {
      setCount(0)
    }
  }, [totalItems])

  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <div className='w-100'>
          <SellersListSearchComponent
            resetData={resetData}
            values={values}
            handleChangeValues={handleChangeValues}
            handleDispatch={handleDispatch}
            eventKey={eventKey}
          />
        </div>

        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<Seller>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-500 fs-6' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<Seller>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <UsersListPagination /> */}
        <PaginationComponent
          values={values}
          setValues={setValues}
          endpoint={endpoints.sellerEndpoint}
          actionType={actionTypes.GET_ALL_SELLERS}
          totalPages={totalPages}
        />
      </KTCardBody>
    </KTCard>
  )
}

export { SellersTable }
