import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../../../_template/helpers'
import {useLang} from '../../../../../../../_template/i18n/Metronici18n'
import Export from '../../../../../../components/export'
import {useListView} from '../../core/ListViewProvider'
// import {NotificationListFilter} from './deliveryTypeFilter'

const NotificationListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddNotificationModal = () => {
    setItemIdForUpdate(null)
  }
  // const locale: string = useLang()

  return (
    <div className='d-flex justify-content-end m-4' data-kt-user-table-toolbar='base'>
    {/* <Export dataType='deliveryType' /> */}

    {/* begin::Add user */}
    {/* <button type='button' className='btn btn-design btn-icon' onClick={openAddNotificationModal}>
      <KTSVG
        path='/media/icons/duotune/arrows/arr075.svg'
        className='svg-icon-2 svg-icon-light'
      />
    </button> */}
    {/* end::Add user */}
  </div>
  )
}

export {NotificationListToolbar}
