/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='/media/menuIcons/Vector.svg'
      />
      <SidebarMenuItem
        to='/main-orders'
        icon='/media/icons/duotune/general/gen019.svg'
        title={intl.formatMessage({id: 'MENU.ORDERS'})}
        fontIcon='/media/menuIcons/orders.svg'
      />
      {/* <SidebarMenuItem
        to='/orders'
        icon='/media/icons/duotune/general/gen019.svg'
        title={intl.formatMessage({id: 'MENU.ORDERS'})}
        fontIcon='/media/menuIcons/orders.svg'
      /> */}

      <SidebarMenuItem
        to='/drivers'
        icon='/media/icons/duotune/general/gen019.svg'
        title={intl.formatMessage({id: 'MENU.DRIVERS'})}
        fontIcon='/media/menuIcons/drivers.svg'
      />
      <SidebarMenuItem
        to='/customers'
        icon='/media/icons/duotune/general/gen019.svg'
        title={intl.formatMessage({id: 'MENU.CUSTOMERS'})}
        fontIcon='/media/menuIcons/custmors.svg'
      />
      <SidebarMenuItem
        to='/sellers'
        icon='/media/icons/duotune/general/gen019.svg'
        title={intl.formatMessage({id: 'MENU.SELLERS'})}
        fontIcon='/media/menuIcons/seller.svg'
      />

      {/* <SidebarMenuItemWithSub
        to='/relay-points'
        icon='/media/menuIcons/relaypoint.svg'
        title={intl.formatMessage({id: 'MENU.RELAY.POINTS'})}
        
      >
        <SidebarMenuItem
          to='/relay-points'
          title={intl.formatMessage({id: 'MENU.RELAY.POINTS'})}
          hasBullet={false}
        />
        <SidebarMenuItem
          to='/relayPoints/map'
          title={intl.formatMessage({id: 'MENU.RELAY.POINTS.MAP'})}
          hasBullet={false}
        />
      </SidebarMenuItemWithSub> */}

      <SidebarMenuItem
        to='/users'
        icon='/media/icons/duotune/general/gen019.svg'
        title={intl.formatMessage({id: 'MENU.USERS'})}
        fontIcon='/media/menuIcons/users.svg'
      />
      <SidebarMenuItemWithSub
        to='/configuration'
        title={intl.formatMessage({id: 'MENU.CONFIG'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItem
          to='/configutation/deliveryType'
          title={intl.formatMessage({id: 'MENU.DELIVERY.TYPE'})}
          hasBullet={false}
        />
        <SidebarMenuItem
          to='/configutation/notification'
          title={intl.formatMessage({id: 'MENU.NOTIFICATION'})}
          hasBullet={false}
        />

        <SidebarMenuItem
          to='/configutation/order_status_management'
          title={intl.formatMessage({id: 'ECOMMERCE.ORDER_STATUS.ORDER_STATUS_MANAGEMENT'})}
          hasBullet={false}
        />

        <SidebarMenuItem
          to='/configutation/driver_area'
          title={intl.formatMessage({id: 'ECOMMERCE.ORDER_STATUS.DRIVER_AREA'})}
          hasBullet={false}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
