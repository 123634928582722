import {useEffect} from 'react'
// import {UserEditModalHeader} from './UserEditModalHeader'
import {UserEditModalFormWrapper} from './UserEditModalFormWrapper'
// import MultiStepForm from '../addUserStepper'

const UserEditModal = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <UserEditModalFormWrapper />
    </>
  )
}

export {UserEditModal}
