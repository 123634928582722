import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {NotificationListHeader} from './components/header/notificationHeader'
import NotificationTable from './table/notificationTable'
// import CustomerEditModal from './customer-edit-modal/CustomerEditModal'
import {useAppDispatch} from '../../../../hooks'
import {useEffect} from 'react'
import InfoView from '../../../../../_template/layout/components/infoView'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_template/layout/core'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'
import { NotificationEditModalForm } from './notification-edit-modal/NotificationEditModalForm'
import NotificationEditModal from './notification-edit-modal/NotificationEditModal'

const NotificationList = () => {
  const {itemIdForUpdate} = useListView()
  const intl = useIntl()
  const dispatch = useAppDispatch()
  useEffect(() => {
  }, [dispatch])
  return (
    <>
      <InfoView />
      <div className='d-flex justify-content-between'
        style={{marginTop: '-4%', marginBottom: '25px'}}>
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({id: 'MENU.NOTIFICATION'})}
          </PageTitle>
        </div>
        <div>
          <NotificationListHeader />
        </div>
      </div>
      <NotificationTable />

      {/* <NotificationListHeader /> */}
      {/* <NotificationTable /> */}
      {itemIdForUpdate !== undefined && <NotificationEditModal />}
    </>
  )
}




const NotificationListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <NotificationList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export default NotificationListWrapper
