import React, { useEffect, useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { getAllFunction } from '../../../../redux/actions/commonCrud'
import { useAppDispatch } from '../../../../hooks'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import { useSelector } from 'react-redux'
import { PageTitle } from '../../../../../_template/layout/core'
import InfoView from '../../../../../_template/layout/components/infoView'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'
const googlePlaceKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PLACE || ''

export function RelayPointsMap() {
  const containerStyle = {
    width: '100%',
    height: '400px',
  }
  const center = {
    lat: 35.7251877,
    lng: 10.7194172,
  }

  //const [map, setMap] = useState(null)

  const intl = useIntl()
  const relayPoint: IRelayPoint[] = useSelector(({ users }: { users: UsersState }) => {
    return users.relayPoints
  })
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getAllFunction(endpoints.relayPointEndpoint, actionTypes.GET_ALL_FRELAY_POINTS, {}))
  }, [dispatch])
  return (
    <div>
      <PageTitle breadcrumbs={getBreadCrambs(intl)}>
        {intl.formatMessage({ id: 'MENU.RELAY.POINTS.MAP' })}
      </PageTitle>
      <InfoView />
      <div className='google-map'>
        <LoadScript googleMapsApiKey={googlePlaceKey}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          //   onLoad={(map)=>setMap(map)}
          //options={{streetViewControl:false}}
          //onReady={onMapReady}
          >
            {relayPoint &&
              relayPoint.map(
                (item) =>
                  item.positionX &&
                  item.positionY && (
                    <Marker
                      position={{ lat: parseFloat(item.positionX), lng: parseFloat(item.positionY) }}
                    />
                  )
              )}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  )
}
