import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_template/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_template/assets/ts/_utils'
import {WithChildren} from '../../_template/helpers'
// import BuilderPageWrapper from '../pages/ListUsers/UsersPageWrapper'
// import OrdersInfo from '../pages/ListOrders/orders-list/order-info/ordersInfo'
import UsersList from '../pages/ListUsers/UsersPageWrapper'
import SellersPageWrapper from '../pages/ListSellers/SellersPageWrapper'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import CustomersPageWrapper from '../pages/ListCustomers/CustomersPageWrapper'
import RelayPointsPageWrapper from '../pages/ListRelayPoints/RelayPointsPageWrapper'
import OrdersPageWrapper from '../pages/ListOrders/OrdersPageWrapper'
import {CustomersInfo} from '../pages/ListCustomers/customers-list/customer-info/CustomersInfo'
import {RelayPointInfo} from '../pages/ListRelayPoints/relayPoints-list/relayPoint-info/RelayPointInfo'
import {SellersInfo} from '../pages/ListSellers/sellers-list/sellers-info/SellersInfo'
import {UsersInfo} from '../pages/ListUsers/users-list/users-info/UsersInfo'
import {OrdersTracking} from '../pages/ListOrders/orders-list/tracking/tracking'

// import DeliveryTypePageWrapper from ''
import {DeliveryTypeInfo} from '../pages/configuration/ListDelivery/deliveryType-list/deliveryType-info/DeliveryTypeInfo'

import DriversPageWrapper from '../pages/ListDrivers/DriversPageWrapper'
import OrdersDetailsTabs from '../pages/ListOrders/orders-list/order-info/tab'
import {RelayPointsMap} from '../pages/ListRelayPoints/relayPoints-list/maps/relayPointMap'
import {DriversInfo} from '../pages/ListDrivers/drivers-list/drivers-info/DriversInfo'
import { Notifications } from '../modules/accounts/components/settings/cards/Notifications'
import { NotificationEditModalForm } from '../pages/configuration/notifications/notifications-list/notification-edit-modal/NotificationEditModalForm'
import NotificationPageWrapper from '../pages/configuration/notifications/notificationsPageWrapper'
import DriverAreaPageWrapper from '../pages/configuration/DriverArea/driverAreaPageWrapper'
import DriverAreaView from '../pages/configuration/DriverArea/driverArea-list/DriverAreaView'
import SuggestionsPageWrapper from '../pages/ListSuggestions/SuggestionsPageWrapper'
import MainOrdersPageWrapper from '../pages/mainOrders/MainOrdersPageWrapper'
import MainOrdersDetailsTabs from '../pages/mainOrders/main-orders-list/Mainorder-info/tab'
import { MainOrdersTracking } from '../pages/mainOrders/main-orders-list/tracking/tracking'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const DeliveryTypePageWrapper = lazy(
    () => import('../pages/configuration/ListDelivery/deliveryTypePageWrapper')
  )

  const OrderStatusManagementPageWrapper = lazy(
    () =>
      import('../pages/configuration/ListOrderStatusManagement/orderStatusManagementPageWrapper')
  )
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='sellers' element={<SellersPageWrapper />} />
        <Route path='customers' element={<CustomersPageWrapper />} />
        <Route path='main-orders/:id/orders' element={<OrdersPageWrapper />} />
        <Route path='relayPoints/map' element={<RelayPointsMap />} />
        <Route path='relay-points' element={<RelayPointsPageWrapper />} />

        <Route path='orders/:id' element={<OrdersDetailsTabs />} />
        <Route path='main-orders/:id' element={<MainOrdersDetailsTabs />} />
      
        <Route path='orders/tracking/:id' element={<OrdersTracking />} />
        <Route path='main-orders/tracking/:id' element={<MainOrdersTracking />} />
        <Route path='sellers/:id' element={<SellersInfo />} />
        <Route path='relay-points/:id' element={<RelayPointInfo />} />
        <Route path='customers/:id' element={<CustomersInfo />} />

        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}

        <Route
          path='users/:id'
          element={
            <SuspensedView>
              <UsersInfo />
            </SuspensedView>
          }
        />
        <Route
          path='users'
          element={
            <SuspensedView>
              <UsersList />
            </SuspensedView>
          }
        />
        <Route
          path='drivers/:id'
          element={
            <SuspensedView>
              <DriversInfo />
            </SuspensedView>
          }
        />
        <Route
          path='drivers'
          element={
            <SuspensedView>
              <DriversPageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/:id/suggestions'
          element={
            <SuspensedView>
              <SuggestionsPageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='main-orders'
          element={
            <SuspensedView>
              <MainOrdersPageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='configutation/deliveryType'
          element={
            <SuspensedView>
              <DeliveryTypePageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='configutation/deliveryType/:id'
          element={
            <SuspensedView>
              <DeliveryTypeInfo />
            </SuspensedView>
          }
        />
        <Route
          path='configutation/notification'
          element={
            <SuspensedView>
              <NotificationPageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='configutation/order_status_management'
          element={
            <SuspensedView>
              <OrderStatusManagementPageWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='configutation/driver_area'
          element={
            <SuspensedView>
              <DriverAreaPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='configutation/driver_area/view/:id' element={<DriverAreaView />} />

        {/* <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
