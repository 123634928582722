import { ListViewProvider } from './core/ListViewProvider'
import { MainOrdersListHeader } from './components/header/MainOrderListHeader'
import { OrdersTable } from './table/MainOrdersTable'
import { useAppDispatch } from '../../../hooks'
import { useEffect, useState } from 'react'
import { getAllFunction } from '../../../redux/actions/commonCrud'
import InfoView from '../../../../_template/layout/components/infoView'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../redux/actionTypes'
import { Tab, Tabs } from 'react-bootstrap'
import { PageTitle } from '../../../../_template/layout/core'
import { useIntl } from 'react-intl'
import getBreadCrambs from '../../../routing/breadcramps/getBreadCrambs'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import DocsCount from '../../../components/DocsCount'

interface state {
  status?: string | undefined
  role?: string | undefined
  page?: number | undefined
}
const MainOrdersList = () => {
  // const {itemIdForUpdate} = useListView()
  const [status, setStatus] = useState<state>({ status: undefined, page: 0 })
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [count, setCount] = useState(0);
  const REACT_APP_ROLE_DRIVER = process.env.REACT_APP_ROLE_DRIVER ||""

  useEffect(() => {
    //dispatch(getAllFunction(endpoints.orderEndpoint, actionTypes.GET_ALL_ORDERS, status))
  }, [dispatch, status])
  useEffect(() => {
    (async () => {
      await dispatch(getAllFunction(endpoints.customerEndpoint, actionTypes.GET_ALL_CUSTOMERS, { size: 100 }))
      await dispatch(getAllFunction(endpoints.sellerEndpoint, actionTypes.GET_ALL_SELLERS, { size: 100 }))
      await dispatch(getAllFunction(endpoints.trackingStatus, actionTypes.GET_ALL_TRACKING_STATUS, {}))
      await dispatch(getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, { size: 100, role: REACT_APP_ROLE_DRIVER }))
      await dispatch(getAllFunction(endpoints.mainOrderEndpoint, actionTypes.GET_ALL_MAIN_ORDERS, {}))
    })()
  }, [])
  return (
    <>
      <div
        className='d-flex justify-content-between'
        style={{ marginTop: '-4%', marginBottom: '25px' }}
      >
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({ id: 'MENU.ORDERS' })}
          </PageTitle>
          <InfoView />
        </div>

        <div>
          <MainOrdersListHeader />
        </div>
      </div>

      <Tabs id='controlled-tab-example' defaultActiveKey='All' >
        <Tab
          className='nav-item'
          eventKey='All'
          title={<DocsCount showNumbers={true} count={count} title={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ALL' })} />}
        >
          <OrdersTable setCount={setCount} />
        </Tab>
      </Tabs >
      {/* </Tab>
        <Tab
          className='nav-item'
          eventKey='PENDING'
          title={intl.formatMessage({id: 'ECOMMERCE.COMMON.PENDING'})}
        >
          <OrdersTable eventKey='PENDING' />
        </Tab>
        <Tab
          className='nav-item'
          eventKey='COMPLETED'
          title={intl.formatMessage({id: 'ECOMMERCE.COMMON.COMPLETED'})}
        >
          <OrdersTable eventKey='COMPLETED' />
        </Tab> */}
      {/* </Tabs> */}
    </>
  )
}

const MainOrdersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <MainOrdersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { MainOrdersListWrapper }
