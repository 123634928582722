import { ListViewProvider, useListView } from './core/ListViewProvider'
//  import {QueryRequestProvider} from './core/QueryRequestProvider'
import { Tab, Tabs } from 'react-bootstrap'
import { SellersListHeader } from './components/header/UsersListHeader'
import { SellersTable } from './table/sellersTable'
// import {SellerEditModal} from './seller-edit-modal/SellerEditModal'
// import {KTCard} from '../../../../_template/helpers'
import InfoView from '../../../../_template/layout/components/infoView'
import { getAllFunction } from '../../../redux/actions/commonCrud'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../hooks'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../redux/actionTypes'
import { PageTitle } from '../../../../_template/layout/core'
import { useIntl } from 'react-intl'
import getBreadCrambs from '../../../routing/breadcramps/getBreadCrambs'
import DocsCount from '../../../components/DocsCount'
interface state {
  status?: string | undefined
  role?: string | undefined
  page?: number | undefined
}
const SellersList = () => {
  const intl = useIntl()
  const [status, setStatus] = useState<state>({ status: undefined, page: 0 })
  const [count, setCount] = useState(0);

  const { itemIdForUpdate } = useListView()
  const dispatch = useAppDispatch()
  const handleSelect = (key: string | null) => {
    if (key === 'INACTIVE') setStatus({ ...status, status: 'INACTIVE', page: 0 })
    else if (key === 'ACTIVE') setStatus({ ...status, status: 'ACTIVE', page: 0 })
    else setStatus({ ...status, status: undefined })
  }
  useEffect(() => {
    dispatch(getAllFunction(endpoints.sellerEndpoint, actionTypes.GET_ALL_SELLERS, status))
  }, [dispatch, status])
  return (
    <>
      <InfoView />
      <div
        className='d-flex justify-content-between'
        style={{ marginTop: '-4%', marginBottom: '25px' }}
      >
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({ id: 'MENU.SELLERS' })}
          </PageTitle>
        </div>
        <div>
          <SellersListHeader />
        </div>
      </div>
      <Tabs id='controlled-tab-example' defaultActiveKey='All' onSelect={(k) => handleSelect(k)}>
        <Tab eventKey='All' title={<DocsCount showNumbers={!status.status} count={count} title={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ALL' })} />}>
          <SellersTable setCount={setCount} eventKey='All' />
        </Tab>
        <Tab
          className='nav-item'
          eventKey='ACTIVE'
          title={<DocsCount showNumbers={status.status === 'ACTIVE'} count={count} title={intl.formatMessage({ id: 'ECOMMERCE.COMMON.AVAILABLE' })} />}
        >
          <SellersTable setCount={setCount} eventKey='ACTIVE' />
        </Tab>
        <Tab
          className='nav-item'
          eventKey='INACTIVE'
          title={<DocsCount showNumbers={status.status === 'INACTIVE'} count={count} title={intl.formatMessage({ id: 'ECOMMERCE.COMMON.DISABLED' })} />}
        >
          <SellersTable setCount={setCount} eventKey='INACTIVE' />
        </Tab>
      </Tabs>
      {/* {itemIdForUpdate !== undefined && <SellerEditModal />} */}
    </>
  )
}

const SellersListWrapper = () => (
  <ListViewProvider>
    <SellersList />
  </ListViewProvider>
)

export { SellersListWrapper }
