/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {FC} from 'react'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_template/helpers'
import {useLang} from '../../../../../_template/i18n/Metronici18n'
import {pickupStatus} from '../core/_models'
import moment from 'moment'

type Props = {
  data: trackingStatus[]
}

const TrackingDetails: FC<Props> = ({data}) => {
  const intl = useIntl()
  const locale: string = useLang()

  return (
    <>
      {' '}
      <div>
        {/* begin::Body */}
        <div className='card-body pt-5'>
          {/* begin::Timeline */}
          <div
            className={clsx(
              {'timeline-label-ltr': locale !== 'ar'},
              {'timeline-label-rtl': locale === 'ar'}
            )}
          >
            {/* begin::Item */}
            {data.length > 0 &&
              data.map((item, i) => (
                <div key={'timeline-vertical' + i} className='timeline-item'>
                  {item.status === pickupStatus.completed ? (
                    <>
                      {/* begin::Label */}
                      <div className='timeline-label fw-bold text-gray-800 fs-6 mr-10'>
                        {moment(item.createDate, 'YYY-MM-DD').format('MMM,DD')}
                      </div>
                      {/* end::Label */}
                      {/* begin::Badge */}

                      <div className='timeline-badge'>
                        <KTSVG
                          path='/media/svg/tarcking/EllipseDone.svg'
                          className='svg-icon-4 svg-icon-gray-500'
                        />
                      </div>
                      {/* end::Badge */}
                      {/* begin::Text */}
                      <div>
                        <div className='fw-bold  timeline-content ps-10'>
                          {' '}
                          {locale === 'ar' ? item.nameAr : item.name}
                        </div>
                        <div className='fw-mormal  timeline-content text-muted ps-10'>
                          {locale === 'ar' ? item.descriptionAr : item.description}
                        </div>
                      </div>
                      {/* end::Text */}
                    </>
                  ) : item.status === pickupStatus.progress ? (
                    <>
                      {/* begin::Label */}
                      <div className='timeline-label fw-bold text-gray-800 fs-6 mr-10'>
                        {moment(item.createDate, 'YYY-MM-DD').format('MMM,DD')}
                      </div>
                      {/* end::Label */}
                      {/* begin::Badge */}

                      <div className='timeline-badge'>
                        <KTSVG
                          path='/media/svg/tarcking/EllipseOrange.svg'
                          className='svg-icon-4 svg-icon-gray-500'
                        />
                      </div>
                      {/* end::Badge */}
                      {/* begin::Text */}
                      <div>
                        <div className='fw-bold  timeline-content ps-10' style={{color: '#E8A623'}}>
                          {locale === 'ar' ? item.nameAr : item.name}
                        </div>
                        <div
                          className='fw-mormal  timeline-content ps-10'
                          style={{color: '#E8A623'}}
                        >
                          {locale === 'ar' ? item.descriptionAr : item.description}
                        </div>
                      </div>
                      {/* end::Text */}
                    </>
                  ) : item.status === pickupStatus.pending ? (
                    <>
                      {/* begin::Label */}
                      <div className='timeline-label fw-bold text-gray-800 fs-6 mr-10'>
                        {moment(item.createDate, 'YYY-MM-DD').format('MMM,DD')}
                      </div>
                      {/* end::Label */}
                      {/* begin::Badge */}

                      <div className='timeline-badge'>
                        <KTSVG
                          path='/media/svg/tarcking/EllipseGray.svg'
                          className='svg-icon-4 svg-icon-gray-500'
                        />
                      </div>
                      {/* end::Badge */}
                      {/* begin::Text */}
                      <div>
                        <div className='fw-bold  timeline-content ps-10'>
                          {' '}
                          {locale === 'ar' ? item.nameAr : item.name}
                        </div>
                        <div className='fw-mormal  timeline-content text-muted ps-10'>
                          {locale === 'ar' ? item.descriptionAr : item.description}
                        </div>
                      </div>
                      {/* end::Text */}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            {/* end::Item */}
          </div>
          {/* end::Timeline */}
        </div>
        {/* end: Card Body */}
      </div>
    </>
  )
}
export default TrackingDetails
