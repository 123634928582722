import React, {FC} from 'react'
import NotificationList from './notifications-list/notificationList'

const NotificationPageWrapper: FC = () => {
  return (
    <>
      <NotificationList />
    </>
  )
}

export default NotificationPageWrapper
