import {IntlShape} from '@formatjs/intl'
import * as Yup from 'yup'
import dataAxios from '../../../../../app/modules/auth/core/dataAxios'
const API_URL = process.env.REACT_APP_API_URL

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const validationSchemaStep1User = (intl: IntlShape<any>) =>
  Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'AUTH.GENERAL.WRONG_EMAIL_FORMAT'}))
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(50, intl.formatMessage({id: 'AUTH.GENERAL.MAX50'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.EMAIL_IS_REQUIRED'}))
      .test(
        'email-backend-validation', // Name
        intl.formatMessage({id: 'AUTH.GENERAL.EMAIL_ALREDY_TAKEN'}), // Msg

        async (email) =>
          await dataAxios
            .post(API_URL + '/api/users/validation', {email: email})
            .then((data1: any) => {
              return data1.data.code !== 4400
            })
      ),
    firstName: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(30, intl.formatMessage({id: 'AUTH.GENERAL.MAX30'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.FIRSTNAME_IS_REQUIRED'})),
    lastName: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(30, intl.formatMessage({id: 'AUTH.GENERAL.MAX30'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.LASTNAME_IS_REQUIRED'})),
    phoneNumber: Yup.string()
      .min(8, intl.formatMessage({id: 'AUTH.GENERAL.MIN8'}))
      .max(10, intl.formatMessage({id: 'AUTH.GENERAL.MAX10'}))
      .matches(phoneRegExp, intl.formatMessage({id: 'AUTH.GENERAL.PHONE_IS_NOT_VALID'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.PHONE_IS_REQUIRED'}))
      .test(
        'phoneNumber-backend-validation', // Name
        intl.formatMessage({id: 'AUTH.GENERAL.PHONE_ALREDY_TAKEN'}), // Msg

        async (phone) =>
          await dataAxios
            .post(API_URL + '/api/users/validation', {phoneNumber: phone})
            .then((data1: any) => {
              return data1.data.code !== 4400
            })
      ),
  })
export const validationSchemaStep1UpdateUser = (intl: IntlShape<any>) =>
  Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'AUTH.GENERAL.WRONG_EMAIL_FORMAT'}))
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(50, intl.formatMessage({id: 'AUTH.GENERAL.MAX50'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.EMAIL_IS_REQUIRED'})),
    firstName: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(30, intl.formatMessage({id: 'AUTH.GENERAL.MAX30'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.FIRSTNAME_IS_REQUIRED'})),
    lastName: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(30, intl.formatMessage({id: 'AUTH.GENERAL.MAX30'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.LASTNAME_IS_REQUIRED'})),
    phoneNumber: Yup.string()
      .min(8, intl.formatMessage({id: 'AUTH.GENERAL.MIN8'}))
      .max(10, intl.formatMessage({id: 'AUTH.GENERAL.MAX10'}))
      .matches(phoneRegExp, intl.formatMessage({id: 'AUTH.GENERAL.PHONE_IS_NOT_VALID'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.PHONE_IS_REQUIRED'})),
  })
export const validationSchemaStep1Driver = (intl: IntlShape<any>) =>
  Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'AUTH.GENERAL.WRONG_EMAIL_FORMAT'}))
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(50, intl.formatMessage({id: 'AUTH.GENERAL.MAX50'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.EMAIL_IS_REQUIRED'}))
      .test(
        'email-backend-validation', // Name
        intl.formatMessage({id: 'AUTH.GENERAL.EMAIL_ALREDY_TAKEN'}), // Msg

        async (email) =>
          await dataAxios
            .post(API_URL + '/api/users/validation', {email: email})
            .then((data1: any) => {
              return data1.data.code !== 4400
            })
      ),
    firstName: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(30, intl.formatMessage({id: 'AUTH.GENERAL.MAX30'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.FIRSTNAME_IS_REQUIRED'})),
    lastName: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(30, intl.formatMessage({id: 'AUTH.GENERAL.MAX30'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.LASTNAME_IS_REQUIRED'})),
    deliveryType: Yup.string().required(
      intl.formatMessage({id: 'AUTH.GENERAL.DELIVERY_TYPE_IS_REQUIRED'})
    ),
    phoneNumber: Yup.string()
      .min(8, intl.formatMessage({id: 'AUTH.GENERAL.MIN8'}))
      .max(10, intl.formatMessage({id: 'AUTH.GENERAL.MAX10'}))
      .matches(phoneRegExp, intl.formatMessage({id: 'AUTH.GENERAL.PHONE_IS_NOT_VALID'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.PHONE_IS_REQUIRED'}))
      .test(
        'phoneNumber-backend-validation', // Name
        intl.formatMessage({id: 'AUTH.GENERAL.PHONE_ALREDY_TAKEN'}), // Msg

        async (phone) =>
          await dataAxios
            .post(API_URL + '/api/users/validation', {phoneNumber: phone})
            .then((data1: any) => {
              return data1.data.code !== 4400
            })
      ),
  })

export const validationSchemaStep1UpdateDriver = (intl: IntlShape<any>) =>
  Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'AUTH.GENERAL.WRONG_EMAIL_FORMAT'}))
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(50, intl.formatMessage({id: 'AUTH.GENERAL.MAX50'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.EMAIL_IS_REQUIRED'})),
    firstName: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(30, intl.formatMessage({id: 'AUTH.GENERAL.MAX30'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.FIRSTNAME_IS_REQUIRED'})),
    lastName: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(30, intl.formatMessage({id: 'AUTH.GENERAL.MAX30'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.LASTNAME_IS_REQUIRED'})),
    deliveryType: Yup.string().required(
      intl.formatMessage({id: 'AUTH.GENERAL.DELIVERY_TYPE_IS_REQUIRED'})
    ),
    phoneNumber: Yup.string()
      .min(8, intl.formatMessage({id: 'AUTH.GENERAL.MIN8'}))
      .max(10, intl.formatMessage({id: 'AUTH.GENERAL.MAX10'}))
      .matches(phoneRegExp, intl.formatMessage({id: 'AUTH.GENERAL.PHONE_IS_NOT_VALID'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.PHONE_IS_REQUIRED'})),
  })

export const validationSchemaStep2 = (intl: IntlShape<any>) =>
  Yup.object().shape({
    address: Yup.object({
      addressLine1: Yup.string()
        .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))

        .required(intl.formatMessage({id: 'AUTH.GENERAL.ADDRESSLINE1_IS_REQUIRED'})),
      addressLine2: Yup.string()
        .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
        .max(50, intl.formatMessage({id: 'AUTH.GENERAL.MAX50'})),
      // .required('Adress line 2 is required'),
      city: Yup.string().required(intl.formatMessage({id: 'AUTH.GENERAL.CITY_IS_REQUIRED'})),
      zipCode: Yup.string()
        .min(4, intl.formatMessage({id: 'AUTH.GENERAL.MIN4'}))
        .max(4, intl.formatMessage({id: 'AUTH.GENERAL.MAX4'}))
        //   .matches(phoneRegExp, 'Phone number is not valid')
        .required(intl.formatMessage({id: 'AUTH.GENERAL.ZIPCODE_IS_REQUIRED'})),
      country: Yup.string()
        .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
        .max(10, intl.formatMessage({id: 'AUTH.GENERAL.MAX10'}))
        .required(intl.formatMessage({id: 'AUTH.GENERAL.COUNTRY_IS_REQUIRED'})),
      state: Yup.string()
        .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
        .max(50, intl.formatMessage({id: 'AUTH.GENERAL.MAX50'}))
        .required(intl.formatMessage({id: 'AUTH.GENERAL.STATE_IS_REQUIRED'})),
    }),
  })

export const validationSchemaStep3User = (intl: IntlShape<any>) =>
  Yup.object().shape({
    role: Yup.string().required(intl.formatMessage({id: 'AUTH.GENERAL.ROLE_IS_REQUIRED'})),
    status: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(20, intl.formatMessage({id: 'AUTH.GENERAL.MAX20'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.STATES_IS_REQUIRED'})),
  })
export const validationSchemaStep3Driver = (intl: IntlShape<any>) =>
  Yup.object().shape({
    status: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.GENERAL.MIN3'}))
      .max(20, intl.formatMessage({id: 'AUTH.GENERAL.MAX20'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.STATES_IS_REQUIRED'})),
    driverArea: Yup.array()
      .of(Yup.object())
      .min(1, intl.formatMessage({id: 'ECOMMERCE.COMMON.REQUIRED_DRIVER_AREA'}))
      .required(intl.formatMessage({id: 'ECOMMERCE.COMMON.REQUIRED_DRIVER_AREA'})),
  })
