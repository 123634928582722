import { PayloadAction } from '@reduxjs/toolkit'
import * as actionTypes from '../actionTypes'

const initialState: ConfigState = {
  deliveryType: [],
  oneDeliveryType: {},
  all_status: [],
  oneNotification: {users:[],order:[]},
  allNotifications: [],
  //   data: '',

  oneArea: undefined,
  //   data: '',
  driverAreas: [],
  totalPages: 0,
}
export default function configReducer(
  state = initialState,
  action: PayloadAction<any>
): ConfigState {
  switch (action.type) {
    case actionTypes.GET_ONE_DELIVERY_TYPE_BY_ID: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneDeliveryType: action.payload,
      }
    }
    case actionTypes.GET_ALL_DELIVERY_TYPES: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        deliveryType: action.payload.data,
        totalPages: action.payload.totalPages,
      }
    }
    case actionTypes.GET_ALL_NOTIFICATIONS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        allNotifications: action.payload.data,
        totalPages: action.payload.totalPages,
      }
    }
    case actionTypes.GET_ALL_STATUS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        totalPages: action.payload.totalPages,
        all_status: action.payload.data,
      }
    }
    case actionTypes.GET_ALL_DRIVER_AREAS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        totalPages: action.payload.totalPages,
        driverAreas: action.payload.data,
      }
    }
    case actionTypes.GET_ONE_DRIVER_AREA: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneArea: action.payload,
      }
    }


    default:
      return state
  }
}
