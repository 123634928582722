export const ADD_ARTICLE = 'ADD_ARTICLE'
export const GET_ALL_FUNCTION = 'GET_ALL_FUNCTION'
export const GET_ONE_BY_ID = 'GET_ONE_BY_ID'
export const DELETE_ONE = 'DELETE_ONE'
export const REMOVE_ARTICLE = 'REMOVE_ARTICLE'
export const FETCH_START = 'FETCH_START'
export const UPDATING_CONTENT = 'UPDATING_CONTENT'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const FETCH_ERROR = 'FETCH_ERROR'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER'
export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS'
export const GET_ALL_CUSTOMERS_BY_ID = 'GET_ALL_CUSTOMERS_BY_ID'
export const GET_ONE_DELIVERY_TYPE_BY_ID = 'GET_ONE_DELIVERY_TYPE_BY_ID'
export const GET_ALL_DELIVERY_TYPES = 'GET_ALL_DELIVERY_TYPES'
export const GET_ALL_DRIVER_AREAS = 'GET_ALL_DRIVER_AREAS'
export const GET_ALL_SELLERS = 'GET_ALL_SELLERS'
export const GET_ALL_FRELAY_POINTS = 'GET_ALL_FRELAY_POINTS'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS'
export const GET_ALL_MAIN_ORDERS = 'GET_ALL_MAIN_ORDERS'
export const GET_ALL_SUGGESTIONS = 'GET_ALL_SUGGESTIONS'
export const GET_ONE_SUGGESTION = 'GET_ONE_SUGGESTION'
export const GET_ONE_ORDER = 'GET_ONE_ORDER'
export const GET_ONE_MAIN_ORDER = 'GET_ONE_MAIN_ORDER'
export const GET_ONE_MAIN_ORDER_TRACKING = 'GET_ONE_MAIN_ORDER_TRACKING'
export const GET_ONE_DRIVER_AREA = 'GET_ONE_DRIVER_AREA'

export const GET_ALL_TRACKING_STATUS = 'GET_ALL_TRACKING_STATUS'
export const GET_ALL_USERS_BY_ID = 'GET_ALL_USERS_BY_ID'
export const GET_ALL_DRIVER_STATUS_BY_ADMIN = 'GET_ALL_DRIVER_STATUS_BY_ADMIN'

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'
export const GET_ALL_SELLERS_BY_ID = 'GET_ALL_SELLERS_BY_ID'
export const GET_ALL_RELAYPOINT_BY_ID = 'GET_ALL_RELAYPOINT_BY_ID'
export const GET_ALL_USER_ROLES = 'GET_ALL_USER_ROLES'
export const GET_ALL_STATUS = 'GET_ALL_STATUS'
export const GET_ALL_STATISTICS = 'GET_ALL_STATISTICS'
export const GET_ALL_ORDER_STATISTICS = 'GET_ALL_ORDER_STATISTICS'
export const GET_ALL_SALES_STATISTICS = 'GET_ALL_SALES_STATISTICS'
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS'
export const GET_POSITIONS_TO_GOOGLE_MAP = 'GET_POSITIONS_TO_GOOGLE_MAP'
export const GET_ONE_ORDER_TRACKING = 'GET_ONE_ORDER_TRACKING'
