// import {useQuery} from 'react-query'
// import {isNotEmpty, QUERIES} from '../../../../components/helpers'
import {useListView} from '../core/ListViewProvider'
// import {getUserById} from '../core/_requests'
import {useAppDispatch} from '../../../../hooks'
import {getAllFunctionById} from '../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {ModalInfo} from './ModalInfo'
// import {fetchStart, fetchSuccess} from '../../../redux/actions/common'
const ModalWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const OneSuggestion: ISuggestions = useSelector(({orders}: {orders: OrdersState}) => {
    return orders.OneSuggestion
  })
  const [loading, setLoading] = useState(false)
  const data = useMemo(() => OneSuggestion, [OneSuggestion])
  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    if (itemIdForUpdate) {
      dispatch(
        getAllFunctionById(
          endpoints.suggestionsEndpoint,
          actionTypes.GET_ONE_SUGGESTION,
          itemIdForUpdate
        )
      )
    }
  }, [dispatch, itemIdForUpdate])

  if (!itemIdForUpdate) {
    return <ModalInfo isDataLoading={false} suggestion={{_id: undefined}} />
  }
  if (data) {
    return <ModalInfo isDataLoading={false} suggestion={data} />
  }

  return null
}

export default ModalWrapper
