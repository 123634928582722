/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../hooks'
import { getAllFunctionById, getAllFunction } from '../../../../redux/actions/commonCrud'
import { PageTitle } from '../../../../../_template/layout/core'

import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
// import { useListView } from '../core/ListViewProvider'
import { useParams } from 'react-router-dom'
import Loading from '../../../../../_template/layout/components/loading/Loading'
import { toAbsoluteUrl } from '../../../../../_template/helpers'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'
import { Role } from '../core/_models'

export function UsersInfo() {
  const user: IUsers = useSelector(({ users }: { users: UsersState }) => {
    return users.oneUser
  })
  const blankImg = toAbsoluteUrl('/media/svg/avatars/avatar_user.svg')
  const roles: Role[] = useSelector(({ users }: { users: UsersState }) => {
    return users.roles
  })
  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(getAllFunctionById(endpoints.userEndpoint, actionTypes.GET_ALL_USERS_BY_ID, id))
    dispatch(getAllFunction(endpoints.userRole, actionTypes.GET_ALL_USER_ROLES, {}))
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [dispatch, id])
  const intl = useIntl()
  return (
    <>
      {loading && <Loading />}
      {/* <InfoView /> */}
      <div className='d-flex justify-content-between flex-wrap'>
        <PageTitle
          breadcrumbs={getBreadCrambs(intl, {
            isActive: false,
            path: '/users',
            title: intl.formatMessage({ id: 'MENU.USERS' }),
            isSeparator: false,
          })}
        >
          {intl.formatMessage({ id: 'ECOMMERCE.USERS.USERS_DETAILS' })}
        </PageTitle>
      </div>

      {user && (
        <div className='row bg-white rounded p-10'>
          <div className='row'>
            {/* begin::Image input */}
            <div className='w-auto'>
              {' '}
              <div
                className='image-input image-input-outline mx-auto'
                data-kt-image-input='true'
                style={{
                  backgroundImage: `url('${user.photo ? process.env.REACT_APP_API_URL + user.photo.slice(1) : blankImg
                    }')`,
                  padding: '10px',
                  width: 'fit-content',
                  height: 'fit-content',
                  borderColor: '#c6c9cc42',
                  borderStyle: 'dashed',
                  borderRadius: '70px',
                }}
              >
                {/* begin::Preview existing avatar */}
                <div
                  className='image-input-wrapper w-110px h-110px p-5'
                  style={{
                    borderRadius: '70px',
                    backgroundImage: `url('${user.photo ? process.env.REACT_APP_API_URL + user.photo.slice(1) : blankImg
                      }')`,
                  }}
                ></div>
                {/* end::Preview existing avatar */}
                {/* begin::Cancel */}
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
                {/* end::Cancel */}
              </div>
            </div>

            {/* end::Image input */}

            <div className='w-auto mt-8 mx-auto'>
            <h4> <FormattedMessage id='ECOMMERCE.COMMON.PROFILE_DETAILS' /></h4>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.NAME' })}
                value={`${user?.firstName + ' ' + user?.lastName}`}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.EMAIL' })}
                value={user?.email}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.PHONENUMBER' })}
                value={user?.phoneNumber}
              />
            </div>
            <div className='w-auto  mt-8 mx-auto'>
            <h4> <FormattedMessage id='ECOMMERCE.COMMON.ADDRESS_DETAILS' /></h4>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ADDRESS_LINE_1' })}
                value={user?.address?.addressLine1}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ADDRESS_LINE_2' })}
                value={user?.address?.addressLine2}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CITY' })}
                value={user?.address?.city}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.COUNTRY' })}
                value={user?.address?.country}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATE' })}
                value={user?.address?.state}
              />
            </div>
            <div className='w-auto mt-8 mx-auto'>
            <h4> <FormattedMessage id='ECOMMERCE.COMMON.STATUS_DETAILS' /></h4>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ROLE' })}
                value={roles.find((role) => role._id === user.role)?.name}
              />
              <div className='d-flex fs-5 my-3'>
                <div className='text-muted '>
                  {intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATUS' })}
                </div>
                :{' '}
                <div className='mx-2'>
                  {' '}
                  {user?.status === 'ACTIVE' ? (
                    <span className='badge badge-light-success fs-5 fw-bold '>
                      {' '}
                      {intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATUS.ACTIVE' })}
                    </span>
                  ) : user?.status === 'INACTIVE' ? (
                    <span className='badge badge-light-danger fs-5 fw-bold '>
                      {intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATUS.INACTIVE' })}
                    </span>
                  ) : (
                    <span className='badge badge-light-info fs-5 fw-bold '>{user?.status}</span>
                  )}{' '}
                </div>
              </div>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CREATEDAT' })}
                value={new Date(user?.createDate || new Date()).toLocaleDateString('en')}
              />
            </div>
          </div>

          {/* <div>
            <h2>{intl.formatMessage({id: 'ECOMMERCE.COMMON.ADDRESS'})}</h2>
            <div className='d-flex'>
          
            </div>
          </div> */}
        </div>
      )}
    </>
  )
}

const HorizontalAttributeComponent = ({
  attr = 'Test',
  value = 'Test',
}: {
  attr?: string | undefined
  value?: string | undefined
}) => (
  <div className='d-flex fs-5 my-auto'>
    <div className='form-subtitle'>{attr}</div>: <div className='mx-2'> {value}</div>
  </div>
)
