import {useEffect} from 'react'
// import {UserEditModalHeader} from './UserEditModalHeader'
import {DriverEditModalFormWrapper} from './DriverEditModalFormWrapper'
// import MultiStepForm from '../addUserStepper'

const DriverEditModal = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <DriverEditModalFormWrapper />
    </>
  )
}

export {DriverEditModal}
