/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {FC} from 'react'
// import {toAbsoluteUrl} from '../../../../../../components/helpers'
// import {User} from '../../core/_models'

type Props = {
  user: IUsers
}

const DriversInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center '>
    {/* begin:: Avatar */}
    <div className='symbol symbol-45px symbol-circle'>
      <img alt='Pic' src='/media/avatars/driver.svg' />
      {user?.availiable === 1100 ? (
        <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2'></div>
      ) : (
        <div className='symbol-badge bg-danger start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2'></div>
      )}
    </div>

    <div className='ms-5'>
      <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
        {user?.firstName} {user?.lastName}
      </a>
      <div className='fw-bold text-gray-400'>{user?.phoneNumber}</div>
    </div>
  </div>
)

export {DriversInfoCell}
