import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../../_template/helpers'
import {useLang} from '../../../../../../_template/i18n/Metronici18n'
import Export from '../../../../../components/export'
import { Link } from 'react-router-dom'

const OrdersListToolbar = () => {
  const locale: string = useLang()

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <Export dataType='orders' />{' '}
      {/* <Link to='/suggestions' className='btn btn-design' >
        Suggestions
      </Link> */}
    </div>
  )
}

export {OrdersListToolbar}
