/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../hooks'
import {getAllFunctionById} from '../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import {toAbsoluteUrl} from '../../../../../_template/helpers'
import {PageTitle} from '../../../../../_template/layout/core'
import {useParams} from 'react-router-dom'
import Loading from '../../../../../_template/layout/components/loading/Loading'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'

export function SellersInfo() {
  const {id} = useParams()
  const seller: ISeller = useSelector(({users}: {users: UsersState}) => {
    return users.oneSeller
  })

  const intl = useIntl()

  const blankImg = toAbsoluteUrl('/media/svg/avatars/avatar_user.svg')
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setLoading(true)
    dispatch(getAllFunctionById(endpoints.sellerEndpoint, actionTypes.GET_ALL_SELLERS_BY_ID, id))

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [dispatch, id])

  return (
    <>
      {loading && <Loading />}
      {/* <InfoView /> */}
      <div className='d-flex justify-content-between'>
        <PageTitle
          breadcrumbs={getBreadCrambs(intl, {
            isActive: false,
            path: '/sellers',
            title: intl.formatMessage({id: 'MENU.SELLERS'}),
            isSeparator: false,
          })}
        >
          {intl.formatMessage({id: 'ECOMMERCE.SELLERS.SELLERS_DETAILS'})}
        </PageTitle>
      </div>
      {seller && (
        <div className='row bg-white rounded p-10'>
          <div className='row'>
            {/* begin::Image input */}
            <div className='w-auto'>
              {' '}
              <div
                className='image-input image-input-outline mx-auto'
                data-kt-image-input='true'
                style={{
                  backgroundImage: `url('${blankImg}')`,
                  padding: '10px',
                  width: 'fit-content',
                  height: 'fit-content',
                  borderColor: '#c6c9cc42',
                  borderStyle: 'dashed',
                  borderRadius: '70px',
                }}
              >
                {/* begin::Preview existing avatar */}
                <div
                  className='image-input-wrapper w-110px h-110px p-5'
                  style={{
                    borderRadius: '70px',
                    backgroundImage: `url('${blankImg}')`,
                  }}
                ></div>
                {/* end::Preview existing avatar */}
                {/* begin::Cancel */}
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
                {/* end::Cancel */}
              </div>
            </div>

            {/* end::Image input */}

            <div className='w-auto mt-8 mx-auto'>
              <h4>
                {' '}
                <FormattedMessage id='ECOMMERCE.COMMON.PROFILE_DETAILS' />
              </h4>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.NAME'})}
                value={`${seller?.shopName}`}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.EMAIL'})}
                value={seller?.email}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.PHONENUMBER'})}
                value={seller?.phoneNumber}
              />
            </div>
            <div className='w-auto  mt-8 mx-auto'>
              <h4>
                {' '}
                <FormattedMessage id='ECOMMERCE.COMMON.ADDRESS_DETAILS' />
              </h4>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.ADDRESS_LINE_1'})}
                value={seller?.address?.addressLine1}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.ADDRESS_LINE_2'})}
                value={seller?.address?.addressLine2}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.CITY'})}
                value={seller?.address?.city}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.COUNTRY'})}
                value={seller?.address?.city}
              />
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.STATE'})}
                value={seller?.address?.state}
              />
            </div>
            <div className='w-auto mt-8 mx-auto'>
              <h4>
                {' '}
                <FormattedMessage id='ECOMMERCE.COMMON.STATUS_DETAILS' />
              </h4>

              <div className='d-flex fs-5 my-3'>
                <div className='text-muted '>
                  {intl.formatMessage({id: 'ECOMMERCE.COMMON.STATUS'})}
                </div>
                :{' '}
                <div className='mx-2'>
                  {' '}
                  {seller?.status === 'ACTIVE' ? (
                    <span className='badge badge-light-success fs-5 fw-bold '>
                      {' '}
                      {intl.formatMessage({id: 'ECOMMERCE.COMMON.STATUS.ACTIVE'})}
                    </span>
                  ) : seller?.status === 'INACTIVE' ? (
                    <span className='badge badge-light-danger fs-5 fw-bold '>
                      {intl.formatMessage({id: 'ECOMMERCE.COMMON.STATUS.INACTIVE'})}
                    </span>
                  ) : (
                    <span className='badge badge-light-info fs-5 fw-bold '>{seller?.status}</span>
                  )}{' '}
                </div>
              </div>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'ECOMMERCE.COMMON.CREATEDAT'})}
                value={new Date(seller?.createDate || new Date()).toLocaleDateString('en')}
              />
            </div>
          </div>

          {/* <div>
            <h2>{intl.formatMessage({id: 'ECOMMERCE.COMMON.ADDRESS'})}</h2>
            <div className='d-flex'>
          
            </div>
          </div> */}
        </div>
      )}
    </>
  )
}

const HorizontalAttributeComponent = ({
  attr = 'Test',
  value = 'Test',
}: {
  attr?: string
  value?: string
}) => (
  <div className='d-flex fs-5 my-3'>
    <div className='text-muted '>{attr}</div>: <div className='mx-2'> {value}</div>
  </div>
)
