import { useListView } from '../core/ListViewProvider'
import MultiStepForm from '../../../../../_template/layout/components/stepper/addUpdateStepper'
import { getAllFunctionById } from '../../../../redux/actions/commonCrud'
import { useAppDispatch } from '../../../../hooks'
import { useEffect, useState } from 'react'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import { useSelector } from 'react-redux'

const UserEditModalFormWrapper = () => {
  const { itemIdForUpdate } = useListView()
  const user: IUsers = useSelector(({ users }: { users: UsersState }) => {
    return users.oneUser
  })
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (itemIdForUpdate) {
      setLoading(true)
      dispatch(
        getAllFunctionById(endpoints.userEndpoint, actionTypes.GET_ALL_USERS_BY_ID, itemIdForUpdate)
      )
      setLoading(false)
    }
  }, [dispatch, itemIdForUpdate])
  if (!itemIdForUpdate) {
    return (
      <MultiStepForm
        isUserLoading={loading}
        type={'user'}
        user={{ _id: undefined }}
        useListView={useListView}
      />
    )
  }

  if (!loading && user.firstName) {
    return (
      <MultiStepForm
        isUserLoading={loading}
        type={'user'}
        user={user}
        useListView={useListView}
      />
    )
  }

  return null
}

export { UserEditModalFormWrapper }
