/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { CircleF, GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api'
import { FormattedMessage } from 'react-intl'
const googlePlaceKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PLACE || ''

export function DriverAreaRadiusMap({
    driverArea
}: { driverArea: any }) {
    const containerStyle = {
        width: '100%',
        height: '400px',
    }

    const [map, setMap] = useState(null)

    // const onMapReady = (mapProps, map:any) => {
    //   setMap(map);
    // };

    return (
        <div>
            <div className='google-map'>
                <LoadScript googleMapsApiKey={googlePlaceKey}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{
                            lat: parseFloat(driverArea.latitude),
                            lng: parseFloat(driverArea.longitude)
                        }}
                        zoom={11}
                    >
                        <CircleF
                            center={{
                                lat: parseFloat(driverArea.latitude),
                                lng: parseFloat(driverArea.longitude)
                            }}
                            radius={driverArea.radius}
                            options={{ fillColor: '#ff0000', strokeColor: "#ff000099" }}
                        />
                    </GoogleMap>
                </LoadScript>
            </div>
        </div>
    )
}
