import {FC, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useAppDispatch} from '../../../../app/hooks'
import {changeUserStatus} from '../../../../app/modules/auth/core/_requests'
import {getAllFunction} from '../../../../app/redux/actions/commonCrud'
import {GET_ALL_USERS} from '../../../../app/redux/actionTypes'
import {userEndpoint} from '../../../helpers'
import {useIntl} from 'react-intl'

type Props = {
  two_steps?: string
  id: string
  type: 'user' | 'driver'
}
const REACT_APP_ROLE_DRIVER = process.env.REACT_APP_ROLE_DRIVER ||""
const UserTwoStepsCell: FC<Props> = ({two_steps, id, type}) => {
  const [show, setShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  // const [changedStatus, setChangedStatus] = useState(two_steps)
  const intl = useIntl()

  const dispatch = useAppDispatch()
  const handleDispatch = () => {
    dispatch(
      getAllFunction(userEndpoint, GET_ALL_USERS, {
        role: type === 'driver' ? REACT_APP_ROLE_DRIVER : undefined,
      })
    )
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const updateUserStatus = (id: string, status: 'INACTIVE' | 'ACTIVE') => {
    setSubmitting(true)
    changeUserStatus(id, status)
      .then(() => {
        // HERE SHOULD REFETCH
        handleDispatch()
        setSubmitting(false)
        // setChangedStatus(status)
        handleClose()
      })
      .catch((err) => {
      
        setSubmitting(false)
      })
  }

  return (
    <div>
      {/* <div onClick={}> */}
      {two_steps && two_steps === 'ACTIVE' ? (
        <button onClick={handleShow} className='btn btn-sm custom-btn-success '>
          {intl.formatMessage({id: 'ECOMMERCE.COMMON.AVAILABLE'})}
        </button>
      ) : (
        <button onClick={handleShow} className='btn btn-sm custom-btn-danger'>
          {intl.formatMessage({id: 'ECOMMERCE.COMMON.DISABLED'})}
        </button>
      )}
      {/* </div> */}

      <Modal
        style={{
          zIndex: 10000,
        }}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({id: 'ECOMMERCE.COMMON.CHANGE_USER_STATUS'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {intl.formatMessage({id: 'ECOMMERCE.COMMON.CHANGE_USER_STATUS_TO'})}{' '}
          <span className={`${two_steps === 'ACTIVE' ? 'text-danger' : 'text-success'} fw-bolder`}>
            {two_steps === 'ACTIVE'
              ? intl.formatMessage({id: 'ECOMMERCE.COMMON.DISABLED'})
              : intl.formatMessage({id: 'ECOMMERCE.COMMON.AVAILABLE'})}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {intl.formatMessage({id: 'ECOMMERCE.COMMON.CLOSE'})}
          </Button>
          <Button
            disabled={submitting}
            variant={two_steps === 'ACTIVE' ? 'danger' : 'success'}
            onClick={() => {
              updateUserStatus(id, two_steps === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')
            }}
          >
            {intl.formatMessage({id: 'ECOMMERCE.COMMON.UPDATE_STATUS'})}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export {UserTwoStepsCell}
