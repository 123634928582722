/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../../../_template/helpers'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

type Props = {
  className: string
  data?: any
  columns?: any
}

const SubOrdersTable: React.FC<Props> = ({ className, data, columns }) => {
  const handleEmailClick = (email: string) => {
    const mailtoUrl = `mailto:${email}`
    window.open(mailtoUrl)
  }

  return (
    <div className={`card ${className} m-5`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        <span className='card-title fw-bold fs-2'>
          <FormattedMessage id='ECOMMERCE.SUB.ORDER' />
        </span>

        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                {columns.map((item: any) => (
                  <th className='min-w-80px'>{item?.title}</th>
                ))}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {
                data &&
                data.length > 0 &&
                data[0]?.map((item: any, index: number) => {
                  console.log(item);
                  return (
                    <tr key={'subOrder' + index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          {/* <div className='symbol symbol-45px'>
                            <img
                              style={{borderRadius: '50px'}}
                              src={toAbsoluteUrl('/media/avatars/avatarUser.jpg')}
                              alt='avatar'
                            />
                          </div> */}
                          <div className='d-flex justify-content-start flex-column'>
                            {/* <a href='#' className='text-dark text-hover-primary fs-6 me-4'> */}
                            <Link
                              to={`/sellers/${item?.sellers?._id}`}
                              style={{ textDecoration: 'none', color: 'black' }}
                            >
                              {' '}
                              {item?.sellers?.shopName}
                            </Link>
                            {/* </a> */}
                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            HTML, JS, ReactJS
                          </span> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <a
                          href='#'
                          onClick={() => handleEmailClick(item.email)}
                          className='text-hover-primary fs-6'
                        >
                          {item?.sellers?.email}
                        </a>
                      </td>
                      <td>{item?.sellers?.phoneNumber}</td>
                      <td>
                        <div className='d-flex flex-column w-100 me-2'>
                          <span className='text-dark me-2 fs-7 fw-semibold'>
                            {item?.sellers?.address?.street + ', ' + item?.sellers?.address?.city}
                          </span>
                        </div>
                      </td>
                      <td>
                        <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${item?.sellers?.address?.positionY},${item?.sellers?.address?.positionX}`}>
                          <Button variant="success" size='sm'>
                            {/* {intl.formatMessage({ id: 'ECOMMERCE.COMMON.LOCATION' })} */}
                            <i className={`bi bi-geo-alt-fill`}></i>
                          </Button>
                        </a>
                      </td>
                    </tr>
                  )
                }
                )
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { SubOrdersTable }
