/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {PageTitle} from '../../../_template/layout/core'
import {
  ChartsWidget5,
  ChartsWidget8,
  TablesWidget6,
  TablesWidget7,
  SalesWidget,
  StatisticsWidget2,
} from '../../../_template/partials/widgets'
import {ChartsWidget9} from '../../../_template/partials/widgets/charts/chartWidget9'
import {useAppDispatch} from '../../hooks'
import * as endpoints from '../../../_template/helpers/endpoints'
import * as actionTypes from '../../redux/actionTypes'
import {getAllStatistics} from '../../redux/actions/statistics'
import {useSelector} from 'react-redux'
import {DashboardFilters} from './filters'

const DashboardPage: FC = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const data: any[] = useSelector(({stats}: {stats: StatisticsState}) => {
    return stats.data
  })

  useEffect(() => {
    dispatch(getAllStatistics(endpoints.allStatistics, actionTypes.GET_ALL_STATISTICS, {}))
  }, [dispatch])
  const sales: any = useSelector(({stats}: {stats: StatisticsState}) => {
    return stats.sales
  })
  const status: any = useSelector(({stats}: {stats: StatisticsState}) => {
    return stats.status
  })
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {data.map((item, i) => (
          <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-4 my-2' key={`statInfo` + i}>
            <StatisticsWidget2
              className='card-xl-stretch mb-xl-8'
              avatar={item.avatar}
              title={intl.formatMessage({id: item.title})}
              value={item.value}
              percentage={item.percentage}
            />
          </div>
        ))}
      </div>
      {/* end::Row */}
      {/* begin::Row */}
      {/* <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <ChartsWidget5 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-6'>
          <ChartsWidget8 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-6'>
          <SalesWidget
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='300px'
            sales={sales}
            title={<FormattedMessage id='ECOMMERCE.DASHBOARD.SALES' />}
          />
        </div>
        {/* begin::Col */}
        <div className='col-xl-6'>
          <SalesWidget
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='300px'
            sales={status}
            title={<FormattedMessage id='ECOMMERCE.DASHBOARD.DELIVERED' />}
          />
          {/* <ChartsWidget9 className='card-xl-stretch mb-xl-8' /> */}
        </div>
      </div>
      {/* end::Row */}

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xl-12'>
          <TablesWidget7 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        {/* begin::Col */}
        {/* <div className='col-xl-4'>
          <TablesWidget6 className='card-xl-stretch mb-5 mb-xl-8' />
        </div> */}
        {/* end::Col */}
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className='d-flex justify-content-end' style={{marginTop: '-4%', marginBottom: '25px'}}>
        <div>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.OVERVIEW'})}</PageTitle>
        </div>
        <div className='mt-1 mb-15 '>
          <DashboardFilters />
        </div>
      </div>

      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
