import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { useQueryResponseLoading } from '../core/QueryResponseProvider'
import { suggestionsColumns } from './columns/_columns'
import * as actionTypes from '../../../../redux/actionTypes'
import { SuggestionsListLoading } from '../components/loading/SuggestionsListLoading'
import { KTCard, KTCardBody } from '../../../../../_template/helpers'
import { useSelector } from 'react-redux'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { getAllFunction } from '../../../../redux/actions/commonCrud'
import { useAppDispatch } from '../../../../hooks'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import { OrdersListSearchComponent } from '../components/header/SuggestionsListSearchComponent'
import PaginationComponent from '../../../../../_template/layout/components/pagination'

type Props = {
  // eventKey?: string | undefined
  setCount: any;
}
type values = {
  role?: string | undefined
  phone?: string | undefined
  status?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string | undefined
}
const SuggestionsTable: React.FC<Props> = ({ setCount }) => {
  const suggestions: ISuggestions[] = useSelector(({ orders }: { orders: OrdersState }) => {
    return orders.suggestions
  })
  const [values, setValues] = useState<values | undefined>({})
  const [filtersValues, setFiltersValues] = useState<any>([])

  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }
  const isLoading = useQueryResponseLoading()
  const data: ISuggestions[] = useMemo(() => suggestions, [suggestions])
  const columns = useMemo(() => suggestionsColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const dispatch = useAppDispatch()
  const handleDispatch = () => { dispatch(getAllFunction(endpoints.suggestionsEndpoint, actionTypes.GET_ALL_SUGGESTIONS, filtersValues)) }

  const totalPages: number = useSelector(({ orders }: { orders: OrdersState }) => {
    return orders.totalPages
  })

  const totalItems: number = useSelector(({ orders }: { orders: OrdersState }) => {
    return orders.totalItems
  })

  useEffect(() => {
    if (totalItems) {
      setCount(totalItems);
    } else {
      setCount(0);
    }
  }, [totalItems])
  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <div className='d-flex justify-content-between'>
          <div className='w-md-100'>
            <OrdersListSearchComponent
              values={values}
              handleChangeValues={handleChangeValues}
              handleDispatch={handleDispatch}
              //eventKey={eventKey}
              setFiltersValues={setFiltersValues}
              filtersValues={filtersValues}
            />
          </div>
        </div>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<ISuggestions>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-500 fs-6' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<ISuggestions>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
          values={values}
          setValues={setValues}
          endpoint={endpoints.suggestionsEndpoint}
          actionType={actionTypes.GET_ALL_SUGGESTIONS}
          totalPages={totalPages}
        />
        {isLoading && <SuggestionsListLoading />}
      </KTCardBody>
    </KTCard>
  )
}

export { SuggestionsTable }
