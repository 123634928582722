import usePlacesAutocomplete, {getGeocode, getLatLng, getZipCode} from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'
import {Typeahead} from 'react-bootstrap-typeahead'
import {useField, useFormikContext} from 'formik'
import {useCallback} from 'react'
import {useIntl} from 'react-intl'

const PlacesAutocomplete = () => {
  const {setFieldValue, getFieldProps, values} = useFormikContext()
  const intl = useIntl()

  const {
    ready,
    suggestions: {data},
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: 'LY',
      },
    },
    debounce: 300,
  })
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })

  const onPlaceChanged = useCallback(
    async (e: any) => {
      getGeocode({address: e[0]?.description}).then((results) => {
        const {lat, lng} = getLatLng(results[0])
        const zipCode = getZipCode(results[0], false)
        setFieldValue('address.addressLine1', e[0]?.description)
        setFieldValue('address.positionX', lng)
        setFieldValue('address.positionY', lat)
        if (zipCode) setFieldValue('address.zipCode', zipCode)
        if (results[0]?.address_components) {
          setFieldValue(
            'address.addressLine2',
            results[0].address_components.find((c) => c.types.includes('sublocality_level_1'))
              ?.long_name
          )
          setFieldValue(
            'address.city',
            results[0].address_components.find((c) => c.types.includes('locality'))?.long_name
          )
          setFieldValue(
            'address.country',
            results[0].address_components.find((c) => c.types.includes('country'))?.long_name
          )
          setFieldValue(
            'address.state',
            results[0].address_components.find((c) => c.types.includes('locality'))?.short_name
          )
        }
      })
    },
    [setFieldValue]
  )

  const handleInput = (e: any) => {
    setValue(e)
  }
  const [field, meta, helpers] = useField({
    ...getFieldProps('address.addressLine1'),
    name: 'address.addressLine1',
    type: 'text',
    id: 'addressLine1',
  })
  const handleBlur = () => {
    helpers.setTouched(true)
  }

  return (
    <div ref={ref}>
      <Typeahead
        onBlur={handleBlur}
        isInvalid={meta.touched && !!meta.error}
        isValid={meta.touched && !meta.error}
        labelKey='description'
        id='my-typeahead'
        onInputChange={handleInput}
        onChange={onPlaceChanged}
        disabled={!ready}
        inputProps={{required: true}}
        defaultSelected={
          field.value ? [{description: field.value, id: 'ChIJzVQNpTgggBQRa6bfL6Gx7WQ'}] : undefined
        }
        placeholder={intl.formatMessage({id: 'ECOMMERCE_FULL_ADDRESS_PLACEHOLDER'})}
        options={data.map((item) => ({id: item.place_id, description: item.description}))}
      >
        {meta.touched && meta.error && <div className='text-danger fs-7 mt-2'>{meta.error}</div>}
      </Typeahead>
    </div>
  )
}
export default PlacesAutocomplete
