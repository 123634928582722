import {Action, Dispatch} from '@reduxjs/toolkit'
import * as actionTypes from '../actionTypes'

export function fetchStart() {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: actionTypes.FETCH_START,
      payload: '',
    })
  }
}

export function fetchSuccess(message: string) {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: actionTypes.FETCH_SUCCESS,
      payload: message,
    })
  }
}
export function fetcherror(message: string) {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: actionTypes.FETCH_ERROR,
      payload: message,
    })
  }
}
export function showMessage(message: string) {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: actionTypes.SHOW_MESSAGE,
      payload: message,
    })
  }
}
