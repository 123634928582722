import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {OrdersListHeader} from './components/header/OrderListHeader'

import {OrdersTable} from './table/OrdersTable'
import {useAppDispatch} from '../../../hooks'
import {FC, useEffect, useState} from 'react'
import {getAllFunction} from '../../../redux/actions/commonCrud'
import InfoView from '../../../../_template/layout/components/infoView'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../redux/actionTypes'
import {Tab, Tabs} from 'react-bootstrap'
import {PageTitle} from '../../../../_template/layout/core'
import {useIntl} from 'react-intl'
import getBreadCrambs from '../../../routing/breadcramps/getBreadCrambs'

import DocsCount from '../../../components/DocsCount'
import {useParams} from 'react-router'
import AssignDriverModal from './AssignDriver/AssignDriverModal'
import {useSelector} from 'react-redux'
import Loading from '../../../../_template/layout/components/loading/Loading'

interface state {
  status?: string | undefined
  role?: string | undefined
  page?: number | undefined
}
const OrdersList: FC = () => {
  const {itemIdForUpdate} = useListView()
  const [status, setStatus] = useState<state>({status: undefined, page: 0})
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const [count, setCount] = useState(0)
  let {id} = useParams()
  useEffect(() => {
    //dispatch(getAllFunction(endpoints.orderEndpoint, actionTypes.GET_ALL_ORDERS, status))
  }, [dispatch, status])
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      dispatch(getAllFunction(endpoints.orderEndpoint, actionTypes.GET_ALL_ORDERS, {mainOrder: id}))

      setTimeout(() => {
        setLoading(false)
      }, 1000)
    })()
  }, [])
  const orders: IOrder[] = useSelector(({orders}: {orders: OrdersState}) => {
    return orders.orders
  })
  return (
    <>
      {loading && <Loading />}
      <div
        className='d-flex justify-content-between'
        style={{marginTop: '-4%', marginBottom: '25px'}}
      >
        <div>
          <PageTitle
            breadcrumbs={getBreadCrambs(intl, {
              isActive: false,
              path: '/main-orders',
              title: intl.formatMessage({id: 'MENU.ORDERS'}),
              isSeparator: false,
            })}
          >
            
            {intl.formatMessage({id: 'MENU.SUB.ORDERS'})}
          </PageTitle>
          <InfoView />
        </div>

        <div>
          <OrdersListHeader />
        </div>
      </div>

      <Tabs id='controlled-tab-example' defaultActiveKey='All'>
        <Tab
          className='nav-item'
          eventKey='All'
          title={
            <DocsCount
              showNumbers={true}
              count={count}
              title={intl.formatMessage({id: 'ECOMMERCE.COMMON.ALL'})}
            />
          }
        >
          {orders && <OrdersTable setCount={setCount} />}
        </Tab>
      </Tabs>

      {itemIdForUpdate !== undefined && <AssignDriverModal />}

      {/* </Tab>
        <Tab
          className='nav-item'
          eventKey='PENDING'
          title={intl.formatMessage({id: 'ECOMMERCE.COMMON.PENDING'})}
        >
          <OrdersTable eventKey='PENDING' />
        </Tab>
        <Tab
          className='nav-item'
          eventKey='COMPLETED'
          title={intl.formatMessage({id: 'ECOMMERCE.COMMON.COMPLETED'})}
        >
          <OrdersTable eventKey='COMPLETED' />
        </Tab> */}
      {/* </Tabs> */}
    </>
  )
}

const OrdersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OrdersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {OrdersListWrapper}
