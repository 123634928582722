/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../helpers'
import { Button } from 'react-bootstrap'

type Props = {
  className: string
  data?: any
  columns?: any
  name?: string
}

const TablesWidget9: React.FC<Props> = ({ className, data, columns, name }) => {
  const handleEmailClick = (email: string) => {
    const mailtoUrl = `mailto:${email}`
    window.open(mailtoUrl)
  }
  const intl = useIntl()
  console.log(data);
  return (
    <div className={`card ${className} m-5`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        <span className='card-title fw-bold fs-2'>
          {name === 'seller' ? (
            <FormattedMessage id='ECOMMERCE.ORDER.SELLER' />
          ) : (
            <FormattedMessage id='ECOMMERCE.ORDER.DRIVER' />
          )}
        </span>
        {name === 'driver' && !data ? (
          <div className=' m-4'>
            <FormattedMessage id='ECOMMERCE.ORDER.DRIVER.NOT.ASSIGNED' />
          </div>
        ) : name === 'seller' || data ? (
          <>
            {' '}
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    {columns.map((data: any) => (
                      <th className='min-w-80px'>{data?.title}</th>
                    ))}
                    <th className='min-w-80px'>{intl.formatMessage({ id: 'MENU.ACTIONS' })}</th>

                  </tr>

                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data && (
                    <tr key={'seller'}>
                      <td>
                        <div className='d-flex align-datas-center'>
                          <div className='symbol symbol-45px'>
                            <img
                              style={{ borderRadius: '50px' }}
                              src={toAbsoluteUrl('/media/avatars/avatarUser.jpg')}
                              alt='avatar'
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark text-hover-primary fs-6 me-4'>
                              {name === 'seller'
                                ? data?.shopName
                                : data.firstName + ' ' + data.lastName}
                            </a>
                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    HTML, JS, ReactJS
                  </span> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <a
                          href='#'
                          onClick={() => handleEmailClick(data.email)}
                          className='text-hover-primary fs-6'
                        >
                          {data?.email}
                        </a>
                      </td>
                      <td>{data?.phoneNumber}</td>
                      <td className='text-start'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <span className='text-dark me-2 fs-7 fw-semibold'>
                            {data?.address?.street + ', ' + data?.address?.city}
                          </span>
                        </div>
                      </td>
                      <td className='text-start'>
                        <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${data.address?.positionY},${data.address?.positionX}`}>
                        <Button variant="success" size='sm'>
                          {/* {intl.formatMessage({ id: 'ECOMMERCE.COMMON.LOCATION' })} */}
                          <i className={`bi bi-geo-alt-fill`}></i>
                        </Button>
                        </a>
                      </td>
                    </tr>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </>
        ) : (
          ''
        )}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget9 }
