/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { toAbsoluteUrl } from '../../../../../_template/helpers'
import ProductDetails from './product'

type Props = {
  className: string
}

const InvoiceDetails: React.FC<Props> = ({ className }) => {
  //   const locale: string = useLang()
  const order: IOrder = useSelector(({ orders }: { orders: OrdersState }) => {
    return orders.oneOrder
  })
  const intl = useIntl()
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            <FormattedMessage id='ECOMMERCE.ORDER.INVOICE_TITLE' />
          </span>
        </h3>
      </div>
      <div>
        <div className='d-flex flex-wrap'>
          <div style={{ width: '230px' }}>
            <div
              className='d-flex flex-column m-5'
              style={{
                backgroundColor: '#2B3D44',

                color: 'white',
                padding: '100px 0px 30px 30px',
              }}
            >
              <span className='fs-1'>
                <FormattedMessage id='ECOMMERCE.ORDER.INVOICE_TITLE' />
              </span>
              <span className='fs-4'>#{order.reference}</span>
            </div>
          </div>
          <div style={{ flexGrow: 2 }}>
            <div className='d-flex align-items-start h-100'>
              <div className='align-self-end mb-5 '>
                <div className='text fs-4'>
                  <FormattedMessage id='ECOMMERCE.COMMON.PLATFORM.TITLE' />
                </div>
                <div className='text-muted fs-5'>
                  <FormattedMessage id='ECOMMERCE.COMMON.PLATFORM.ADDRESS' />
                </div>
                <div className='text-muted fs-5'>
                  <FormattedMessage id='ECOMMERCE.COMMON.PLATFORM.PHONE' />
                </div>
                <div className='text-muted fs-5'>
                  <FormattedMessage id='ECOMMERCE.COMMON.PLATFORM.EMAIL' />
                </div>
                <div className='text-muted fs-5'>
                  <FormattedMessage id='ECOMMERCE.COMMON.PLATFORM.WEBSITE' />
                </div>
              </div>
            </div>
          </div>
          <div style={{ flexGrow: 1, marginRight: '30px' }}>
            <div className='d-flex justify-content-end h-100'>
              <div className=' d-flex align-self-center align-items-center flex-column '>
                <span className='text-muted fs-4'>September12,2022</span>
                <div className='symbol symbol-50px me-2'>
                  <span className='symbol-label'>
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/logoLayer.svg')}
                      className=''
                      alt='product'
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Table container */}
        <div>
          <div></div>
          <div>
            <ProductDetails className='' />
          </div>
          <div className='d-flex  ml-10  justify-content-end'>
            <div>
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.ORDER.SUBTOTAL' })}
                value={order.totalAmount}
              />
              {/* <HorizontalAttributeComponent
                attr={intl.formatMessage({id: 'ECOMMERCE.ORDER.TAX'})}
                value={order.customer?.address?.zipCode}
              /> */}
              <HorizontalAttributeComponent
                attr={intl.formatMessage({ id: 'ECOMMERCE.ORDER.DISCOUNT' })}
                value={0}
              />
              <HorizontalAttributeBold
                attr={intl.formatMessage({ id: 'ECOMMERCE.ORDER.TOTAL' })}
                value={order.totalAmount}
              />
            </div>
          </div>
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default InvoiceDetails
const HorizontalAttributeComponent = ({
  attr = 'Test',
  value = 'Test',
}: {
  attr?: string
  value?: string | number
}) => (
  <div className='d-flex justify-content-between  fs-4 my-5 '>
    <div className='text-muted '>{attr}</div>: <div className='mx-5'> {value}</div>
  </div>
)
const HorizontalAttributeBold = ({
  attr = 'Test',
  value = 'Test',
}: {
  attr?: string
  value?: string | number
}) => (
  <div className='d-flex justify-content-between fw-bold  fs-4 my-8 mx-2 '>
    <div className='text fw-bold'>{attr}</div>: <div className='mx-5'> {value}</div>
  </div>
)
