import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLang} from '../../../i18n/Metronici18n'
import {HeaderNotificationsMenu, HeaderUserMenu} from '../../../partials'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../app/redux/actionTypes'
import {Badge} from 'react-bootstrap'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useAppDispatch} from '../../../../app/hooks'
import {getAllFunction, patchNotif} from '../../../../app/redux/actions/commonCrud'
import {socketIO} from '../../../../app/socket'
// import {useLayout} from '../../core'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-45px h-45px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'

const Navbar = () => {
  const [count, setCount] = useState<number>(0)
  const locale: string = useLang()
  const data = useMemo(() => count, [count])
  const updateCount = (value: number) => setCount(count + value)
  const dispatch = useAppDispatch()
  const [messages, setMessages] = useState<any[]>([])
  const [isConnected, setIsConnected] = useState(socketIO.connected)
  const token = localStorage.getItem('token')
  const updateMsg = useCallback(
    (message: {}) => {
      setMessages((t) => [message, ...messages])
    },
    [messages]
  )
  useEffect(() => {
    socketIO.on('connect', () => {
      setIsConnected(true)
      socketIO.emit('setUserId', token)
      socketIO.on('unreadCount', (count) => {
      //  console.log('Received unread count:', count)
        updateCount(count)
      })
      setTimeout(() => {
        socketIO.emit('getNotificationsLength', token)
      }, 10000)
    })

    socketIO.on('onNotification', (e) => {
      updateMsg({...e, isNew: true})
      updateCount(1)
    })

    return () => {
      socketIO.off('connect')
      socketIO.off('disconnect')
      socketIO.off('onNotification')
    }
  }, [token, socketIO])

  const handleSeen = () => {
    dispatch(patchNotif(endpoints.allNotifications))
    dispatch(getAllFunction(endpoints.allNotifications, actionTypes.GET_ALL_NOTIFICATIONS, {}))
    // if (token) socketIO.emit('notification-seen', token)
  }
  // useEffect(() => {
  //   dispatch(getAllFunction(endpoints.allNotifications, actionTypes.GET_ALL_NOTIFICATIONS, {}))
  // }, [dispatch])
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}
      {/* 
      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
          className={btnClass}
          onClick={() => handleSeen()}
        >
          <div style={{position: 'relative', display: 'inline-block'}}>
            <div
              //  pill
              // className='bg-warning'
              style={{
                position: 'absolute',
                top: '-8px',
                right: '-9px',
                width:'23px',
                borderRadius: '19px',
                padding: '0px',
                color:'white',
                backgroundColor: '#FD7972',
              }}
            >
              {data}
            </div>

            <KTSVG path='/media/icons/duotune/general/notification.svg' className={'svg-icon-1'} />
          </div>
        </div>
        <HeaderNotificationsMenu messages={messages} />
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
        >
          <img src={toAbsoluteUrl('/media/avatars/avatarUser.jpg')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Navbar}
