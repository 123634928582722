import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik, useField } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../../_template/helpers'
import { initDeliveryType } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import DriverAreaLoading from '../components/loading/driverAreaLoading'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { useIntl } from 'react-intl'
import { IntlShape } from '@formatjs/intl'
import { formDataAddFunction, formDatapatchFunction } from '../../../../../redux/actions/commonCrud'
import { useAppDispatch } from '../../../../../hooks'
import * as endpoints from '../../../../../../_template/helpers/endpoints'

type Props = {
  isUserLoading: boolean
  deliveryType: IDeliveryType
}

const DeliveryTypeSchema = (intl: IntlShape<any>) =>
  Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MIN3' }))
      .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAX30' }))
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.EMAIL_IS_REQUIRED' })),
    code: Yup.string()
      .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MIN3' }))
      .max(30, intl.formatMessage({ id: 'AUTH.GENERAL.MAX30' }))
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.EMAIL_IS_REQUIRED' })),
    weight: Yup.number()
      .min(1, intl.formatMessage({ id: 'AUTH.GENERAL.MIN1' }))
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.EMAIL_IS_REQUIRED' })),
    width: Yup.number()
      .min(1, intl.formatMessage({ id: 'AUTH.GENERAL.MIN1' }))
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.EMAIL_IS_REQUIRED' })),
    height: Yup.number()
      .min(1, intl.formatMessage({ id: 'AUTH.GENERAL.MIN1' }))
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.EMAIL_IS_REQUIRED' })),
    depth: Yup.number()
      .min(1, intl.formatMessage({ id: 'AUTH.GENERAL.MIN1' }))
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.EMAIL_IS_REQUIRED' })),
  })
const MyTextInput = ({ label, ...props }: any & React.InputHTMLAttributes<HTMLInputElement>) => {
  const [field, meta] = useField(props)

  return (
    <>
      {/* begin::Label */}
      <label className='required fw-bold fs-6 mb-2'>{label}</label>
      {/* end::Label */}

      {/* begin::Input */}
      <input
        {...field}
        {...props}
        className={clsx(
          'form-control form-control-solid mb-3 mb-lg-0',
          { 'is-invalid': meta.touched && meta.error },
          {
            'is-valid': meta.touched && !meta.error,
          }
        )}
      />
      {meta.touched && meta.error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{meta.error}</span>
          </div>
        </div>
      )}
      {/* end::Input */}
    </>
  )
}
const DriverAreaEditModalForm: FC<Props> = ({ deliveryType, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const [imageFile, setImageFile] = useState<File>()
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>(null)

  const { refetch } = useQueryResponse()
  const intl = useIntl()
  const dispatch = useAppDispatch()
  useEffect(() => { }, [deliveryType])

  const [userForEdit] = useState<IDeliveryType>({
    ...deliveryType,
    name: deliveryType.name || initDeliveryType.name,

    maxWeight: deliveryType.maxWeight || initDeliveryType.maxWeight,
   maxHeight: deliveryType.maxHeight || initDeliveryType.maxHeight,
    maxWidth: deliveryType.maxWidth || initDeliveryType.maxWidth,
    maxDepth: deliveryType.maxDepth || initDeliveryType.maxDepth,

    minWeight: deliveryType.minWeight || initDeliveryType.minWeight,
    minHeight: deliveryType.minHeight || initDeliveryType.minHeight,
    minWidth: deliveryType.minWidth || initDeliveryType.minWidth,
    minDepth: deliveryType.minDepth || initDeliveryType.minDepth,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImageFile(file)
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }
  const blankImg = toAbsoluteUrl('/media/svg/avatars/avatar_obj.svg')

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={userForEdit}
        validationSchema={DeliveryTypeSchema(intl)}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)

          if (
            imageFile &&
            values.name &&
            values.code &&
            values.minWidth &&
            values.minHeight &&
            values.minWeight &&
            values.minDepth &&
            values.maxWidth &&
            values.maxHeight &&
            values.maxWeight &&
            values.maxDepth 
          ) {
            let bodyFormData = new FormData()
            bodyFormData.append('image', imageFile)
            bodyFormData.append('name', values.name)
            bodyFormData.append('code', values.code)

            bodyFormData.append('width', JSON.stringify(values.minWidth))
            bodyFormData.append('height', JSON.stringify(values.minHeight))
            bodyFormData.append('depth', JSON.stringify(values.minWeight))
            bodyFormData.append('weight', JSON.stringify(values.minDepth))

            bodyFormData.append('width', JSON.stringify(values.maxWidth))
            bodyFormData.append('height', JSON.stringify(values.maxHeight))
            bodyFormData.append('depth', JSON.stringify(values.maxWeight))
            bodyFormData.append('weight', JSON.stringify(values.maxDepth))

            try {
              if (isNotEmpty(values._id)) {
                await dispatch(
                  formDatapatchFunction(endpoints.deliveryType, bodyFormData, values._id)
                )
              } else {
                await dispatch(formDataAddFunction(endpoints.deliveryType, bodyFormData))
              }
              // dispatch(fetchSuccess('Your customer has been addes successfully'))
            } catch (ex) {
              // dispatch(fetcherror('errooooooorrr'))
            } finally {
              setSubmitting(true)
              cancel(true)
            }
          }
        }}
        validateOnChange={false}
      >
        {({ isSubmitting, values, isValid, touched, getFieldProps, errors }) => (
          <Form>
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              {/* begin::Input group */}
              <div className='d-flex justify-content-center mb-7'>
                {/* begin::Label */}

                {/* end::Label */}

                {/* begin::Image input */}
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{
                    backgroundImage: `url('${blankImg}')`,
                    padding: '10px',
                    borderColor: '#c6c9cc42',
                    borderStyle: 'dashed',
                    borderRadius: '70px',
                  }}
                >
                  {/* begin::Preview existing avatar */}

                  <div
                    className='image-input-wrapper w-125px h-125px p-5'
                    style={{
                      borderRadius: '70px',
                      backgroundImage: `url('${imagePreview ? imagePreview.toString() : blankImg
                        }')`,
                    }}
                  ></div>
                  {/* end::Preview existing avatar */}

                  {/* begin::Label */}
                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    title='Change avatar'
                  >
                    <i className='bi bi-pencil-fill fs-7'></i>

                    <input
                      type='file'
                      name='avatar'
                      onChange={handleImageChange}
                      accept='.png, .jpg, .jpeg'
                    />
                    <input type='hidden' name='avatar_remove' />
                  </label>
                  {/* end::Label */}

                  {/* begin::Cancel */}
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    title='Cancel avatar'
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                  {/* end::Cancel */}
                </div>
                {/* end::Image input */}

                {/* begin::Hint */}
                {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
                {/* end::Hint */}
              </div>
              {/* end::Input group */}
              <div>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.NAME' })}
                    {...getFieldProps('name')}
                    label={intl.formatMessage({ id: 'ECOMMERCE.COMMON.NAME' })}
                    name='name'
                    type='text'
                    // value={formik.values.name}
                    autoComplete='on'
                  //disabled={isUserLoading}
                  />
                </div>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CODE' })}
                    {...getFieldProps('code')}
                    label={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CODE' })}
                    name='code'
                    type='text'
                    // value={formik.values.code}
                    autoComplete='on'
                  //disabled={isUserLoading}
                  />
                </div>
                {/* end::Input group */}
              </div>



             <div className='d-flex flex-sm-row flex-column justify-content-between'>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <div className='fv-row mb-7'>
                    <MyTextInput
                      placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.DEPTH' })}
                      {...getFieldProps('minDepth')}
                      label={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.DEPTH' })}
                      name='minDepth'
                      type='number'
                      autoComplete='on'
                    />
                  </div>
                </div>

                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <div className='fv-row mb-7'>
                    <MyTextInput
                      placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.DEPTH' })}
                      {...getFieldProps('maxDepth')}
                      label={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.DEPTH' })}
                      name='maxDepth'
                      type='number'
                      // value={formik.values.depth}
                      autoComplete='on'
                    //disabled={isDataLoading}
                    />
                  </div>
                </div>
              </div>

              <div className='d-flex flex-sm-row flex-column justify-content-between'>
                {' '}
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.WIDTH' })}
                    {...getFieldProps('minWidth')}
                    label={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.WIDTH' })}
                    name='minWidth'
                    type='number'
                    // value={formik.values.height}
                    autoComplete='on'
                  //disabled={isDataLoading}
                  />
                </div>
                {/* end::Input group */}
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.WIDTH' })}
                    {...getFieldProps('maxWidth')}
                    label={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.WIDTH' })}
                    name='maxWidth'
                    type='number'
                    // value={formik.values.width}
                    autoComplete='on'
                  //disabled={isDataLoading}
                  />
                </div>
                {/* end::Input group */}
              </div>

              <div className='d-flex flex-sm-row flex-column justify-content-between'>
                {' '}
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.HEIGHT' })}
                    {...getFieldProps('minHeight')}
                    label={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.HEIGHT' })}
                    name='minHeight'
                    type='number'
                    // value={formik.values.height}
                    autoComplete='on'
                  //disabled={isDataLoading}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.HEIGHT' })}
                    {...getFieldProps('maxHeight')}
                    label={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.HEIGHT' })}
                    name='maxHeight'
                    type='number'
                    // value={formik.values.height}
                    autoComplete='on'
                  //disabled={isDataLoading}
                  />
                </div>
                {/* end::Input group */}
                {/* begin::Input group */}
                {/* <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.MAX.WIDTH'})}
                    {...getFieldProps('width')}
                    label={intl.formatMessage({id: 'ECOMMERCE.COMMON.MAX.WIDTH'})}
                    name='width'
                    type='number'
                    // value={formik.values.width}
                    autoComplete='on'
                    //disabled={isDataLoading}
                  />
                </div> */}
                {/* end::Input group */}
              </div>

              <div className='d-flex flex-sm-row flex-column justify-content-between'>
                {' '}
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.WEIGHT' })}
                    {...getFieldProps('minWeight')}
                    label={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MIN.WEIGHT' })}
                    name='minWeight'
                    type='number'
                    // value={formik.values.range}
                    autoComplete='on'
                  //disabled={isDataLoading}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.WEIGHT' })}
                    {...getFieldProps('maxWeight')}
                    label={intl.formatMessage({ id: 'ECOMMERCE.COMMON.MAX.WEIGHT' })}
                    name='maxWeight'
                    type='number'
                    // value={formik.values.range}
                    autoComplete='on'
                  //disabled={isDataLoading}
                  />
                </div>
                {/* end::Input group */}
              </div>



            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}
            <div className='d-flex justify-content-between pt-15'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={isSubmitting || isUserLoading}
              >
                {intl.formatMessage({ id: 'ECOMMERCE.COMMON.DISCARD' })}
              </button>

              <button
                type='submit'
                className='btn btn-design'
                data-kt-users-modal-action='submit'
                disabled={isUserLoading || isSubmitting || !isValid || !touched}
              >
                <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                {(isSubmitting || isUserLoading) && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
            {(isSubmitting || isUserLoading) && <DriverAreaLoading />}
          </Form>
        )}
      </Formik>
    </>
  )
}

export { DriverAreaEditModalForm }
