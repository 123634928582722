/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_template/helpers'
import {value} from '../../../../modules/shared/actionTypes'

type Props = {
  className: string
  rows: any[]
  title: string
}

// const rows: Array<{description: string}> = [
//   {description: 'Avg. Client Rating'},
//   {description: 'Instagram Followers'},
//   {description: 'Google Ads CPC'},
// ]

const DetailsBox = ({className, rows, title}: Props) => {
  const handleEmailClick = (email: string) => {
    const mailtoUrl = `mailto:${email}`
    window.open(mailtoUrl)
  }
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <h3 className='card-title text-gray-800 fw-bold'>{title}</h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-5'>
        {rows.map((row, index) => (
          <Fragment key={`lw26-rows-${index}`}>
            <div className='d-flex justify-content-between mb-8 flex-wrap'>
              <div className='flex-grow-1'>
                <span>
                  <i className={`bi ${row.icon}`}></i>
                </span>
                <span className='mt-4 me-2'> {row.title}</span>
              </div>
              {row.code === 'STATUS' ? (
                <span
                  style={{width: 'auto', padding: '8px 15px 8px 14px', fontSize: '15px'}}
                  className={`badge ${
                    row.value.code === '10'
                      ? 'badge-light-warning'
                      : row.value.code === '20'
                      ? 'badge-light-info'
                      : row.value.code === '100'
                      ? 'badge-light-success'
                      : row.value.code === '30'
                      ? 'badge-light-primary'
                      : row.value.code === '400'
                      ? 'badge-light-danger'
                      : 'badge-light-warning'
                  }`}
                >
                  {row.value.name}
                </span>
              ) : row.code === 'EMAIL' ? (
                <a
                  href='#'
                  onClick={() => handleEmailClick(row.value)}
                  className='text-hover-primary fs-6'
                >
                  {' '}
                  <span> {row.value}</span>
                </a>
              ) : row.code === 'CUSTOMER' ? (
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-25px'>
                    <img
                      style={{borderRadius: '50px'}}
                      src={toAbsoluteUrl('/media/avatars/avatarUser.jpg')}
                      alt='avatar'
                    />
                  </div>
                  <div className='d-flex justify-content-start flex-column'>
                    <a href='#' className='text-dark text-hover-primary fs-6 me-4'>
                      {row.value}
                    </a>
                    {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
              HTML, JS, ReactJS
            </span> */}
                  </div>
                </div>
              ) : (
                <span> {row.value}</span>
              )}
              {/* 
            <button
              type='button'
              className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
            >
              <KTSVG path='media/icons/duotune/arrows/arr095.svg' className='svg-icon-2' />
            </button> */}
            </div>
            {rows.length - 1 > index && <div className='separator separator-solid my-3' />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
export default DetailsBox
