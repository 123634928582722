/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {FormattedMessage} from 'react-intl'
import ProductDetails from './product'

type Props = {
  className: string
}

const ProductTab: React.FC<Props> = ({className}) => {
  //   const locale: string = useLang()

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            <FormattedMessage id='ECOMMERCE.ORDER.PRODUCT.LIST' />
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Table container */}
        <ProductDetails className='' />
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default ProductTab
