import {PayloadAction} from '@reduxjs/toolkit'
import * as actionTypes from '../actionTypes'

const initialState: UsersState = {
  usersList: [],
  customers: [],
  sellers: [],
  relayPoints: [],
  data: '',
  oneCustomer: {
    firstName: '',
    lastName: '',
    status: '',
  },
  oneSeller: {
    shopName: '',
    status: '',
  },
  oneRelayPoint: {
    fullname: '',
    status: '',
  },
  oneUser: {
    firstName: '',
    lastName: '',
    status: '',
  },
  totalPages: 1,
  totalItems: 1,
  roles: [],
}
export default function usersReducer(state = initialState, action: PayloadAction<any>): UsersState {
  switch (action.type) {
    case actionTypes.GET_ALL_USERS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        usersList: action.payload.data
          ? action.payload.data?.filter(
              (item: IUsers) =>
                item.role?.name !== 'SuperAdmin' && item.role?.name !== 'ROLE_SUPER_ADMIN'
            )
          : [],
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      }
    }
    case actionTypes.GET_ALL_USER_ROLES: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        roles: action.payload,
      }
    }
    case actionTypes.GET_ALL_CUSTOMERS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        customers: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      }
    }
    case actionTypes.GET_ALL_CUSTOMERS_BY_ID: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneCustomer: action.payload,
      }
    }
    case actionTypes.GET_ALL_USERS_BY_ID: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneUser: {
          ...action.payload,
          driverArea: action.payload.driverArea?.map((item: any) => ({
            ...item,
            label: item.name,
            value: item._id,
          })),
        },
      }
    }
    case actionTypes.GET_ALL_SELLERS_BY_ID: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneSeller: action.payload,
      }
    }
    case actionTypes.GET_ALL_RELAYPOINT_BY_ID: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        oneRelayPoint: action.payload,
      }
    }
    case actionTypes.GET_ALL_SELLERS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        sellers: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      }
    }
    case actionTypes.GET_ALL_FRELAY_POINTS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        relayPoints: action.payload.data,
        totalPages: action.payload.totalPages,
      }
    }

    default:
      return state
  }
}
