// @ts-nocheck
import {Column} from 'react-table'
import {
  UserSelectionCell,
  UserCustomHeader,
  UserSelectionHeader,
} from '../../../../../../_template/layout/components/columns'

import {User} from '../../core/_models'
import {FormattedMessage} from 'react-intl'
import {RelayPointActionsCell} from '../../../../../../_template/layout/components/columns/RelayPointActionsCell'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.NAME' />}
        className='min-w-125px'
      />
    ),
    accessor: 'fullname',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.PHONENUMBER' />}
        className='min-w-125px'
      />
    ),
    accessor: 'phoneNumber',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.ADDRESS' />}
        className='min-w-125px'
      />
    ),
    accessor: 'adress',
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='ECOMMERCE.COMMON.STATUS' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'status',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={1} />,
  // },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.ACTIVITYFIELD' />}
        className='min-w-125px'
      />
    ),
    id: 'activityField',
    accessor: 'activityField',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),
    id: 'createDate',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].createDate} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='MENU.ACTIONS' />}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <RelayPointActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
