import { FC } from 'react'

type Props = {
  status?: any
}

const CustomOrderStatus: FC<Props> = ({ status }) => {
  return (
    <>
      {' '}
      {status && (
        <span
          style={{ width: 'auto', padding: '8px 15px 8px 14px', fontSize: '15px' }}
          className={`badge ${status.code === '10'
              ? 'badge-light-warning'
              : status.code === '20'
                ? 'badge-light-info'
                : status.code === '100'
                  ? 'badge-light-success'
                  : status.code === '30'
                    ? 'badge-light-primary'
                    : status.code === '400'
                      ? 'badge-light-danger'
                      : status.code === '40'
                      ? 'badge-light-danger'
                      : 'badge-light-warning'
            }`}
        >
          {status.name}
        </span>
      )}
    </>
  )
}

export { CustomOrderStatus }
