import axios, {AxiosError, AxiosResponse} from 'axios'
import formDataAxios from './formDataAxios'

const API_URL = process.env.REACT_APP_API_URL
const sessionId = localStorage.getItem('sessionId')
const dataAxios = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': '*',
    'X-Session-ID': sessionId ? sessionId : '',
  },
})
dataAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Unauthorized error, log user out

      localStorage.removeItem('token')
      localStorage.removeItem('expirationTime')
     // authHelper.removeAuth()
    //  window.location.reload() // Reload the app to clear state and redirect to login page
    }

    return Promise.reject(error)
  }
)
export const setAuthToken = (token: string | null) => {
  if (token) {
    dataAxios.defaults.headers.common = {Authorization: `bearer ${token}`}
    formDataAxios.defaults.headers.common = {Authorization: `bearer ${token}`}

    localStorage.setItem('token', token)
  } else {
    delete dataAxios.defaults.headers.common['Authorization']
    delete formDataAxios.defaults.headers.common['Authorization']
    localStorage.removeItem('token')
  }
}
export default dataAxios
