import React, {FC} from 'react'
import {PageTitle} from '../../../_template/layout/core'
// import {BuilderPage} from './BuilderPage'
import {useIntl} from 'react-intl'
import {SuggestionsListWrapper} from './suggestions-list/SuggestionsList'

const SuggestionsPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SUGGESTIONS'})}</PageTitle>
      <SuggestionsListWrapper />
    </>
  )
}

export default SuggestionsPageWrapper
