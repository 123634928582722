import { ID, Response } from '../../../../../../_template/helpers'
// import {User} from '../../../ListUsers/users-list/core/_models'
export enum UserStatus {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
}
export type Role = {
  _id?: ID
  name: string
  message: string
}

export type Adress = {
  _id?: ID
  country: string
  city: string
  delegation: string
  zipCode: string
  street: string
  //createDate?: Date
  //updateDate?: Date
  // createdBy?: User
  // updatedBy?: User
}

export type UsersQueryResponse = Response<Array<ICustomer>>

export const initDeliveryType: IDeliveryType = {
  name: '',
  maxWeight: 0,
 maxHeight: 0,
  maxWidth: 0,
  maxDepth: 0,

  minWeight: 0,
  minHeight: 0,
  minWidth: 0,
  minDepth: 0,
  code: '',

  // createDate: Date,
  //updateDate: Date,
}
