import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Formik, Form} from 'formik'
import BootstrapForm from 'react-bootstrap/Form'
import {object, string, number} from 'yup'
import {CircleF, GoogleMap, LoadScript} from '@react-google-maps/api'
import {patchDriverArea, postDriverArea} from '../modules/auth/core/_requests'
import {fetchSuccess} from '../redux/actions/common'
import {useAppDispatch} from '../hooks'
import {getAllFunction, getAllFunctionById} from '../redux/actions/commonCrud'
import {DriverAreas} from '../../_template/helpers'
import {GET_ALL_DRIVER_AREAS, GET_ONE_DRIVER_AREA} from '../redux/actionTypes'
import {useSelector} from 'react-redux'

const googlePlaceKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PLACE || ''

// interface initialValuesT {
//     name: string;
//     city: string;
//     description: string;
//     radius: number;
//     latitude: number;
//     longitude: number;
// }

const DriverAreaForm = ({show, handleClose, id}: {handleClose: any; show: any; id?: string}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const handleDispatch = () => {
    dispatch(getAllFunction(DriverAreas, GET_ALL_DRIVER_AREAS, {}))
  }

  useEffect(() => {
    if (id) {
      dispatch(getAllFunctionById(DriverAreas, GET_ONE_DRIVER_AREA, id))
    }
  }, [id])

  let oneArea = useSelector(({config}: {config: ConfigState}) => config.oneArea)
  oneArea = id ? oneArea : undefined

  const validationSchema = object().shape({
    name: string().required(intl.formatMessage({id: 'AUTH.GENERAL.NAME_IS_REQUIRED'})),
    city: string().required(intl.formatMessage({id: 'AUTH.GENERAL.CODE_IS_REQUIRED'})),
    description: string().required(
      intl.formatMessage({id: 'AUTH.GENERAL.DESCRIPTION_IS_REQUIRED'})
    ),
    radius: number().required(intl.formatMessage({id: 'AUTH.GENERAL.DESCRIPTION_IS_REQUIRED'})),
    latitude: number().required(intl.formatMessage({id: 'AUTH.GENERAL.DESCRIPTION_IS_REQUIRED'})),
    longitude: number().required(intl.formatMessage({id: 'AUTH.GENERAL.DESCRIPTION_IS_REQUIRED'})),
  })

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: oneArea?.name || '',
        city: oneArea?.city || '',
        description: oneArea?.description || '',
        radius: oneArea ? +oneArea.radius : 1000,
        latitude: oneArea ? +oneArea.latitude : 32.89491471162975,
        longitude: oneArea ? +oneArea.longitude : 13.181183096369686,
      }}
      onSubmit={(values: any, actions: any) => {
        if (id) {
          // Edit
          values = {
            ...values,
            geometry: {
              type: 'Point',
              coordinates: [values.longitude, values.latitude],
              radius: values.radius,
            },
          }
          patchDriverArea(values, id)
            .then(({data}) => {
              if (data.code === 2204) {
                alert(data.message)
                return
              }
            })
            .catch((err) => {
             //// console.log(err)
            })
        } else {
          // Create
          values = {
            ...values,
            geometry: {
              type: 'Point',
              coordinates: [values.longitude, values.latitude],
              radius: values.radius,
            },
          }
          postDriverArea(values)
            .then(({data}) => {
              if (data.code === 2021) {
                dispatch(fetchSuccess('Data successfully created'))
                return
              }
            })
            .catch((err) => {
            //
            })
        }
        handleDispatch()
        handleClose()
        actions.resetForm()
      }}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({values, getFieldProps, handleSubmit, errors, isSubmitting, setFieldValue}) => (
        <Modal
          style={{
            zIndex: 10000,
          }}
          show={show}
          onHide={handleClose}
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                {intl.formatMessage({
                  id: id ? 'ECOMMERCE.COMMON.EDIT_DRIVER_AREA' : 'ECOMMERCE.COMMON.ADD_DRIVER_AREA',
                })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* end::Actions */}
              <BootstrapForm>
                <BootstrapForm.Group className='mb-3' controlId='name'>
                  <BootstrapForm.Label>
                    {intl.formatMessage({id: 'ECOMMERCE.COMMON.LABEL'})}
                  </BootstrapForm.Label>
                  <BootstrapForm.Control
                    {...getFieldProps('name')}
                    value={values.name}
                    name='name'
                  />
                  {errors?.name && (
                    <BootstrapForm.Text className='text-danger fs-7'>
                      {errors?.name}
                    </BootstrapForm.Text>
                  )}
                  {/*  */}
                </BootstrapForm.Group>
                <BootstrapForm.Group className='mb-3' controlId='city'>
                  <BootstrapForm.Label>
                    {intl.formatMessage({id: 'ECOMMERCE.COMMON.CITY'})}
                  </BootstrapForm.Label>
                  <BootstrapForm.Control
                    {...getFieldProps('city')}
                    value={values.city}
                    name='city'
                  />
                  {errors?.city && (
                    <BootstrapForm.Text className='text-danger fs-7'>
                      {errors?.city}
                    </BootstrapForm.Text>
                  )}
                </BootstrapForm.Group>
                <BootstrapForm.Group className='mb-3' controlId='code'>
                  <BootstrapForm.Label>
                    {intl.formatMessage({id: 'ECOMMERCE.COMMON.DESCRIPTION'})}
                  </BootstrapForm.Label>
                  <BootstrapForm.Control
                    {...getFieldProps('description')}
                    value={values.description}
                    name='description'
                    type='number'
                    as='textarea'
                    rows={3}
                  />
                  {errors?.description && (
                    <BootstrapForm.Text className='text-danger fs-7'>
                      {errors?.description}
                    </BootstrapForm.Text>
                  )}
                </BootstrapForm.Group>

                <BootstrapForm.Group className='mb-3' controlId='code'>
                  <BootstrapForm.Label>
                    {intl.formatMessage({id: 'ECOMMERCE.COMMON.RADIUS'})}
                  </BootstrapForm.Label>
                  <div className='d-flex flex-row'>
                    <BootstrapForm.Control
                      {...getFieldProps('radius')}
                      value={values.radius}
                      name='radius'
                      type='number'
                      className='mb-4'
                    />
                    <div className='mt-3 mx-3 fs-5 font-weight-bold'>
                      {intl.formatMessage({id: 'ECOMMERCE.COMMON.METRE'})}
                    </div>
                  </div>
                  <DriverAreaRadiusMap
                    radius={values.radius || 1000}
                    getCoords={(lat, lng) => {
                      setFieldValue('latitude', lat)
                      setFieldValue('longitude', lng)
                    }}
                  />
                  {errors?.radius && (
                    <BootstrapForm.Text className='text-danger fs-7'>
                      {errors?.radius}
                    </BootstrapForm.Text>
                  )}
                  {errors?.latitude && (
                    <BootstrapForm.Text className='text-danger fs-7'>
                      {errors?.latitude}
                    </BootstrapForm.Text>
                  )}
                  {errors?.longitude && (
                    <BootstrapForm.Text className='text-danger fs-7'>
                      {errors?.longitude}
                    </BootstrapForm.Text>
                  )}
                </BootstrapForm.Group>
              </BootstrapForm>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage({id: 'ECOMMERCE.COMMON.CANCEL'})}
              </Button>
              <button disabled={isSubmitting} type='submit' className='btn btn-lg btn-design-blue'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

export function DriverAreaRadiusMap({
  radius,
  getCoords,
  center = {latitude: 32.89491471162975, longitude: 13.181183096369686},
}: {
  center?: {
    latitude: number
    longitude: number
  }
  getCoords?: (lat: number, lng: number) => void
  radius: number
}) {
  const containerStyle = {
    width: '100%',
    height: '400px',
  }

  const mapRef = useRef<google.maps.Map>()
  function handleLoad(map: google.maps.Map) {
    mapRef.current = map
  }
  const [_center, set_Center] = useState(center)

  return (
    <div>
      <div className='google-map'>
        <LoadScript googleMapsApiKey={googlePlaceKey}>
          <GoogleMap
            onLoad={handleLoad}
            mapContainerStyle={containerStyle}
            center={{
              lat: _center.latitude,
              lng: _center.longitude,
            }}
            clickableIcons
            zoom={13}
            onClick={
              getCoords
                ? (e) => {
                    getCoords!(e.latLng!.lat(), e.latLng!.lng())
                    set_Center({
                      latitude: e.latLng!.lat(),
                      longitude: e.latLng!.lng(),
                    })
                  }
                : undefined
            }
          >
            {/* <MarkerF position={getCoords ? _center! : center!} /> */}
            <CircleF
              center={{
                lat: _center.latitude,
                lng: _center.longitude,
              }}
              radius={radius}
              options={{fillColor: '#ff0000', strokeColor: '#ff000099'}}
              // options={driverArea.circle.options}
            />
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  )
}

export default DriverAreaForm
