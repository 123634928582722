import axios from 'axios'
import {useQueryClient, useMutation} from 'react-query'
import {ID, QUERIES} from '../../../../../../../_template/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedUsers} from '../../core/_requests'
import * as endpoints from '../../../../../../../_template/helpers/endpoints'
import { useAppDispatch } from '../../../../../../hooks';
import { deleteFunction } from '../../../../../../redux/actions/commonCrud';

const NotificationListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const dispatch = useAppDispatch()
  const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
    const requests = userIds.map((id) => dispatch(deleteFunction(endpoints.deliveryType, id)))
    return axios.all(requests).then(() => {})
  }
  const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
  })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Delete Selected
      </button>
    </div>
  )
}

export {NotificationListGrouping}
