import { ID, Response } from '../../../../../_template/helpers'
export enum UserStatus {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
}
export type Role = {
  _id?: ID
  name: string
  message: string
}
export type Adress = {
  _id?: ID
  country: string
  city: string
  delegation: string
  zipCode: string
  street: string
  //createDate?: Date
  //updateDate?: Date
  // createdBy?: User
  // updatedBy?: User
}
// export type User = {
//   _id?: string
//   firstName?: string
//   lastName?: string
//   adress?: string
//   status?: UserStatus
//   documentType?: DocumentType
//   email?: string
//   role?: Role
//   createDate?: Date
//   updateDate?: Date
//   initials?: {
//     label: string
//     state: string
//   }
// }

export type UsersQueryResponse = Response<Array<IUsers>>

export const initialUser: IUsers = {
  firstName: '',
  lastName: '',
  photo: '',
  // documentType: DocumentType,
  email: '',

  adress: { addressLine1: '', addressLine2: '', city: '', country: '', region: '' },
  // createDate: Date,
  //updateDate: Date,
}
