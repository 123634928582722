import { ID, Response } from '../../../../../_template/helpers'
export enum OrderStatus {
  picked = 'PICKED',
  shipped = 'SHIPED',
  completed = 'COMPLETED',
  pending = 'PENDING',
  failed = 'FAILED',
  initial = 'INITIAL',
}
export enum pickupStatus {

  completed = 'COMPLETED',
  pending = 'PENDING',
  progress = 'PROGRESS',

}
export type Role = {
  _id?: ID
  name: string
  message: string
}

export type Adress = {
  _id?: ID
  country: string
  city: string
  delegation: string
  zipCode: string
  street: string
  //createDate?: Date
  //updateDate?: Date
  // createdBy?: User
  // updatedBy?: User
}

export type UsersQueryResponse = Response<Array<IOrder>>

export const initialOrder: IOrder = {
  name: '',
  status: OrderStatus.initial,
  price: '',
  customer: { firstName: '', lastName: '' },
  relayPoint: { fullname: '' },
  sellers: { shopName: '' },
  driver:{}
}
