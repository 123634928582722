import React, {useState} from 'react'
import {useField, useFormikContext} from 'formik'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import { CustomSelect } from '../../customSelect/customSelect'

const MyTextInput = ({label, ...props}: any & React.InputHTMLAttributes<HTMLInputElement>) => {
  const [field, meta] = useField(props)
  return (
    <>
      {/* begin::Label */}
      <label className='required fw-bold fs-6 mb-2'>{label}</label>
      {/* end::Label */}
      {/* begin::Input */}
      <input
        {...field}
        {...props}
        required
        className={clsx(
          'form-control form-control-solid mb-3 mb-lg-0',
          {'is-invalid': meta.touched && meta.error},
          {
            'is-valid': meta.touched && !meta.error,
          }
        )}
      />
      {meta.touched && meta.error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{meta.error}</span>
          </div>
        </div>
      )}
      {/* end::Input */}
    </>
  )
}

const InfoForm: React.FC<{type: string}> = ({type}) => {
  const intl = useIntl()
  const blankImg =
    type === 'driver' ? '/media/avatars/driver.svg' : '/media/svg/avatars/avatar_user.svg'
  const {getFieldProps, values, setFieldValue} = useFormikContext<IUsers>()
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>(values.photo)
  const deliveryType: IDeliveryType[] = useSelector(({config}: {config: ConfigState}) => {
    return config.deliveryType
  })

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (file) {
      if (file.size > 5242880) {
        alert('File Size must be less then 5MB')
        return
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        setImageFile(file)
        setImagePreview(reader.result)
        setFieldValue(
          'photo',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      }
      reader.readAsDataURL(file)
    }
  }
  const Gender = [
    {name: 'male', _id: 'M'},
    {name: 'female', _id: 'F'},
  ]
  return (
    <>
      {/* begin::Scroll */}
      <div>
        <label className='d-block fw-bold fs-6 mb-5'>
          {intl.formatMessage({id: 'ECOMMERCE.USERS.PROFILE_DETAILS'})}
        </label>
        {/* begin::Input group */}
        <div className='d-flex justify-content-center mb-7'>
          {/* begin::Image input */}
          <div
            className='image-input image-input-outline'
            data-kt-image-input='true'
            style={{
              backgroundImage: `url('${imagePreview ? imagePreview.toString() : blankImg}')`,
              padding: '10px',
              borderColor: '#c6c9cc42',
              borderStyle: 'dashed',
              borderRadius: '70px',
            }}
          >
            {/* begin::Preview existing avatar */}
            <div
              className='image-input-wrapper w-125px h-125px p-5'
              style={{
                borderRadius: '70px',
                backgroundImage: `url('${imagePreview ? imagePreview.toString() : blankImg}')`,
              }}
            ></div>
            {/* end::Preview existing avatar */}

            {/* begin::Label */}
            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='bi bi-pencil-fill fs-7'></i>
              <input
                type='file'
                name='photo'
                onChange={handleImageChange}
                accept='.png, .jpg, .jpeg'
              />
              <input type='hidden' name='avatar_remove' />
            </label>
            {/* end::Label */}

            {/* begin::Cancel */}
            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span>
            {/* end::Cancel */}
          </div>
          {/* end::Image input */}
        </div>
        {/* end::Input group */}
        <div className='d-flex flex-sm-row flex-column justify-content-between'>
          <div>
            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              <MyTextInput
                placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.FIRST_NAME'})}
                {...getFieldProps('firstName')}
                label={intl.formatMessage({id: 'ECOMMERCE.COMMON.FIRST_NAME'})}
                name='firstName'
                type='text'
                value={values.firstName}
                autoComplete='on'
                //disabled={isUserLoading}
              />
            </div>
            {/* end::Input group */}
          </div>
          <div>
            <div className='fv-row mb-7'>
              {/* begin::Input */}
              <MyTextInput
                placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.LAST_NAME'})}
                {...getFieldProps('lastName')}
                label={intl.formatMessage({id: 'ECOMMERCE.COMMON.LAST_NAME'})}
                name='lastName'
                value={values.lastName}
                type='text'
                autoComplete='on'
                //disabled={isUserLoading}
              />
              {/* end::Input */}
            </div>
          </div>
        </div>

        {/* end::Input group */}
        <div className='fv-row mb-7'>
          <div className='fv-row mb-7'>
            {/* begin::Input */}

            <div className='mb-10'>
              <CustomSelect
                className='form-select rounded form-select-solid fw-bolder'
                data-kt-select2='true'
                placeholder={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.GENDER'})}
                data-placeholder='Select option'
                data-allow-clear='true'
                data-hide-search='true'
                name='gender'
                label={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.GENDER'})}
                options={Gender}
              />
            </div>
            {/* end::Input */}
          </div>
        </div>

        {/* end::Input group */}
        <div className='fv-row mb-7'>
          <div className='fv-row mb-7'>
            {/* begin::Input */}
            <MyTextInput
              placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.EMAIL'})}
              {...getFieldProps('email')}
              label={intl.formatMessage({id: 'ECOMMERCE.COMMON.EMAIL'})}
              name='email'
              type='email'
              value={values.email}
              autoComplete='on'
              //disabled={isUserLoading}
            />
            {/* end::Input */}
          </div>
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Input */}
          <MyTextInput
            placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.PHONENUMBER'})}
            {...getFieldProps('phoneNumber')}
            label={intl.formatMessage({id: 'ECOMMERCE.COMMON.PHONENUMBER'})}
            name='phoneNumber'
            type='text'
            value={values.phoneNumber}
            autoComplete='on'
            //disabled={isUserLoading}
          />
          {/* end::Input */}
        </div>
        {type === 'driver' ? (
          <>
            <div className='mb-10'>
              <CustomSelect
                className='form-select rounded form-select-solid fw-bolder'
                data-kt-select2='true'
                placeholder={intl.formatMessage({id: 'MENU.DELIVERY.TYPE'})}
                data-placeholder='Select option'
                data-allow-clear='true'
                data-hide-search='true'
                name='deliveryType'
                label={intl.formatMessage({id: 'MENU.DELIVERY.TYPE'})}
                options={deliveryType}
              />
            </div>
          </>
        ) : null}
      </div>
      {/* end::Scroll */}

      {/* {(isSubmitting || isUserLoading) && <UsersListLoading />} */}
    </>
  )
}

export default InfoForm
