import {FC} from 'react'
import moment from 'moment'
type Props = {
  date?: Date
}

const DateCell: FC<Props> = ({date}) => {
  // Moment.locale('en');
  // var dt = '2016-05-02T00:00:00';
  return <> {date && <div>{moment(date).format('MM.DD.YYYY')} </div>}</>
}

export {DateCell}
