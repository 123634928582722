import React, { FC } from 'react'
import { PageTitle } from '../../../_template/layout/core'
// import {BuilderPage} from './BuilderPage'
import { FormattedMessage, useIntl } from 'react-intl'
import { OrdersListWrapper } from './orders-list/OrdersList'
import getBreadCrambs from '../../routing/breadcramps/getBreadCrambs'

const OrdersPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle
        breadcrumbs={getBreadCrambs(
          intl,
          {
            isActive: false,
            path: '/main-orders',
            title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.ORDER_LIST' }),
            isSeparator: false,
          },
          {
            isActive: false,
            path: '/main-orders/DD',
            title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.ORDER_LIST' }),
            isSeparator: true,
          }
        )}
      >
        {intl.formatMessage({ id: 'ECOMMERCE.ORDER.SUB.ORDERS' })}
      </PageTitle>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ORDERS'})}</PageTitle> */}
      <OrdersListWrapper />
    </>
  )
}

export default OrdersPageWrapper
