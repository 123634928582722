import axios from 'axios'
import dataAxios from './dataAxios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = '/api/auth'
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = '/api/auth/forgotten-password'
export const RESET_PASSWORD_URL = '/api/auth/reset-password'
export const CHECK_RESET_TOKEN_URL = '/api/auth/check-reset-token'
export const EXPORTDATA = '/api/export'
export const USERS_URL = '/api/users'
export const ORDER_STATUS_URL = '/api/status'
export const ORDER_DRIVERAREA_URL = '/api/driverArea'



export function postOrderStatu(orderStatu: FormData) {
  return dataAxios.post(ORDER_STATUS_URL, orderStatu)
}

export function postDriverArea(driverArea: any) {
  return dataAxios.post(ORDER_DRIVERAREA_URL, driverArea)
}

export function patchDriverArea(driverArea: any, id: string) {
  return dataAxios.patch(`${ORDER_DRIVERAREA_URL}/${id}`, driverArea)
}

export function putOrderStatu(id: string, orderStatu: {
  name: string,
  nameAr: string,
  code: string,
  description: string
  descriptionAr: string
}) {
  return dataAxios.put(`${ORDER_STATUS_URL}/${id}`, orderStatu)
}

export function deleteOrderStatu(id: string) {
  return dataAxios.delete(`${ORDER_STATUS_URL}/${id}`)
}

export function getOrderStatus(id: string) {
  return dataAxios.get(`${ORDER_STATUS_URL}/${id}`)
}

export function exportData(exportParams: {
  startDate: string
  endDate: string
  exportAs: 'excel' | 'csv' | 'pdf'
  dataType: 'sellers' | 'customers' | 'users' | 'deliveries' | 'orders'
}) {
  return dataAxios.get(EXPORTDATA, {
    params: { ...exportParams },
  })
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return dataAxios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return dataAxios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function resetPassword(email = '', passwordResetToken = '', password = '') {
  return dataAxios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
    email,
    passwordResetToken,
    password,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function checkResetTokenValidity(email = '', passwordResetToken = '') {
  return dataAxios.post(CHECK_RESET_TOKEN_URL, {
    email,
    passwordResetToken,
  })
}

export function changeUserStatus(id: string, status: 'INACTIVE' | 'ACTIVE') {
  return dataAxios.patch(`${USERS_URL}/${id}`, {
    status,
  })
}
