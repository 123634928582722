
export const CustomSelect: React.FC<any & React.SelectHTMLAttributes<HTMLSelectElement>> = ({
    label,
    options,
    ...props
  }) => {
    //const [field, meta] = useField(props)
    return (
      <>
        <label className='fw-bold fs-6 mb-2' htmlFor={props.id || props.name}>{label}</label>
        <select  {...props}>
          {options.map((option: any) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </>
    )
  }