/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo} from 'react'
import {GoogleMap, LoadScript, MarkerF} from '@react-google-maps/api'
import {FormattedMessage} from 'react-intl'
import {useSelector} from 'react-redux'
const googlePlaceKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PLACE || ''

export function TrackingMap() {
  const data: any = useSelector(({orders}: {orders: OrdersState}) => {
    return orders.oneOrderGoogleMap
  })
  const order = useMemo(() => data, [data])
 
  const containerStyle = {
    width: '100%',
    height: '400px',
  }
  return (
    <div>
      <div className='google-map'>
        <LoadScript googleMapsApiKey={googlePlaceKey}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={order.sellerPos}
            zoom={10}
            //onLoad={(map)=>setMap(map)}
            //options={{streetViewControl:false}}
            //onReady={onMapReady}
          >
            <MarkerF position={order.customerPos} icon={'/media/svg/tarcking/markerBlue.svg'} />
            <MarkerF position={order.driverPos} icon={'/media/svg/tarcking/markerYellow.svg'} />
            <MarkerF position={order.sellerPos} icon={'/media/svg/tarcking/markerPrimer.svg'} />
          </GoogleMap>
        </LoadScript>
      </div>
      <div className='d-flex  justify-content-around p-5 flex-wrap'>
        <div>
          <div className='w-15px h-15px me-3' style={{backgroundColor: '#44566C'}}></div>
          <span>
            <FormattedMessage id='ECOMMERCE.COMMON.CUSTOMER' />
          </span>
        </div>
        <div>
          <div className='w-15px h-15px me-3' style={{backgroundColor: '#0090FF'}}></div>

          <span>
            <FormattedMessage id='ECOMMERCE.COMMON.SELLER' />{' '}
          </span>
        </div>
        <div>
          <div className='w-15px h-15px  me-3' style={{backgroundColor: '#E9A800'}}></div>

          <span>
            <FormattedMessage id='ECOMMERCE.COMMON.DRIVER' />{' '}
          </span>
        </div>
      </div>
    </div>
  )
}
