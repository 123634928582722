import {PayloadAction} from '@reduxjs/toolkit'
import * as actionTypes from '../actionTypes'

const initialState: StatisticsState = {
  statistics: [],
  orders: [],
  sales: {},
  status: {},
  info: {},
  data: [
    {
      avatar: '/media/svg/icons/ordersIcon.svg',
      title: 'ECOMMERCE.COMMON.TOTAL_ORDERS',
      value: '0',
      percentage: '0%',
      code: 'ORD',
    },
    {
      avatar: '/media/svg/icons/statsIcon.svg',
      title: 'ECOMMERCE.COMMON.TOTAL_SALES',
      value: '0',
      percentage: '0%',
      code: 'SALES',
    },
    {
      avatar: '/media/svg/icons/userIcon.svg',
      title: 'ECOMMERCE.COMMON.NEW_CLIENTS',
      value: '0',
      percentage: '0%',
      code: 'CLT',
    },
  ],
  driverStatusByAdmin:[]
}
export default function statisticsReducer(
  state = initialState,
  action: PayloadAction<any>
): StatisticsState {
  switch (action.type) {
    case actionTypes.GET_ALL_STATISTICS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        statistics: action.payload,
        data: state.data?.map((item) =>
          item.code === 'ORD'
            ? {...item, value: action.payload.data?.orders?.total.count}
            : item.code === 'SALES'
            ? {...item, value: action.payload.data?.sales?.total.amount}
            : item.code === 'CLT'
            ? {...item, value: action.payload.data?.customers}
            : {...item}
        ),
        sales: action.payload.data.sales,
        status: action.payload.data.orders.status,
        // orders: action.payload.data.orders,
      }
    }
    case actionTypes.GET_ALL_ORDER_STATISTICS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        orders: action.payload.data,
      }
    }
    case actionTypes.GET_ALL_SALES_STATISTICS: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        sales: action.payload.data.sales,
      }
    }
    case actionTypes.GET_ALL_DRIVER_STATUS_BY_ADMIN: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        driverStatusByAdmin: action.payload.data,
      }
    }

    default:
      return state
  }
}
