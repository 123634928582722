import {ListViewProvider, useListView} from './core/ListViewProvider'
import {SuggestionsListHeader} from './components/header/SuggestionsListHeader'
import {SuggestionsTable} from './table/SuggestionsTable'
import {useAppDispatch} from '../../../hooks'
import {useEffect, useState} from 'react'
import {getAllFunction} from '../../../redux/actions/commonCrud'
import InfoView from '../../../../_template/layout/components/infoView'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../redux/actionTypes'
import {Tab, Tabs} from 'react-bootstrap'
import {PageTitle} from '../../../../_template/layout/core'
import {useIntl} from 'react-intl'
import getBreadCrambs from '../../../routing/breadcramps/getBreadCrambs'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import DocsCount from '../../../components/DocsCount'
import ModalWrapper from './drivers-info-modal/ModalWrapper'
import Modal from './drivers-info-modal/Modal'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'

interface state {
  status?: string | undefined
  role?: string | undefined
  order?: string | undefined

  page?: number | undefined
}
const SuggestionsList = () => {
  const {itemIdForUpdate} = useListView()
  const {id} = useParams()
  const [status, setStatus] = useState<state>({status: undefined, page: 0, order: id})
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [count, setCount] = useState(0)
  const mainOrder = localStorage.getItem('mainOrder')

  useEffect(() => {
    return () => {
      localStorage.removeItem('mainOrder')
    }
  }, [dispatch, status])
  useEffect(() => {
    ;(async () => {})()
  }, [])

  return (
    <>
      <div
        className='d-flex justify-content-between'
        style={{marginTop: '-4%', marginBottom: '25px'}}
      >
        <div>
          <PageTitle
            breadcrumbs={getBreadCrambs(
              intl,
              {
                isActive: true,
                path: '/main-orders',
                title: 'Orders',
                isSeparator: false,
              },
              {
                isActive: true,
                path: `/main-orders/${mainOrder}/orders`,
                title: 'Sub-orders',
                isSeparator: false,
              }
            )}
          >
            {intl.formatMessage({id: 'MENU.SUGGESTIONS'})}
          </PageTitle>
          <InfoView />
        </div>

        <div>
          <SuggestionsListHeader />
        </div>
      </div>

      <Tabs id='controlled-tab-example' defaultActiveKey='All'>
        <Tab
          className='nav-item'
          eventKey='All'
          title={
            <DocsCount
              showNumbers={true}
              count={count}
              title={intl.formatMessage({id: 'ECOMMERCE.COMMON.ALL'})}
            />
          }
        >
          <SuggestionsTable setCount={setCount} />
        </Tab>
      </Tabs>
      {/* </Tab>
        <Tab
          className='nav-item'
          eventKey='PENDING'
          title={intl.formatMessage({id: 'ECOMMERCE.COMMON.PENDING'})}
        >
          <OrdersTable eventKey='PENDING' />
        </Tab>
        <Tab
          className='nav-item'
          eventKey='COMPLETED'
          title={intl.formatMessage({id: 'ECOMMERCE.COMMON.COMPLETED'})}
        >
          <OrdersTable eventKey='COMPLETED' />
        </Tab>
         */}
      {/* </Tabs> */}
      {itemIdForUpdate !== undefined && <Modal />}
    </>
  )
}

const SuggestionsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SuggestionsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SuggestionsListWrapper}
