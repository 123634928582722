import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../_template/helpers'
import {useLang} from '../../_template/i18n/Metronici18n'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {Formik, Form} from 'formik'
import BootstrapForm from 'react-bootstrap/Form'
import {object, string, date} from 'yup'
import {exportData} from '../modules/auth/core/_requests'

const Export = ({
  //   exportAs,
  dataType,
}: {
  //   exportAs: 'excel' | 'csv' | 'pdf'
  dataType: 'sellers' | 'customers' | 'users' | 'deliveries' | 'orders'
}) => {
  const locale: string = useLang()
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [exportAs, setExportAs] = useState<'excel' | 'csv' | 'pdf'>()

  function download(url: any) {
    const a = document.createElement('a')
    a.href = url
    a.download = url.split('/').pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const handleClose = () => {
    setExportAs(undefined)
    setShow(false)
  }
  const handleShow = (exportAs: 'excel' | 'csv' | 'pdf') => {
    setShow(() => {
      setExportAs(exportAs)
      return true
    })
  }

  const validationSchema = object().shape({
    startDate: string().required(intl.formatMessage({id: 'AUTH.VALIDATION.STARTDATE'})),
    endDate: string().required(intl.formatMessage({id: 'AUTH.VALIDATION.ENDDATE'})),
    exportAs: string().required(intl.formatMessage({id: 'AUTH.GENERAL.LASTNAME_IS_REQUIRED'})),
    dataType: string().required(intl.formatMessage({id: 'AUTH.GENERAL.PHONE_IS_REQUIRED'})),
  })

  return (
    <>
      {/* begin::Export */}
      <button
        data-kt-menu-trigger='click'
        data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
        type='button'
        className='btn btn-lightBtn me-3'
      >
        <span>
          <KTSVG path='/media/icons/duotune/arrows/download.svg' className='svg-icon-6' />
        </span>
        <span className='mx-3'>
          <FormattedMessage id='ECOMMERCE.COMMON.EXPORT' />
        </span>
        <span>
          <KTSVG path='/media/icons/duotune/arrows/Arrow-Down.svg' className='svg-icon-8' />
        </span>{' '}
      </button>
      {/* end::Export */}

      {/* begin::SubMenu */}
      <div
        className='menu menu-sub menu-sub-dropdown rounded- w-250px w-md-200px'
        data-kt-menu='true'
      >
        {/* begin::content */}
        {/* <div className='px-7 py-4'>
          <span className='ml-5'>
            <KTSVG path='/media/icons/duotune/print/Printer.svg' className='svg-icon-3 ml-5' />{' '}
          </span>{' '}
          <span className='fs-6 text-dark'>Print</span>
        </div> */}

        {/* begin::Separator */}
        {/* <div className='separator border-gray-200'></div> */}
        {/* end::Separator */}
        <div onClick={() => handleShow('excel')} role={'button'} className='px-7 py-4 curser'>
          <span className='ml-5'>
            <KTSVG path='/media/icons/duotune/print/Excel.svg' className='svg-icon-3 ml-5' />{' '}
          </span>{' '}
          <span className='fs-6 text-dark'>Excel</span>
        </div>
        {/* begin::Separator */}
        {/* <div className='separator border-gray-200'></div> */}
        {/* end::Separator */}
        {/* <div className='px-7 py-4'>
          <span className='ml-5'>
            <KTSVG path='/media/icons/duotune/print/PDF.svg' className='svg-icon-3 ml-5' />{' '}
          </span>{' '}
          <span className='fs-6 text-dark'>PDF</span>
        </div> */}
        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}
        <div onClick={() => handleShow('csv')} role={'button'} className='px-7 py-4'>
          <span className='ml-5'>
            <KTSVG path='/media/icons/duotune/print/CSV.svg' className='svg-icon-3 ml-5' />{' '}
          </span>{' '}
          <span className='fs-6 text-dark'>CSV</span>
        </div>
        {/* end::content */}
      </div>
      {exportAs && (
        <Formik
          initialValues={{
            startDate: '',
            endDate: '',
            exportAs,
            dataType,
          }}
          onSubmit={(values) => {
            exportData(values)
              .then(({data}) => {
                const url = process.env.REACT_APP_API_URL + data.data.url.slice(0)
                download(url)
              })
              .catch((err) => {
              
              })
          }}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({values, getFieldProps, handleSubmit, errors, isSubmitting}) => (
            <Modal
              style={{
                zIndex: 10000,
              }}
              show={show}
              onHide={handleClose}
            >
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>{intl.formatMessage({id: 'ECOMMERCE.COMMON.EXPORT'})}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* end::Actions */}
                  <BootstrapForm>
                    <BootstrapForm.Group className='mb-3' controlId='formBasicEmail'>
                      <BootstrapForm.Label>
                        {intl.formatMessage({id: 'ECOMMERCE.COMMON.STARTDATE'})}
                      </BootstrapForm.Label>
                      <BootstrapForm.Control
                        {...getFieldProps('startDate')}
                        value={values.startDate}
                        name='startDate'
                        type='date'
                      />
                      {errors?.startDate && (
                        <BootstrapForm.Text className='text-danger fs-7'>
                          {errors?.startDate}
                        </BootstrapForm.Text>
                      )}
                      {/*  */}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group className='mb-3' controlId='formBasicEmail'>
                      <BootstrapForm.Label>
                        {intl.formatMessage({id: 'ECOMMERCE.COMMON.ENDDATE'})}
                      </BootstrapForm.Label>
                      <BootstrapForm.Control
                        {...getFieldProps('endDate')}
                        value={values.endDate}
                        name='endDate'
                        type='date'
                      />
                      {errors?.endDate && (
                        <BootstrapForm.Text className='text-danger fs-7'>
                          {errors?.endDate}
                        </BootstrapForm.Text>
                      )}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group className='mb-3' controlId='formBasicEmail'>
                      <BootstrapForm.Label>
                        {intl.formatMessage({id: 'ECOMMERCE.COMMON.DATATYPE'})}
                      </BootstrapForm.Label>
                      <BootstrapForm.Select
                        {...getFieldProps('dataType')}
                        value={values.dataType}
                        name='dataType'
                        aria-label='dataType'
                      >
                        <option value='sellers'>sellers</option>
                        <option value='customers'>customers</option>
                        <option value='users'>users</option>
                        <option value='deliveries'>deliveries</option>
                        <option value='orders'>orders</option>
                      </BootstrapForm.Select>
                      {/* <BootstrapForm.Text className='text-muted'>
                      We'll never share your email with anyone else.
                    </BootstrapForm.Text> */}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group className='mb-3' controlId='formBasicEmail'>
                      <BootstrapForm.Label>
                        {intl.formatMessage({id: 'ECOMMERCE.COMMON.EXPORTAS'})}
                      </BootstrapForm.Label>
                      <BootstrapForm.Select
                        {...getFieldProps('exportAs')}
                        value={values.exportAs}
                        name='exportAs'
                        aria-label='exportAs'
                      >
                        <option value='excel'>excel</option>
                        <option value='csv'>csv</option>
                        {/* <option value='pdf'>pdf</option> */}
                      </BootstrapForm.Select>
                      {/* <BootstrapForm.Text className='text-muted'>
                      We'll never share your email with anyone else.
                    </BootstrapForm.Text> */}
                    </BootstrapForm.Group>
                  </BootstrapForm>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleClose}>
                    {intl.formatMessage({id: 'ECOMMERCE.COMMON.CANCEL'})}
                  </Button>
                  <button disabled={isSubmitting} type='submit' className='btn btn-lg btn-design-blue'>
                  {intl.formatMessage({id: 'ECOMMERCE.COMMON.DOWNLOAD'})}
              </button>  
                
                </Modal.Footer>
              </Form>
            </Modal>
          )}
        </Formik>
      )}

      {/* end::SubMenu */}
    </>
  )
}

export default Export
