import { useListView } from '../../core/ListViewProvider'
import { DriverAreaListToolbar } from './driverAreaToolbar'
import { DriverAreaGrouping } from './driverAreaGrouping'
// import CustomersListSearchComponent from './DeliveryTypeSearchComponent'

const DriverAreaListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* <CustomersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <DriverAreaGrouping /> : <DriverAreaListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { DriverAreaListHeader }
