import {useListView} from '../../core/ListViewProvider'
import {NotificationListToolbar} from './notificationToolbar'
import {NotificationListGrouping} from './notificationGrouping'
// import CustomersListSearchComponent from './NotificationSearchComponent'

const NotificationListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* <CustomersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <NotificationListGrouping /> : <NotificationListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {NotificationListHeader}
