import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useQueryResponseLoading} from '../core/QueryResponseProvider'
import customerColumns from './columns/_columns'
// import {Customer} from '../core/_models'
import DeliveryTypeListLoading from '../components/loading/notificationLoading'
// import CustomersListPagination from '../components/pagination/CustomersListPagination'
import {KTCard, KTCardBody} from '../../../../../../_template/helpers'
import {useSelector} from 'react-redux'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import DeliveryTypeListSearchComponent from '../components/header/notificationSearchComponent'
import {useAppDispatch} from '../../../../../hooks'
import {getAllFunction} from '../../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import TCustomerFilterValues from '../../../../../types/TCustomerFilterValues'
import PaginationComponent from '../../../../../../_template/layout/components/pagination'

const DeliveryTypeTable: React.FC = () => {
  const notifications: INotification[] = useSelector(({config}: {config: ConfigState}) => {
    return config.allNotifications
  })

  const totalPages: number = useSelector(({config}: {config: ConfigState}) => {
    return config.totalPages
  })
  const [filterValues, setFilterValues] = useState<TCustomerFilterValues>()

  const handleChangeValues = (e?: React.ChangeEvent<any>) => {
    if (e) {
      setFilterValues({
        ...filterValues,
        // ...filterObject,
        [e.target.name]: e.target.value,
      })
    } else {
      setFilterValues(undefined)
    }
  }

  const dispatch = useAppDispatch()
  const handleDispatch = () => {
    dispatch(
      getAllFunction(endpoints.allNotifications, actionTypes.GET_ALL_NOTIFICATIONS, filterValues)
    )
  }

  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => notifications, [notifications])
  const columns = useMemo(() => customerColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <div className='d-flex justify-content-between'>
          <div className='w-100'>
            <DeliveryTypeListSearchComponent
              filterValues={filterValues}
              handleChangeValues={handleChangeValues}
              handleDispatch={handleDispatch}
              // eventKey={eventKey}
            />
          </div>
          {/* <div style={{width: '180px'}}>
            <UsersListFilter eventKey={eventKey} />
          </div> */}
        </div>

        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<INotification>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-500 fs-6' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<INotification>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <CustomersListPagination /> */}
        <PaginationComponent
          values={filterValues}
          setValues={setFilterValues}
          endpoint={endpoints.allNotifications}
          actionType={actionTypes.GET_ALL_DELIVERY_TYPES}
          totalPages={totalPages}
        />
        {isLoading && <DeliveryTypeListLoading />}
      </KTCardBody>
    </KTCard>
  )
}

export default DeliveryTypeTable
