/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import {useAppDispatch} from '../../../../hooks'
import {getAllFunctionById, getAllFunction} from '../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import {UsersListLoading} from '../../../ListUsers/users-list/components/loading/UsersListLoading'
import {useParams} from 'react-router-dom'
import OrderTrackingHeader from '../../../../components/trackingHeader'
import TrackingDetails from './trackingDetails'
import {TrackingMap} from './trackingMap'
import {TrackingInfo} from '../../../../components/trackingInfo'
import {pickupStatus} from '../core/_models'
import {useSelector} from 'react-redux'
import {FormattedMessage, useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_template/layout/core'
import getBreadCrambs from '../../../../routing/breadcramps/getBreadCrambs'

export function OrdersTracking() {
  // const order: IOrder = useSelector(({orders}: {orders: OrdersState}) => {
  //   return orders.oneOrder
  // })
  let {id} = useParams()
  const [loading, setLoading] = useState(false)
  const trackingStatus: trackingStatus[] = useSelector(
    ({orders}: {orders: OrdersState}) => orders.trackingStatus
  )
  const {oneOrderTracking, oneOrder} = useSelector(({orders}: {orders: OrdersState}) => orders)
  const [data, setData] = useState<trackingStatus[]>([])
  const statusList = useMemo(() => trackingStatus, [trackingStatus])
  const oneOrderStatus = useMemo(() => oneOrderTracking, [oneOrderTracking])
  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(
      getAllFunctionById(endpoints.orderTrackingEndpoint, actionTypes.GET_ONE_ORDER_TRACKING, id)
    )
    dispatch(getAllFunctionById(endpoints.orderEndpoint, actionTypes.GET_ONE_ORDER, id))

    dispatch(getAllFunction(endpoints.trackingStatus, actionTypes.GET_ALL_TRACKING_STATUS, {}))
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    return () => {
      dispatch({type: actionTypes.GET_ALL_TRACKING_STATUS,payload:[]})
      dispatch({type: actionTypes.GET_ONE_ORDER,payload:{}})

    }

  }, [dispatch, id])


  
  useEffect(() => {
    function handleStatusChange(a: trackingStatus[], b: trackingStatus[]) {
      let dataList = a
      let array: trackingStatus[] = dataList
        .sort((a, b) => {
          const codeA = parseInt(a.code)
          const codeB = parseInt(b.code)
          return codeB - codeA
        })
        .sort((dataList, b) => (parseInt(dataList.code) > parseInt(b.status?.code) ? 1 : -1)) // fusion de deux tableaux
        .map((aa: trackingStatus) => {
          let matchingB = b.find(
            (bb: trackingStatus) => aa && bb && parseInt(aa['code']) === parseInt(bb.status?.code)
          )

          if (matchingB) {
            return {...aa, status: pickupStatus.completed}
          } else {
            return {...aa, status: pickupStatus.pending}
          }
        })
      const final: trackingStatus | undefined = array.find(
        (val) => val.status === pickupStatus.pending
      )
      if (final) {
        final.status = pickupStatus.progress
      }

      setData(array)
    }
    if (statusList.length > 0 && oneOrderStatus.length > 0) {
      handleStatusChange(statusList, oneOrderStatus)
    }
    //return () => handleStatusChange?.abort();
  }, [oneOrderStatus, statusList])
  const intl = useIntl()
  return (
    <>
      {loading && <UsersListLoading />}
      <div>
        <PageTitle
          breadcrumbs={getBreadCrambs(
            intl,
            {
              isActive: false,
              path: '/main-orders',
              title: intl.formatMessage({id: 'MENU.ORDERS'}),
              isSeparator: false,
            },
            {
              isActive: false,
              path: `/main-orders/${oneOrder.mainOrder}/orders`,
              title: intl.formatMessage({id: 'ECOMMERCE.ORDER.SUB.ORDERS'}),
              isSeparator: false,
            }
          )}
        >
          {intl.formatMessage({id: 'ECOMMERCE.ORDER.TRACKING'})}
        </PageTitle>
      </div>
      <div className='card'>
        <div className='card-body mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0' style={{color: '#e9a800'}}>
                <FormattedMessage id='ECOMMERCE.ORDER.TRACKING' />
              </h3>
              {/* <br/> */}
            </div>
            <div></div>
          </div>

          <div>
            <div id='kt_activities_body'>
              <TrackingInfo data={oneOrder} />
              <OrderTrackingHeader data={data} />
            </div>
          </div>
          <div className='d-flex justify-content-between w-100 flex-wrap'>
            <div className='w-md-50 w-sm-100 p-10'>
              <TrackingDetails data={data} />
            </div>
            <div className='w-md-50 w-sm-100 p-10'>
              <TrackingMap />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
