import {FC} from 'react'
import {DriversListWrapper} from './drivers-list/DriversList'
const REACT_APP_ROLE_DRIVER = process.env.REACT_APP_ROLE_DRIVER
console.log(REACT_APP_ROLE_DRIVER,"process.env.REACT_APP_ROLE_DRIVER")
const DriversList: FC = () => {
  return (
    <>
      <DriversListWrapper />
    </>
  )
}

export default DriversList
