/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../_template/helpers'
import {getCSSVariableValue} from '../../../_template/assets/ts/_utils'
import {Dropdown1} from '../../../_template/partials/content/dropdown/Dropdown1'
import {useThemeMode} from '../../../_template/partials/layout/theme-mode/ThemeModeProvider'
import {useLang} from '../../../_template/i18n/Metronici18n'
import {FormattedMessage} from 'react-intl'
import {useSelector} from 'react-redux'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  sales: any
  title: any
}

const SalesWidget: React.FC<Props> = ({className, chartColor, chartHeight, sales, title}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const locale: string = useLang()
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    if (sales.currentWeek) {
      const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, sales))
      if (chart) {
        chart.render()
      }

      return chart
    }
    if (sales[0].status?.name) {
      const chart = new ApexCharts(
        chartRef.current,
        chartStatusOptions(chartColor, chartHeight, sales)
      )
      if (chart) {
        chart.render()
      }

      return chart
    }
  }
  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, sales])

  return (
    <>
      {sales[0] && sales[0].status?.name && (
        <div className={`card ${className}`}>
          {/* begin::Beader */}
          <div className='card-header border-0 py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
              {/* <span className='text-muted fw-semibold fs-7'>Complete your profile setup</span> */}
            </h3>

            <div className='card-toolbar'>
              {/* begin::Menu */}
              <a href='#'>
                <i className='bi bi-three-dots fs-5'></i>
              </a>
              {/* <Dropdown1 /> */}
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className='card-body d-flex flex-column'>
            <div className='flex-grow-1'>
              <div ref={chartRef} className='mixed-widget-4-chart'></div>
            </div>

            <div className='pt-5'>
              <div className='d-flex flex-column content-justify-center flex-row-fluid'>
                {sales.map((item: any) => (
                  <>
                    <div className='d-flex fw-semibold align-items-center my-3'>
                      <div
                        className='bullet w-8px h-8px rounded-2 me-3'
                        style={{backgroundColor: '#2B3D44'}}
                      ></div>
                      <div className='text-gray-500 flex-grow-1 me-4'>{item.status?.name}</div>
                      <div className='fw-bolder text-gray-700 text-xxl-end'>{item?.count}</div>
                    </div>
                    {/* begin::Separator */}
                    <div className='separator border-gray-200'></div>
                    {/* end::Separator */}
                  </>
                ))}

                {/* <div className='d-flex fw-semibold align-items-center my-3'>
                  <div
                    className='bullet w-8px h-8px rounded-2 me-3'
                    style={{backgroundColor: '#E9A800'}}
                  ></div>
                  <div className='text-gray-500 flex-grow-1 me-4'>
                    <FormattedMessage id='ECOMMERCE.DASHBOARD.LAST.WEEK' />
                  </div>
                  <div className='fw-bolder text-gray-700 text-xxl-end'>
                    {sales?.lastWeek?.count}
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* end::Body */}
        </div>
      )}
      {sales.currentWeek && (
        <div className={`card ${className}`}>
          {/* begin::Beader */}
          <div className='card-header border-0 py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>
                <FormattedMessage id='ECOMMERCE.DASHBOARD.SALES' />
              </span>
              {/* <span className='text-muted fw-semibold fs-7'>Complete your profile setup</span> */}
            </h3>

            <div className='card-toolbar'>
              {/* begin::Menu */}
              <a href='#'>
                <i className='bi bi-three-dots fs-5'></i>
              </a>
              {/* <Dropdown1 /> */}
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className='card-body d-flex flex-column'>
            <div className='flex-grow-1'>
              <div ref={chartRef} className='mixed-widget-4-chart'></div>
            </div>

            <div className='pt-5'>
              <div className='d-flex flex-column content-justify-center flex-row-fluid'>
                <div className='d-flex fw-semibold align-items-center my-3'>
                  <div
                    className='bullet w-8px h-8px rounded-2 me-3'
                    style={{backgroundColor: '#2B3D44'}}
                  ></div>
                  <div className='text-gray-500 flex-grow-1 me-4'>
                    <FormattedMessage id='ECOMMERCE.DASHBOARD.CURRENT.WEEK' />
                  </div>
                  <div className='fw-bolder text-gray-700 text-xxl-end'>
                    {sales?.currentWeek?.count}
                  </div>
                </div>

                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}

                <div className='d-flex fw-semibold align-items-center my-3'>
                  <div
                    className='bullet w-8px h-8px rounded-2 me-3'
                    style={{backgroundColor: '#E9A800'}}
                  ></div>
                  <div className='text-gray-500 flex-grow-1 me-4'>
                    <FormattedMessage id='ECOMMERCE.DASHBOARD.LAST.WEEK' />
                  </div>
                  <div className='fw-bolder text-gray-700 text-xxl-end'>
                    {sales?.lastWeek?.count}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* end::Body */}
        </div>
      )}
    </>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, sales: any): ApexOptions => {
  const baseColor = '#2B3D44'
  const lightColor = '#E9A800'
  const labelColor = getCSSVariableValue('--bs-gray-700')
  const current = parseFloat(sales.currentWeek?.count)
  const last = parseFloat(sales.lastWeek?.count)

  const value = (current / (current + last)) * 100
  const valueToFixed = value.toFixed(2)

  return {
    series: [valueToFixed ? parseFloat(valueToFixed) : 0],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '70%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}
const chartStatusOptions = (chartColor: string, chartHeight: string, sales: any): ApexOptions => {
  const baseColor = '#2B3D44'
  const lightColor = '#E9A800'
  const labelColor = getCSSVariableValue('--bs-gray-700')
  // const current = parseFloat(sales.currentWeek?.count)
  // const last = parseFloat(sales.lastWeek?.count)
  let sum = sales.reduce(
    (acc: any, key: any) => {
      return acc + key.count
    },

    0
  )
  const delivered = sales.find((item: any) => item.status?.name?.toUpperCase() === 'DELIVERED')
  const value = delivered?.count ? (delivered?.count / sum) * 100 : 0
  const valueToFixed = value?.toFixed(2)

  return {
    series: [valueToFixed ? parseFloat(valueToFixed) : 0],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '70%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}
export {SalesWidget}
