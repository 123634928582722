// @ts-nocheck
import {Column} from 'react-table'
import {
  UserInfoCell,
  // UserActionsCell,
  UserSelectionCell,
  UserCustomHeader,
  UserSelectionHeader,
} from '../../../../../../_template/layout/components/columns'
import {FormattedMessage} from 'react-intl'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
import {CustomSuggStatus} from './customStatus'
import {OrderActionsCell} from '../../../../../../_template/layout/components/columns/OrderActionsCell'
import {OrderAddressCell} from '../../../../../../_template/layout/components/columns/OrderAddressCell'
import {FirstCell} from '../../../../../../_template/layout/components/columns/FirstCell'
import {SuggestionsActionsCell} from '../../../../../../_template/layout/components/columns/SugestionsActionCell'

const suggestionsColumns: ReadonlyArray<Column<ISuggestions>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CODE' />}
        className='min-w-125px'
      />
    ),
    id: 'code',
    Cell: ({...props}) => <FirstCell value={props.data[props.row.index].code} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.SELLER' />}
        className='min-w-125px'
      />
    ),
    Cell: ({...props}) => {
      const {seller} = props?.data[props.row.index]
      return (
        <div className='d-flex gap-1'>
          <div>{seller?.shopName}</div>
        </div>
      )
    },

    id: 'customer',
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.ADDRESS' />}
        className='min-w-200px'
      />
    ),
    id: 'seller.address',
    Cell: ({...props}) => <OrderAddressCell order={props.data[props.row.index].seller} />,
    // accessor: 'customer',
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='ECOMMERCE.COMMON.RELAYPOINT' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   accessor: 'relayPoint',
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.STATUS' />}
        className='min-w-125px'
      />
    ),
    id: 'status',
    Cell: ({...props}) => <CustomSuggStatus status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),
    id: 'createDate',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].createDate} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='MENU.ACTIONS' />}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <SuggestionsActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {suggestionsColumns}
