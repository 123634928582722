import {ListViewProvider} from './core/ListViewProvider'
import {UsersListHeader} from './components/header/RPListHeader'
import {RelayPointTable} from './table/RelayPointsTable'
// import {UserEditModal} from './relayPoints-edit-modal/UserEditModal'
// import {KTCard} from '../../../../_template/helpers'
import InfoView from '../../../../_template/layout/components/infoView/index'
import {getAllFunction} from '../../../redux/actions/commonCrud'
import {useEffect} from 'react'
import {useAppDispatch} from '../../../hooks'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../redux/actionTypes'
import {PageTitle} from '../../../../_template/layout/core'
import {useIntl} from 'react-intl'
import getBreadCrambs from '../../../routing/breadcramps/getBreadCrambs'

const RelayPointsList = () => {
  const intl = useIntl()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAllFunction(endpoints.relayPointEndpoint, actionTypes.GET_ALL_FRELAY_POINTS, {}))
  }, [dispatch])
  return (
    <>
      <div
        className='d-flex justify-content-between'
        // style={{marginTop: '-4%', marginBottom: '25px'}}
      >
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({id: 'MENU.RELAY.POINTS'})}
          </PageTitle>
          <InfoView />
        </div>
        {/* <div>
          <UsersListHeader />
        </div> */}
      </div>

      <RelayPointTable />
    </>
  )
}

const UsersListWrapper = () => (
  <ListViewProvider>
    <RelayPointsList />
  </ListViewProvider>
)

export {UsersListWrapper}
