import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { useQueryResponseLoading } from '../core/QueryResponseProvider'
import { ordersColumns } from './columns/_columns'
import * as actionTypes from '../../../../redux/actionTypes'
import { OrdersListLoading } from '../components/loading/OrdersListLoading'
import { KTCard, KTCardBody } from '../../../../../_template/helpers'
import { useSelector } from 'react-redux'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import PaginationComponent from '../../../../../_template/layout/components/pagination'

type Props = {
  // eventKey?: string | undefined
  setCount: any;
}
type values = {
  role?: string | undefined
  phone?: string | undefined
  status?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  mainOrder?:string|undefined
  email?: string | undefined
}
const OrdersTable: React.FC<Props> = ({ setCount }) => {
  const orders: IOrder[] = useSelector(({ orders }: { orders: OrdersState }) => {
    return orders.orders
  })
  const [values, setValues] = useState<values | undefined>({})
  const isLoading = useQueryResponseLoading()
  const data: IOrder[] = useMemo(() => orders, [orders])
  const columns = useMemo(() => ordersColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const totalPages: number = useSelector(({ orders }: { orders: OrdersState }) => {
    return orders.totalPages
  })

  const totalItems: number = useSelector(({ orders }: { orders: OrdersState }) => {
    return orders.totalItems
  })

  useEffect(() => {
    if (totalItems) {
      setCount(totalItems);
    } else {
      setCount(0);
    }
  }, [totalItems])
  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <div className='d-flex justify-content-between'>
          <div className='w-md-100'>
          </div>
        </div>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IOrder>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-500 fs-6' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IOrder>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
          values={values}
          setValues={setValues}
          endpoint={endpoints.orderEndpoint}
          actionType={actionTypes.GET_ALL_ORDERS}
          totalPages={totalPages}
        />
        {isLoading && <OrdersListLoading />}
      </KTCardBody>
    </KTCard>
  )
}

export { OrdersTable }
