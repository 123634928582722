import React, { FC } from 'react'
import { PageTitle } from '../../../_template/layout/core'
// import {BuilderPage} from './BuilderPage'
import { useIntl } from 'react-intl'
import CustomersListWrapper from './customers-list/CustomersList'
// const wizardsBreadCrumbs: Array<PageLink> = [

//   {
//     title: '',
//     path: '',
//     isSeparator: true,
//     isActive: false,
//   },
// ]
const CustomersPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CUSTOMERS' })}</PageTitle>
      <CustomersListWrapper />
    </>
  )
}

export default CustomersPageWrapper
