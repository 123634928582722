import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Form, Formik, useField} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_template/helpers'
// import {initDeliveryType} from '../core/_models'
import clsx from 'clsx'
// import {useListView} from '../core/ListViewProvider'
// import CustomersListLoading from '../components/loading/deliveryTypeLoading'
// import {useQueryResponse} from '../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {IntlShape} from '@formatjs/intl'
import {useAppDispatch} from '../../../../../hooks'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import Loading from '../../../../../../_template/layout/components/loading/Loading'
import {Socket, io} from 'socket.io-client'
import {socketIO} from '../../../../../socket'
import {useListView} from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'

type Props = {
  isDataLoading: boolean
  deliveryType: Message
}

const DeliveryTypeSchema = (intl: IntlShape<any>) =>
  Yup.object().shape({
    title: Yup.string()
      .max(50, intl.formatMessage({id: 'AUTH.GENERAL.MAX30'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.TITLE_IS_REQUIRED'})),
    message: Yup.string()
      .max(30, intl.formatMessage({id: 'AUTH.GENERAL.MAX30'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.MESSAGE_IS_REQUIRED'})),
    // weight: Yup.number().required(intl.formatMessage({id: 'AUTH.GENERAL.VALUE_IS_REQUIRED'})),
    // width: Yup.number().required(intl.formatMessage({id: 'AUTH.GENERAL.VALUE_IS_REQUIRED'})),
    // height: Yup.number().required(intl.formatMessage({id: 'AUTH.GENERAL.VALUE_IS_REQUIRED'})),
    // depth: Yup.number().required(intl.formatMessage({id: 'AUTH.GENERAL.VALUE_IS_REQUIRED'})),
  })
const MyTextInput = ({label, ...props}: any & React.InputHTMLAttributes<HTMLInputElement>) => {
  const [field, meta] = useField(props)

  return (
    <>
      {/* begin::Label */}
      <label className='required fw-bold fs-6 mb-2'>{label}</label>
      {/* end::Label */}

      {/* begin::Input */}
      <input
        {...field}
        {...props}
        className={clsx(
          'form-control form-control-solid mb-3 mb-lg-0',
          {'is-invalid': meta.touched && meta.error},
          {
            'is-valid': meta.touched && !meta.error,
          }
        )}
      />
      {meta.touched && meta.error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{meta.error}</span>
          </div>
        </div>
      )}
      {/* end::Input */}
    </>
  )
}

const NotificationEditModalForm = () => {
  const {setItemIdForUpdate} = useListView()
  const [imageFile, setImageFile] = useState<File>()
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null | undefined>(null)

     const {refetch} = useQueryResponse()
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  useEffect(() => {
    // no-op if the socket is already connected
    socketIO.connect()
    // return () => {
    //   socketIO.disconnect()
    // }
  }, [])
  const sendMessage = (e: Message) => {
    socketIO.emit('newMessage', e)
    socketIO.emit('getUnreadCount', e)
    socketIO.emit('notification-seen','hello')
    cancel()
  }
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImageFile(file)
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }
  const blankImg = toAbsoluteUrl('/media/icons/duotune/general/notification.svg')

  return (
    <>
      {/* <div className='card m-4 p-5'>
        <div className='card-body m-4 p-5'> */}
      {/* {isDataLoading && <Loading />} */}
      <Formik
        enableReinitialize={true}
        initialValues={{title: '', message: '' ,status:0}}
        validationSchema={DeliveryTypeSchema(intl)}
        onSubmit={async (values, {setSubmitting}) => {
          sendMessage(values)
        }}
        validateOnChange={false}
      >
        {({isSubmitting, values, isValid, touched, getFieldProps, errors}) => (
          <Form>
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              {/* begin::Input group */}
              <div className='d-flex justify-content-center mb-7'>
                {/* begin::Label */}

                {/* end::Label */}

                {/* begin::Image input */}
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{
                    backgroundImage: `url('${blankImg}')`,
                    padding: '10px',
                    borderColor: '#c6c9cc42',
                    borderStyle: 'dashed',
                    borderRadius: '70px',
                  }}
                >
                  {/* begin::Preview existing avatar */}

                  <div
                    className='image-input-wrapper w-125px h-125px p-5'
                    style={{
                      borderRadius: '70px',
                      backgroundImage: `url('${
                        imagePreview ? imagePreview.toString() : blankImg
                      }')`,
                    }}
                  ></div>
                  {/* end::Preview existing avatar */}

                  {/* begin::Label */}
                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    title='Change avatar'
                  >
                    <i className='bi bi-pencil-fill fs-7'></i>

                    <input
                      type='file'
                      name='photo'
                      onChange={handleImageChange}
                      accept='.png, .jpg, .jpeg'
                    />
                    <input type='hidden' name='avatar_remove' />
                  </label>
                  {/* end::Label */}

                  {/* begin::Cancel */}
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    title='Cancel avatar'
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                  {/* end::Cancel */}
                </div>
                {/* end::Image input */}

                {/* begin::Hint */}
                {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
                {/* end::Hint */}
              </div>
              {/* end::Input group */}
              <div>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.TITLE'})}
                    {...getFieldProps('title')}
                    label={intl.formatMessage({id: 'ECOMMERCE.COMMON.TITLE'})}
                    name='title'
                    type='text'
                    // value={formik.values.name}
                    autoComplete='on'
                    //disabled={isDataLoading}
                  />
                </div>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.MESSAGE'})}
                    {...getFieldProps('message')}
                    label={intl.formatMessage({id: 'ECOMMERCE.COMMON.MESSAGE'})}
                    name='message'
                    type='text'
                    // value={formik.values.code}
                    autoComplete='on'
                    //disabled={isDataLoading}
                  />
                </div>
                {/* end::Input group */}
              </div>
            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}
            <div className='d-flex justify-content-between pt-15'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={isSubmitting}
              >
                {intl.formatMessage({ id: 'ECOMMERCE.COMMON.DISCARD' })}
              </button>

              <button
                type='submit'
                className='btn btn-design'
                data-kt-users-modal-action='submit'
                disabled={isSubmitting || !isValid || !touched}
              >
                <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                {isSubmitting && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
            {/* {(isSubmitting || isDataLoading) && <CustomersListLoading />} */}
          </Form>
        )}
      </Formik>
      {/* </div>
      </div> */}
    </>
  )
}

export {NotificationEditModalForm}
