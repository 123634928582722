import {io, Socket} from 'socket.io-client'

// "undefined" means the URL will be computed from the `window.location` object
const url = process.env.REACT_APP_API_URL || ''
export const socketIO: Socket<ServerToClientEvents, ClientToServerEvents> = io(url, {
  autoConnect: true,
  extraHeaders: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
})
