// import {useQuery} from 'react-query'
import { DriverAreaEditModalForm } from './driverAreaEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../components/helpers'
import { useListView } from '../core/ListViewProvider'
// import {getUserById} from '../core/_requests'
import { useAppDispatch } from '../../../../../hooks'
import { getAllFunctionById } from '../../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
// import {fetchStart, fetchSuccess} from '../../../../redux/actions/common'
const DeliveryTypeEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  // const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const oneDeliveryType: IDeliveryType = useSelector(({ config }: { config: ConfigState }) => {
    return config.oneDeliveryType
  })
  const data = useMemo(() => oneDeliveryType, [oneDeliveryType])
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (itemIdForUpdate) {
      dispatch(
        getAllFunctionById(
          endpoints.customerEndpoint,
          actionTypes.GET_ALL_CUSTOMERS_BY_ID,
          itemIdForUpdate
        )
      )
    }
  }, [dispatch, itemIdForUpdate])

  if (!itemIdForUpdate) {
    return <DriverAreaEditModalForm isUserLoading={false} deliveryType={{ _id: undefined }} />
  }

  if (data) {
    return <DriverAreaEditModalForm isUserLoading={false} deliveryType={data} />
  }

  return null
}

export default DeliveryTypeEditModalFormWrapper
