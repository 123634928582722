import React from 'react'
import {Field, useField, useFormikContext} from 'formik'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import PlacesAutocomplete from '../../../../helpers/components/AutoCompleteGoogleComp'
import {LoadScript} from '@react-google-maps/api'
const libraries: (any)[] = [
  'places',
]
const googlePlaceKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PLACE || ''
const AdressForm: React.FC = () => {
  const intl = useIntl()
  const {getFieldProps} = useFormikContext()

  return (
    <>
      <div>
        {/* <label className='d-block fw-bold fs-6 mb-5'>
          {intl.formatMessage({id: 'ECOMMERCE.CUSTOMERS.ADDRESS'})}
        </label> */}

        {/* begin autocomplete google maps */}

        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          <label className=' required fw-bold fs-6 mb-2'>
            {intl.formatMessage({id: 'ECOMMERCE.COMMON.ADDRESS_LINE_1'})}
          </label>
          <div className='fv-row mb-7'>
            <LoadScript googleMapsApiKey={googlePlaceKey} libraries={libraries}>
              <Field name='address.addressLine1' component={PlacesAutocomplete} />
            </LoadScript>
            {/* end autocomplete google maps */}
          </div>
          {/* <MyTextInput
            placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.ADDRESS_LINE_1'})}
            {...getFieldProps('address.addressLine1')}
            label={intl.formatMessage({id: 'ECOMMERCE.COMMON.ADDRESS_LINE_1'})}
            name='address.addressLine1'
            type='text'
            autoComplete='on'
            // disabled={isUserLoading}
          /> */}
        </div>
        {/* end::Input group */}
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-6 mb-2'>
            {intl.formatMessage({id: 'ECOMMERCE.COMMON.OPTIONAL'})}
          </label>
          {/* end::Label */}
          <MyTextInput
            placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.ADDRESS_LINE_2'})}
            {...getFieldProps('address.addressLine2')}
            label={intl.formatMessage({id: 'ECOMMERCE.COMMON.OPTIONAL'})}
            name='address.addressLine2'
            type='text'
            autoComplete='on'
            // disabled={isUserLoading}
          />
        </div>
        {/* end::Input group */}
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>
            {intl.formatMessage({id: 'ECOMMERCE.COMMON.CITY'})}
          </label>

          <MyTextInput
            placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.CITY'})}
            {...getFieldProps('address.city')}
            label={intl.formatMessage({id: 'ECOMMERCE.COMMON.CITY'})}
            name='address.city'
            type='text'
            autoComplete='on'
            // disabled={isUserLoading}
          />
        </div>
        {/* end::Input group */}
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>
            {intl.formatMessage({id: 'ECOMMERCE.COMMON.COUNTRY'})}
          </label>

          <MyTextInput
            placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.COUNTRY'})}
            {...getFieldProps('address.country')}
            label={intl.formatMessage({id: 'ECOMMERCE.COMMON.COUNTRY'})}
            name='address.country'
            type='text'
            autoComplete='on'
            // disabled={isUserLoading}
          />
        </div>
        {/* end::Input group */}

        <div className='d-flex flex-sm-row flex-column justify-content-between'>
          <div>
            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'ECOMMERCE.COMMON.STATE_REGION'})}
              </label>

              <MyTextInput
                placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.STATE_REGION'})}
                {...getFieldProps('address.state')}
                label={intl.formatMessage({id: 'ECOMMERCE.COMMON.STATE_REGION'})}
                name='address.state'
                type='text'
                autoComplete='on'
                // disabled={isUserLoading}
              />
            </div>
            {/* end::Input group */}
          </div>
          <div>
            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'ECOMMERCE.COMMON.ZIPCODE'})}
              </label>

              <MyTextInput
                placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.ZIPCODE'})}
                {...getFieldProps('address.zipCode')}
                label={intl.formatMessage({id: 'ECOMMERCE.COMMON.ZIPCODE'})}
                name='address.zipCode'
                type='text'
                autoComplete='on'
                // disabled={isUserLoading}
              />
            </div>
            {/* end::Input group */}
          </div>
        </div>
      </div>
      {/* end::Scroll */}
    </>
  )
}

export default AdressForm
const MyTextInput = ({label, ...props}: any & React.InputHTMLAttributes<HTMLInputElement>) => {
  const [field, meta] = useField(props)
  return (
    <>
      {/* begin::Input */}
      <input
        {...field}
        {...props}
        className={clsx(
          'form-control mb-3 mb-lg-0',
          {'is-invalid': meta.touched && meta.error},
          {
            'is-valid': meta.touched && !meta.error,
          }
        )}
      />
      {meta.touched && meta.error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{meta.error}</span>
          </div>
        </div>
      )}
      {/* end::Input */}
    </>
  )
}
