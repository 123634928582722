/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import {useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../_template/helpers'
import moment from 'moment'
import * as endpoints from '../../../_template/helpers/endpoints'
import * as actionTypes from '../../redux/actionTypes'
import {getAllFunction} from '../../redux/actions/commonCrud'
import {useAppDispatch} from '../../hooks'
import {Link} from 'react-router-dom'
type Props = {
  className: string
}

const TablesWidget7: React.FC<Props> = ({className}) => {
  const orders: any = useSelector(({orders}: {orders: OrdersState}) => {
    return orders.mainOrders
  })
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      getAllFunction(endpoints.mainOrderEndpoint, actionTypes.GET_ALL_MAIN_ORDERS, {size: 4})
    )
  }, [dispatch])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fs-3 mb-1'>
            <FormattedMessage id='ECOMMERCE.DASHBOARD.LATEST.ORDERS' />
          </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            <FormattedMessage id='ECOMMERCE.DASHBOARD.LATEST.ORDERS.MORE' />
          </span>
        </h3>
        <div className='card-toolbar'>
          {/* <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_7_tab_1'
              >
                <FormattedMessage id='ECOMMERCE.DASHBOARD.MONTH' />
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_7_tab_2'
              >
                <FormattedMessage id='ECOMMERCE.DASHBOARD.WEEK' />
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary px-4'
                data-bs-toggle='tab'
                href='#kt_table_widget_7_tab_3'
              >
                <FormattedMessage id='ECOMMERCE.DASHBOARD.DAY' />
              </a>
            </li>
          </ul> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_7_tab_1'>
            {/* begin::Table container */}
            <div className=''>
              {/* begin::Table */}
              <table className='customtable align-middle w-100 gs-0 gy-2'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='p-0 w-250px'>
                      <FormattedMessage id='ECOMMERCE.DASHBOARD.CUSTOMER.NAME' />
                    </th>
                    <th className='p-0 min-w-140px'>
                      <FormattedMessage id='ECOMMERCE.DASHBOARD.ORDER.NO' />
                    </th>

                    <th className='p-0 min-w-120px'>
                      <FormattedMessage id='ECOMMERCE.DASHBOARD.AMOUNT' />
                    </th>
                    <th className='p-0 min-w-120px'>
                      <FormattedMessage id='ECOMMERCE.DASHBOARD.PAYMENT.TYPE' />
                    </th>
                    <th className='p-0 min-w-120px'>
                      <FormattedMessage id='ECOMMERCE.DASHBOARD.STATUS' />
                    </th>
                    <th className='p-0 min-w-120px'>
                      <FormattedMessage id='ECOMMERCE.DASHBOARD.DATE' />
                    </th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {orders &&
                    orders.length > 0 &&
                    orders?.map((item: IOrder) => (
                      <tr>
                        <Link to={`/customers/${item.customer?._id}`}>
                          {' '}
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-50px me-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')}
                                    className='h-75 align-self-end'
                                    alt=''
                                  />
                                </span>
                              </div>
                              <span className='text-dark text-hover-primary mt-2 fs-6'>
                                {item.customer?.firstName + ' ' + item.customer?.lastName}
                              </span>
                            </div>
                          </td>
                        </Link>

                        <td>
                          {' '}
                          <Link to={`/main-orders/${item._id}`}>
                            <span className='text-darktext-hover-primary mb-1 fs-6'>
                              #{item.reference}
                            </span>
                            {/* <span className='text-muted fw-semibold d-block fs-7'>{item.desc}</span> */}
                          </Link>
                        </td>
                        <td>
                          <span className='text-dark mb-1 fs-6'>
                            {item.totalAmount ? parseFloat(item.totalAmount)?.toFixed(3) : 0} LYD
                          </span>
                          {/* <span className='text-muted fw-semibold d-block fs-7'>{item.desc}</span> */}
                        </td>
                        <td className='text-start'>
                          <span className='text-darktext-hover-primary mb-1 fs-6'>
                            {item.paidThrough}
                          </span>
                        </td>
                        <td className=''>
                          <span className='text-dark fs-7'>
                            <span
                              style={{
                                width: 'auto',
                                padding: '8px 15px 8px 14px',
                                fontSize: '15px',
                              }}
                              className={`badge ${
                                item.status?.code === '10'
                                  ? 'badge-light-warning'
                                  : item.status?.code === '20'
                                  ? 'badge-light-info'
                                  : item.status?.code === '100'
                                  ? 'badge-light-success'
                                  : item.status?.code === '30'
                                  ? 'badge-light-primary'
                                  : item.status?.code === '400'
                                  ? 'badge-light-danger'
                                  : 'badge-light-warning'
                              }`}
                            >
                              {item.status?.name}
                            </span>
                          </span>
                        </td>
                        <td className=''>
                          <span className='text-dark fs-7'>
                            {moment(item.createDate).format('MM.DD.YYYY')}
                          </span>
                        </td>

                        <td className='text-end'>
                          <span className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'>
                            <i className='bi bi-three-dots-vertical fs-5'></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget7}
