import React, {useEffect, useState} from 'react'
import {useField, useFormikContext} from 'formik'

import {FormattedMessage, useIntl} from 'react-intl'
import {useAppDispatch} from '../../../../../app/hooks'
import {getAllFunction} from '../../../../../app/redux/actions/commonCrud'
import {useSelector} from 'react-redux'
import * as actionTypes from '../../../../../app/redux/actionTypes'
import * as endpoints from '../../../../helpers/endpoints'
import clsx from 'clsx'
import ReactSelect from 'react-select'
import CustomerDropDownComponent from '../../CustomerDropDownComponent'

const options = [
  {value: 'ACTIVE', label: 'Enabled'},
  {value: 'INACTIVE', label: 'Disabled'},
]
const CustomSelect: React.FC<any & React.SelectHTMLAttributes<HTMLSelectElement>> = ({
  label,
  options,
  ...props
}) => {
  const [field, meta] = useField(props)
  return (
    <>
      <label className='fw-bold fs-6 mb-2' htmlFor={props.id || props.name}>
        {label}
      </label>
      <select {...field} {...props}>
        <option key={'0'} value={''}>
          {''}
        </option>
        {options.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {meta.touched && meta.error && <div className='text-danger fs-4 mt-2'>{meta.error}</div>}
    </>
  )
}
const RoleForm: React.FC<{type: string}> = ({type}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const {getFieldProps, values, errors} = useFormikContext<IUsers>()
  useEffect(() => {
    dispatch(getAllFunction(endpoints.DriverAreas, actionTypes.GET_ALL_DRIVER_AREAS, {}))
  }, [dispatch])
  const driverAreas = useSelector(({config}: {config: ConfigState}) => config.driverAreas)
  const roles: Role[] = useSelector(({users}: {users: UsersState}) => {
    return users.roles.filter(
      (item: Role) => item?.name !== 'ROLE_SUPER_ADMIN' && item?.name !== 'ROLE_DRIVER'
    )
  })
  useEffect(() => {
    dispatch(getAllFunction(endpoints.userRole, actionTypes.GET_ALL_USER_ROLES, {}))
  }, [dispatch])

  const typeOfID = [
    {label: 'ID card', value: 'card'},
    {label: 'Passport', value: 'passport'},
  ]

  return (
    <>
      {/* begin::Scroll */}
      <div>
        {type === 'user' ? (
          <label className='d-block fw-bold fs-6 mb-5'>
            {intl.formatMessage({id: 'ECOMMERCE.USERS.ROLES_STATUS'})}
          </label>
        ) : (
          <label className='d-block fw-bold fs-6 mb-5'>
            {intl.formatMessage({id: 'ECOMMERCE.USERS.USER_STATUS'})}
          </label>
        )}

        {/* begin::Input group */}

        {/* begin::Input group */}
        <div className='mb-10'>
          <CustomSelect
            className='form-select rounded form-select-solid fw-bolder'
            data-kt-select2='true'
            placeholder='Status'
            required
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='Status'
            data-hide-search='true'
            name='status'
            label={intl.formatMessage({id: 'ECOMMERCE.COMMON.STATUS'})}
            options={options}
          />
        </div>
        {/* end::Input group */}
        {/* begin::Input group */}
        {type === 'user' ? (
          <div className='mb-10'>
            <CustomSelect
              className='form-select rounded form-select-solid fw-bolder'
              data-kt-select2='true'
              placeholder='Role'
              required
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              name={'role'}
              label={intl.formatMessage({id: 'ECOMMERCE.COMMON.ROLE'})}
              options={roles.map((item) => ({...item, value: item._id, label: item.name}))}
            />
          </div>
        ) : (
          <>
            {driverAreas && (
              <div className='fv-row mb-7'>
                <MultieSelect
                  options={driverAreas.map((driverArea) => ({
                    label: driverArea.name,
                    value: driverArea._id,
                  }))}
                />
              </div>
            )}

            <div className='d-flex flex-sm-row flex-column justify-content-between'>
              <div>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.IDNUMBER'})}
                    {...getFieldProps('IDNumber')}
                    label={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.IDNUMBER'})}
                    name='IDNumber'
                    type='text'
                    value={values.IDNumber}
                    autoComplete='on'
                    //disabled={isUserLoading}
                  />
                </div>
                {/* end::Input group */}
              </div>
              <div>
                <div className='fv-row mb-7'>
                  <CustomSelect
                    className='form-select rounded form-select-solid fw-bolder'
                    data-kt-select2='true'
                    placeholder={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.TYPEOfID'})}
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-hide-search='true'
                    name='typeOfID'
                    label={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.TYPEOfID'})}
                    options={typeOfID}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex flex-sm-row flex-column justify-content-between'>
              <div>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.LICENCENUMBER'})}
                    {...getFieldProps('licenseNumber')}
                    label={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.LICENCENUMBER'})}
                    name='licenseNumber'
                    type='text'
                    value={values.licenseNumber}
                    autoComplete='on'
                    //disabled={isUserLoading}
                  />
                </div>
                {/* end::Input group */}
              </div>
              <div>
                <div className='fv-row mb-7'>
                  {/* begin::Input */}
                  <MyTextInput
                    placeholder={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.PLACEOFISSUANCE'})}
                    {...getFieldProps('placeOfIssuance')}
                    label={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.PLACEOFISSUANCE'})}
                    name='placeOfIssuance'
                    value={values.placeOfIssuance}
                    type='text'
                    autoComplete='on'
                    //disabled={isUserLoading}
                  />
                  {/* end::Input */}
                </div>
              </div>
            </div>
            <div>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                <MyTextInput
                  placeholder={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.LISENCEEXPIRATIONDATE'})}
                  {...getFieldProps('licenseExpirationDate')}
                  label={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.LISENCEEXPIRATIONDATE'})}
                  name='licenseExpirationDate'
                  type='text'
                  value={values.licenseExpirationDate}
                  autoComplete='on'
                  //disabled={isUserLoading}
                />
              </div>
              {/* end::Input group */}
            </div>

            <div className='d-flex flex-sm-row flex-column justify-content-between'>
              <div>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.TECHNICALINSPECTION'})}
                    {...getFieldProps('technicalInspection')}
                    label={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.TECHNICALINSPECTION'})}
                    name='technicalInspection'
                    type='text'
                    value={values.technicalInspection}
                    autoComplete='on'
                    //disabled={isUserLoading}
                  />
                </div>
                {/* end::Input group */}
              </div>
              <div>
                <div className='fv-row mb-7'>
                  {/* begin::Input */}
                  <MyTextInput
                    placeholder={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.INSURANCENUMBER'})}
                    {...getFieldProps('insuranceNumper')}
                    label={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.INSURANCENUMBER'})}
                    name='insuranceNumper'
                    value={values.insuranceNumper}
                    type='text'
                    autoComplete='on'
                    //disabled={isUserLoading}
                  />
                  {/* end::Input */}
                </div>
              </div>
            </div>
            <div className='d-flex flex-sm-row flex-column justify-content-between'>
              <div>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  <MyTextInput
                    placeholder={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.PLATENUMBER'})}
                    {...getFieldProps('plateNumber')}
                    label={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.PLATENUMBER'})}
                    name='plateNumber'
                    type='text'
                    value={values.plateNumber}
                    autoComplete='on'
                    //disabled={isUserLoading}
                  />
                </div>
                {/* end::Input group */}
              </div>
              <div>
                <div className='fv-row mb-7'>
                  {/* begin::Input */}
                  <MyTextInput
                    placeholder={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.POULLLISENCE'})}
                    {...getFieldProps('poullLicense')}
                    label={intl.formatMessage({id: 'ECOMMERCE.DRIVERS.POULLLISENCE'})}
                    name='poullLicense'
                    value={values.poullLicense}
                    type='text'
                    autoComplete='on'
                    //disabled={isUserLoading}
                  />
                  {/* end::Input */}
                </div>
              </div>
            </div>
          </>
        )}
        {/* end::Input group */}
      </div>
      {/* end::Scroll */}
    </>
  )
}

const MultieSelect = ({options}: {options: {label: string; value: any}[]}) => {
  const [selectedOption, setSelectedOptions] = useState<any>()
  const [field, meta] = useField({name: 'driverArea'})
  const {setFieldValue} = useFormikContext()

  const handleChange = (selected: any) => {
    setSelectedOptions(selected)
    setFieldValue('driverArea', selected)
  }

  return (
    <>
      <label className='fw-bold fs-6 mb-2'><FormattedMessage id='driverarea'/></label>
      <ReactSelect
        {...field}
       // value={selectedOption}
        options={options}
        isMulti
        required
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: CustomerDropDownComponent,
        }}
        onChange={handleChange}
      />
      {meta.error && <div className='text-danger fs-4 mt-2'>{meta.error}</div>}
    </>
  )
}

export default RoleForm

const MyTextInput = ({label, ...props}: any & React.InputHTMLAttributes<HTMLInputElement>) => {
  const [field, meta] = useField(props)
  return (
    <>
      {/* begin::Label */}
      <label className='fw-bold fs-6 mb-2'>{label}</label>
      {/* end::Label */}
      {/* begin::Input */}
      <input
        {...field}
        {...props}
        className={clsx(
          'form-control form-control-solid mb-3 mb-lg-0',
          {'is-invalid': meta.touched && meta.error},
          {
            'is-valid': meta.touched && !meta.error,
          }
        )}
      />
      {meta.touched && meta.error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{meta.error}</span>
          </div>
        </div>
      )}
      {/* end::Input */}
    </>
  )
}
