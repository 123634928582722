import React, {useState} from 'react'
import {Pagination as BootstrapPagination, Pagination} from 'react-bootstrap'
import {useAppDispatch} from '../../../../app/hooks'
import {getAllFunction} from '../../../../app/redux/actions/commonCrud'
import {useSelector} from 'react-redux'

type values = {
  role?: string | undefined
  phone?: string | undefined
  status?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string | undefined
}

type IPaginationProps = {
  values: values | undefined
  setValues: any
  endpoint: string
  actionType: string
  totalPages: number
}

const PaginationComponent = ({
  values,
  setValues,
  endpoint,
  actionType,
  totalPages,
}: IPaginationProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const dispatch = useAppDispatch()

  const fetchData = async (page: number) => {
    await dispatch(getAllFunction(endpoint, actionType, {...values, page}))
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
    setValues({...values, page: pageNumber - 1})
    fetchData(pageNumber - 1)
  }

  const createPaginationItem = (i: number) => (
    <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
      {i}
    </Pagination.Item>
  )
  console.log(totalPages,"totalPages")
  const generatePaginationItems = () => {
    const paginationItems: any = [];
  
    if (totalPages <= 1) {
      paginationItems.push(createPaginationItem(1));
      return paginationItems;
    }
  
    const addPageItem = (page:number) => paginationItems.push(createPaginationItem(page));
  
    addPageItem(1);
  
    if (totalPages === 2) {
      // If there are exactly two pages, include the second page
      addPageItem(2);
    } else {
      const startPage = Math.max(2, Math.min(currentPage - 1, totalPages - 4));
      const endPage = Math.min(startPage + 4, totalPages - 1);
  
      if (startPage > 2) {
        paginationItems.push(<Pagination.Ellipsis key='ellipsis-start' />);
      }
  
      for (let i = startPage; i <= endPage; i++) {
        addPageItem(i);
      }
  
      if (endPage < totalPages - 1) {
        paginationItems.push(<Pagination.Ellipsis key='ellipsis-end' />);
      }
  
      addPageItem(totalPages);
    }
  
    return paginationItems;
  };
  
  
  
  
  return (
    <Pagination className='paginationInfo'>
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {generatePaginationItems()}
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  )
}

export default PaginationComponent
