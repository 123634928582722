/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../hooks'
import { getAllFunctionById } from '../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import { UsersListLoading } from '../../../ListUsers/users-list/components/loading/UsersListLoading'
import { useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_template/helpers'
import DetailsBox from './detailsBox'
import { SubOrdersTable } from './subOrdersTable'
import { Button } from 'react-bootstrap'

type Props = {
  className: string
}
const OrdersInfo: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const columns = [
    { code: '', title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.SELLER' }) },
    { code: '', title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.EMAIL' }) },
    { code: '', title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.PHONENUMBER' }) },
    { code: '', title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.LOCATION' }) },
    { code: '', title: intl.formatMessage({ id: 'MENU.ACTIONS' }) },
  ]
  //let data = [{name: 'Nuha', email: 'ddd@gmail.com', phone: '95544764', location: 'cccc'}]

  const order: IMainOrder = useSelector(({ orders }: { orders: OrdersState }) => {
    return orders.oneMainOrder
  })
  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(getAllFunctionById(endpoints.mainOrderEndpoint, actionTypes.GET_ONE_MAIN_ORDER, id))

    setTimeout(() => {
      setLoading(false)
    }, 100000)

    return () => {
      dispatch({ type: actionTypes.GET_ONE_MAIN_ORDER, payload: {} })
    }
  }, [dispatch, id])

  return (
    <>
      <div>
        {loading && <UsersListLoading />}
        <div
          style={{ backgroundColor: '#e4e6ef30' }}
          className='card mb-5 mb-xl-10'
          id='kt_profile_details_view'
        >
          {order && order.totalAmount && (
            <div className='p-9'>
              <div className='row mb-7'>
                {/* <TablesWidget9 columns={columns} className='mb-5 mb-xl-8' data={[order?.customer]} /> */}
                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 p-5'>
                  <DetailsBox
                    rows={[
                      {
                        code: 'STATUS',
                        title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATUS' }),
                        icon: 'bi-bag-fill',
                        value: order.status,
                      },
                      // {code:'', title: 'shipping method', icon: 'bi-truck', value: '30/05'},
                      {
                        code: '',
                        title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.PRICE' }),
                        icon: 'bi-truck',
                        value: `${parseFloat(order.totalAmount)?.toFixed(3) + ' LYD'}`,
                      },
                      {
                        code: '',
                        title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.MARKETPLACE_REF' }),
                        icon: 'bi-upc',
                        value: `${order.marketplaceReference}`,
                      },
                    ]}
                    className={''}
                    title={`${intl.formatMessage({ id: 'ECOMMERCE.ORDER' })} #` + order.reference}
                  />
                </div>
                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 p-5'>
                  <DetailsBox
                    rows={[
                      {
                        code: 'CUSTOMER',
                        title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.CUSTOMER' }),
                        icon: 'bi-person-circle',
                        value: `${order.customer?.firstName + ' ' + order.customer?.lastName}`,
                      },
                      {
                        code: 'EMAIL',
                        title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.EMAIL' }),
                        icon: 'bi-envelope-at-fill',
                        value: `${order.customer?.email}`,
                      },
                      {
                        code: '',
                        title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.PHONENUMBER' }),
                        icon: 'bi-phone-fill',
                        value: `${order.customer?.phoneNumber}`,
                      },
                    ]}
                    className={''}
                    title={intl.formatMessage({ id: 'ECOMMERCE.CUSTOMERS.CUSTOMERS_DETAILS' })}
                  />
                </div>
                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 p-5'>
                  {order && (
                    <DetailsBox
                      rows={[
                        {
                          code: '',
                          title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.PAYMENT&SHIPING' }),
                          icon: 'bi-cash-coin',
                          value: `${order.paidThrough === 'mobicash' || order.status?.code === '100'
                            ? intl.formatMessage({ id: 'ECOMMERCE.ORDER.PAID' })
                            : intl.formatMessage({ id: 'ECOMMERCE.ORDER.NOT_PAID' })
                            }`,
                        },
                        {
                          code: '',
                          title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.PAYMENTMETHOD' }),
                          icon: 'bi-wallet-fill',
                          value: `${order.paidThrough}`,
                        },
                        {
                          code: '',
                          title: intl.formatMessage({ id: 'ECOMMERCE.COMMON.SHIPPING' }),
                          icon: 'bi-truck',
                          value: '30/05',
                        },
                      ]}
                      className={''}
                      title={intl.formatMessage({ id: 'ECOMMERCE.COMMON.PAYMENT&SHIPING' })}
                    />
                  )}
                </div>
              </div>
              <div className='row my-3'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 p-5'>
                  <div
                    className={`card bgi-no-repeat ${className}`}
                    style={{
                      backgroundPosition: 'right top',
                      backgroundSize: '30% auto',
                      backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-1.svg')})`,
                    }}
                  >
                    <div className='py-5'>
                      <span className='card-title fw-bold fs-2'>
                        <FormattedMessage id='ECOMMERCE.ORDER.BILLING_ADDRESS' />
                      </span>


                      <div className='w-lg-75 w-md-100 mt-4'>
                        <div>
                          <HorizontalAttributeComponent
                            attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CITY' })}
                            value={order.billingAddress?.city}
                          />

                          <HorizontalAttributeComponent
                            attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.COUNTRY' })}
                            value={order.billingAddress?.country}
                          />

                          <HorizontalAttributeComponent
                            attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATE' })}
                            value={order.billingAddress?.addressLine1}
                          />
                          <HorizontalAttributeComponent
                            attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ZIPCODE' })}
                            value={order.billingAddress?.zipCode}
                          />
                        </div>
                      </div>
                    </div>

                    {/* end::Body */}
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 p-5'>
                  <div
                    className={`card bgi-no-repeat ${className}`}
                    style={{
                      backgroundPosition: 'right top',
                      backgroundSize: '30% auto',
                      backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-4.svg')})`,
                    }}
                  >
                    {/* begin::Body */}
                    <div className='py-5'>
                      <div className='card-title fw-bold fs-2 d-flex justify-content-between'>
                        <span className='card-title fw-bold fs-2'>
                          <FormattedMessage id='ECOMMERCE.ORDER.SHIPPING_ADDRESS' />
                        </span>
                        <div>
                          <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${order.customer?.address?.positionY},${order.customer?.address?.positionX}`}>
                            <Button variant="success" size='sm'>
                              {intl.formatMessage({ id: 'ECOMMERCE.COMMON.LOCATION' })}
                              <i className={`bi bi-geo-alt-fill`}></i>
                            </Button>
                          </a>
                        </div>
                      </div>


                      <div className='w-lg-75 w-md-100 mt-4'>
                        <div>
                          <HorizontalAttributeComponent
                            attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CITY' })}
                            value={order.shippingAddress?.city}
                          />

                          <HorizontalAttributeComponent
                            attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.COUNTRY' })}
                            value={order.shippingAddress?.country}
                          />

                          <HorizontalAttributeComponent
                            attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.STATE' })}
                            value={order.shippingAddress?.addressLine1}
                          />
                          <HorizontalAttributeComponent
                            attr={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ZIPCODE' })}
                            value={order.shippingAddress?.zipCode}
                          />
                        </div>
                      </div>
                    </div>
                    {/* end::Body */}
                  </div>
                </div>
              </div>
              <div className='row my-3'>
                <SubOrdersTable
                  columns={columns}
                  className='mb-5 mb-xl-8'
                  data={[order?.subOrders]}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default OrdersInfo
const HorizontalAttributeComponent = ({
  attr = '--',
  value = '--',
}: {
  attr?: string
  value?: string | number
}) => (
  <div className='d-flex fs-4 my-auto '>
    <div className='text-muted '>{attr}</div>: <div className='mx-8'> {value}</div>
  </div>
)
