import {KTSVG} from '../../../_template/helpers'
import { useLang } from '../../../_template/i18n/Metronici18n'
import {CustomSelect} from './selectComponent'
const options = [
  {value: 'ACTIVE', label: 'Last 7 days '},
  {value: 'INACTIVE', label: 'Last month'},
  {value: 'INACTIVE', label: 'Last year'},
]
const DashboardFilters = () => {
    const locale: string = useLang()

  return (
    <div className='card-header border-0 pt-6'>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <div    className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
               {/* begin::Export */}
      <button
        data-kt-menu-trigger='click'
        data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
        type='button'
        className='btn btn-lightBtn me-3'
      >
        <span>
          <KTSVG path='/media/icons/duotune/arrows/download.svg' className='svg-icon-6' />
        </span>
   
      </button>
      {/* end::Export */}
          <CustomSelect
            className='form-select rounded form-select-solid-white fw-bolder'
            data-kt-select2='true'
            placeholder='Status'
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='Status'
            data-hide-search='true'
            name='status'
            //label={intl.formatMessage({id: 'ECOMMERCE.COMMON.STATUS'})}
            options={options}
          />

          {/* begin::Add user */}
          {/* <button type='button' className='btn btn-icon' >
        <KTSVG
          path='/media/icons/duotune/arrows/arr075.svg'
          className='svg-icon-2 svg-icon-light'
        />
      </button> */}
          {/* end::Add user */}
        </div>
      </div>
    </div>
  )
}

export {DashboardFilters}
